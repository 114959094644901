import styled, { css, keyframes } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { StyledInput } from '../Input/styles';
import search from '../../assets/icons/search.svg';
import { ScreenWidthEnum } from '../../enums/screenWidth';

const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

export const SearchBar = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px -2px rgba(0, 0, 0, 0.1);

  button {
    width: 104px;
    min-width: 104px;
    border-radius: 0px 8px 8px 0px;
  }
`;

export const SearchInput = styled(StyledInput)`
  background: url(${search}) no-repeat;
  background-position: 10px center;
  background-color: white;
  padding-left: 32px;
  border-radius: 8px 0px 0px 8px;
  border: none;
  color: ${Colors.BLACK};
  font-size: 14px;
  line-height: 14px;

  ${props => props.theme.screen === ScreenWidthEnum.phone && css`
    font-size: 11px;
    line-height: 11px;
  `}
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin-top: 8px;

  & > * {
    animation: ${opacity} 800ms ease forwards;
  }

  &:empty {
    animation: ${opacity} 700ms ease forwards;
  }

  & > p {
    margin-top: 16px;
    padding-left: 16px;
  }
`;
