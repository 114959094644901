import React from 'react';
import MyDoctors from '../Patient/MyDoctors';
import Routes from '../../../enums/Routes';
import FindByName from './FindByName';
import HeaderWithTitle from '../../../components/HeaderWithTitle';

const SearchDoctor: React.FC = () => {
  return (
    <>
      <HeaderWithTitle
        backRoute={Routes.PATIENT}
        pageTitle='Find vDoctor by name'
      />
      <FindByName />
      <MyDoctors />
    </>
  );
};

export default SearchDoctor;
