import styled, { css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import Typography from '../Typography';

export const DropdownHeader = styled.div`
  display: flex;
  flex-flow: row;
  padding: 0px 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    height: 48px;
  `}
  border-radius: 8px;
  background: ${Colors.WHITE};
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: fit-content;

  img {
    cursor: pointer;
  }
`;

interface IDropdownContentWrapper {
  show?: boolean;
}

export const DropdownContentWrapper = styled.div`
  padding: 10px 0px;
  width: 100%;

  > * + * {
    margin-top: 10px;
    ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
      margin-top: 15px;
    `}
  }
  
  ${(props: IDropdownContentWrapper) => !props.show && css`
    display: none;
  `}
`;

export const Title = styled(Typography)`
  font-weight: 500;
`