import React, { useState } from 'react';
import ListItem, { ItemType, ICheckboxItem } from './ListItem';
import * as UI from './styles';

interface ICheckboxList {
  options?: ICheckboxItem[];
  onClick?: (item?: ICheckboxItem) => void;
  type: ItemType;
  radio?: boolean;
}

const CheckboxList: React.FC<ICheckboxList> = ({
  options,
  onClick,
  type = 'text',
  radio,
  ...props
}) => {
  const [checkboxList, setCheckboxList] = useState(options);

  const handleCheckboxClick = (id: string) => {
    const item = checkboxList?.find((item) => item.id === id);
    if (radio) {
      setCheckboxList((opts) =>
        opts?.map((el) => ({
          ...el,
          checked: el.id === id,
        })),
      );
    } else {
      const items = checkboxList?.map((item) => {
        if (item.id === id) return { ...item, checked: !item.checked };
        return item;
      });

      setCheckboxList(items);
    }
    if (onClick) onClick(item);
  };

  return (
    <UI.Wrapper {...props}>
      {checkboxList?.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          onClick={() => handleCheckboxClick(item.id)}
          type={type}
        />
      ))}
    </UI.Wrapper>
  );
};
export default CheckboxList;
