import React, { useState, useMemo, useEffect } from 'react';
import * as UI from './styles';
import close from '../../assets/icons/closeCircle.svg';
import Alert from '../Alert';
import { v4 } from 'uuid';
import { ACCEPT_MIME, MAX_SIZE } from '../../constants/image';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../store/notification';

interface IImageInput {
  options?: string[];
  maxImages?: number;
  onDelete?: (index: number) => void;
  onChange?: (file: File) => void;
  onClick?: (src: string) => void;
}

const ImageInput: React.FC<IImageInput> = ({
  maxImages = 1,
  onDelete,
  onChange,
  options,
  onClick
}) => {
  const [images, setImages] = useState<string[]>([]);
  const disaptch = useDispatch()
  const acceptString = ACCEPT_MIME.join(", ");
  
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files[0] !== null) {
      if (!ACCEPT_MIME.includes(e.target.files[0].type)) {
        disaptch(notificationActions.add({
          message: "Accepted file types: " + acceptString.replaceAll("image/", "."),
          type: 'error'
        }));
        return;
      };

      if (e.target.files[0].size > MAX_SIZE) {
        disaptch(notificationActions.add({
          message: "Uploaded image exceeded the maximum file size (10 MB).",
          type: 'error'
        }));
        return;
      };

      if (e.target.files[0].size <= 0) {
        disaptch(notificationActions.add({
          message: "Uploaded image is invalid.",
          type: 'error'
        }));
        return;
      };

      const url = URL.createObjectURL(e.target.files[0]);
      setImages(images => [...images, url]);
      if (onChange) onChange(e.target.files[0]);
    }

    e.target.value = '';
  };

  useEffect(() => {
    setImages(options || []);
  }, [options]);

  const handleDelete = (index: number) => {
    setImages(image => image.filter((img, i) => i !== index));

    if (onDelete) onDelete(index);
  };

  const handleClick = (src: string) => {
    if (onClick) onClick(src);
  };

  const imageList = useMemo(() => (
    <>
      {images.map((img, i) => (
        <UI.ImageWrapper key={v4()}>
          {img ?
            <img src={img} onClick={() => handleClick(img)} /> :
            <UI.Placeholder />
          }
          <img src={close} onClick={() => handleDelete(i)} />
        </UI.ImageWrapper>
      ))}
      {images.length < maxImages && 
        <UI.CustomInput>
          <UI.Input
            type="file"
            accept={acceptString}
            onChange={handleInput}
          />
        </UI.CustomInput>
      }
    </>
  ), [images, maxImages, acceptString]);

  return (
    <>
      <UI.ImageInputWrapper>
        {imageList}
      </UI.ImageInputWrapper>
    </>
  );
};

export default ImageInput;
