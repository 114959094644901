import React from 'react';
import * as UI from './styles';
import { IBodyPart, IFile } from '../../../constants/avatar';
import { getBodypartColor } from '../../../helpers/color';

interface IBodyPartComponent extends IFile {
  item: IBodyPart;
  selected?: boolean;
}

const BodyPart: React.FC<IBodyPartComponent> = ({
  item,
  selected,
  onClick,
  fill,
  stroke
}) => (
  <UI.ImageWrapper styles={item.styles} pointer={!!onClick}>
    {item.component({
      fill: getBodypartColor(selected, fill),
      stroke: getBodypartColor(selected, stroke),
      onClick: onClick})}
  </UI.ImageWrapper>
);

export default BodyPart;
