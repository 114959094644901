import { ScreenWidthEnum } from '../../../enums/screenWidth';
import styled, { css } from 'styled-components/macro';
import Typography from '../../../components/Typography';

export const CorrectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-grow: 1;
`;

export const CheckboxListWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(129px, 1fr));
  grid-row-gap: 13px;
  grid-column-gap: 8px;
  overflow-y: scroll;
`;

export const HeaderTypography = styled(Typography)`
  display: block;
  margin-top: 0;
  align-self: flex-start;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${props => props.theme.screen === ScreenWidthEnum.phone && css`
    display: none;
  `}
`;

export const HeaderContentTypography = styled(Typography)`
  margin-top: 0;
  align-self: center;
  display: none;
  ${props => props.theme.screen === ScreenWidthEnum.phone && css`
    display: block;
  `}
`;

export const DropdownsContainer = styled.div`
  display: grid;
  grid-row-gap: 8px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  padding-bottom: 100px;

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    padding-bottom: 70px;
  `}
`;
