import { getBase64Img, postRequest, publicUrl } from "../../api";
import { sessionId } from "../../helpers/session";

export const imageUrl = (id: string) => 
 publicUrl + `/api/jr/download/photo/${id}`;

export const getDiseaseImage = (id: string) => getBase64Img('/jr/download/photo/', id);

export const uploadDiseasePhotos = async (photos: string[]) => {
  return postRequest("/user-session/add/disease-photo/", {
    photoId: [...photos],
    sessionId: sessionId()
  });
};
