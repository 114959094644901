import React from 'react';
import * as UI from './styles';

interface ICheckbox extends UI.IStyledCheckbox {
  onClick?: () => void;
}

const Checkbox: React.FC<ICheckbox> = ({
  checked,
  onClick,
  children,
  type = "text"
}) => (
  <UI.CheckboxWrapper checked={checked} type={type}>
    <UI.CheckboxBox onClick={onClick} checked={checked} type={type} />
    {children}
  </UI.CheckboxWrapper>
);

export default Checkbox;
