import { getRequest, postRequest } from '../../api'
import { sessionId } from '../../helpers/session';
import { IDiseasePhoto } from '../../models/Disease';
import ISpecialization from '../../models/Specialization';
import { ISessionSymptoms } from '../../models/Symptom';

export const fetchSessionSpecialization = (): Promise<ISpecialization> => {
  return getRequest(`/user-session/get/specialization/?sessionId=${sessionId()}`);
};

export const fetchSessionSymptoms = () => {
  return getRequest(`/user-session/get/current/organ-symptom/?sessionId=${sessionId()}`);
};

export const fetchDiseasePhotos = (): Promise<IDiseasePhoto[]> => {
  return getRequest(`/user-session/get/disease-photo/?sessionId=${sessionId()}`);
}

export const sendSessionSpecialization = (specializationId: string) => {
  return postRequest('/user-session/add/specialization/', {
    specializationId: specializationId,
    sessionId: sessionId(),
  });
};

export const sendSessionSymptom = (sessionSymptom: ISessionSymptoms) => {
  return postRequest('/user-session/add/organ-symptom/', sessionSymptom);
};

export const saveSessionSymptoms = async (sessionSymptoms: ISessionSymptoms[]) => {
  for (const symptom of sessionSymptoms) {
    await sendSessionSymptom(symptom);
  }
};

export const getSpecializationHistory = () => {
  return getRequest('/user-session/get/specialization-history/');
};
