import { ScreenWidthEnum } from '../../../enums/screenWidth';
import styled, { css } from 'styled-components/macro';
import Colors from '../../../enums/Colors';
import { VIEWPORT_WIDTH } from '../../../constants/viewport';
import Button from '../../../components/BaseButton';

export interface IProps {
  isSpecializationView?: boolean;
}

export const AddButton = styled.button<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  width: fit-content;
  background-color: ${Colors.TRANSPARENT};

  ${(props) => [ScreenWidthEnum.phone, ScreenWidthEnum.laptop].some(el => el === props.theme.screen) && css`
    max-width: 104px;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    max-width: 140px;
  `}

  ${props => props.theme.screen !== ScreenWidthEnum.landscapePhone && css`
    margin-top: 40px;
  `}

  ${props => props.isSpecializationView && css`
    ${props.theme.screen <= ScreenWidthEnum.tablet && css`
      flex-direction: row;
      > p {
        margin-left: 8px;
        text-align: left;
      }
    `}

    ${props.theme.screen === ScreenWidthEnum.tablet && css`
      margin-top: 64px;
      margin-right: 104px;
    `}

    ${(props) => [ScreenWidthEnum.phone, ScreenWidthEnum.tablet].some(el => el === props.theme.screen) && css`
      margin-left: auto;
      max-width: 160px;
    `}

    ${props.theme.screen === ScreenWidthEnum.phone && css`
      margin-top: 16px;
    `}

    ${props.theme.screen === ScreenWidthEnum.landscapePhone && css`
      order: 3;

      & > p {
        max-width: 120px;
      }
    `}
  `}

  img {
    width: 40px;
    height: 40px;

    ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
      width: 48px;
      height: 48px;
    `}
  }
`;

export const ImgButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;


  & > * + * {
    margin-left: 8px;
  }

  img {
    height: 40px;
    cursor: pointer;
    ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
      width: 48px;
      height: 48px;
    `}
  }
`;

export const BottomButtons = styled.div`
  grid-column: 2 / 4;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  align-self: end;
  margin-bottom: 100px;

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    margin: auto 0 70px;
    grid-column: 1 / 4;
  `}

  & > :first-child {
    margin-top: auto;
  }
`;

export const PreviewHeaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 13px;
  width: calc((180 / ${VIEWPORT_WIDTH}) * 100vw);

  & > :first-child {
    margin-bottom: 14px;
  }

  & > :nth-child(2) {
    margin-bottom: 27px;
  }

  & > :last-child {
    margin: 16px 0px 22px;
  }

  button {
    width: calc((155 / ${VIEWPORT_WIDTH}) * 100vw);
    max-width: 400px;
  }
`;

interface IRow extends IProps {
  preview: boolean
}

export const Row = styled.div<IRow>`
  grid-column: 2 / 3;
  display: flex;
  justify-content: space-between;

  ${props => props.theme.screen > ScreenWidthEnum.landscapePhone && css`
    justify-content: flex-end;
  `}

  ${props => props.theme.screen >= ScreenWidthEnum.laptop && css`
    justify-content: flex-start;
    margin-top: 24px;
  `}

  ${props => (props.preview && props.theme.screen !== ScreenWidthEnum.landscapePhone) && css`
    flex-direction: column;
    grid-column: 2 / 4;
    height: ${props.theme.screen === ScreenWidthEnum.phone && '100%'};
  `}

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    grid-column: 1 / 4;

    ${props.isSpecializationView && css`
      flex-wrap: wrap;

      & > :nth-child(2) {
        flex-basis: 100%;
      }
    `}
  `}

  ${props => (props.theme.screen > ScreenWidthEnum.tablet) && css`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const PlusButton = styled.div`
  position: relative;
  margin-bottom: 4px;
  display: flex;
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    width: 48px;
    height: 48px;
  `}
`;

export const PlusCounter = styled.div<IProps>`
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);

  ${(props) => props.theme.screen <= ScreenWidthEnum.tablet && props.isSpecializationView && css`
    top: 10%;
    transform: translateY(-10%);
    right: -8px;
  `}
`;

export const Container = styled.div`
  width: 94vw;
  align-self: center;
  flex-grow: 1;
  display: grid;  
  grid-template-columns: 1fr minmax(0px, 600px) 1fr;
  grid-template-rows: 50% 50%;
  align-items: start;

  ${props => props.theme.screen === ScreenWidthEnum.phone && css`
    grid-template-rows: calc(100% - 156px) 156px;
    position: relative;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.laptop && css`
    width: 1024px;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    grid-template-columns: 1fr minmax(0px, 740px) 1fr;
    width: 1240px;
  `}
`;

export const ProceedButton = styled(Button)`
  width: 40%;
  min-width: 170px;

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    max-width: 240px;
  `}
`;

export const SymptomsListContainer = styled.div<IProps>`
  height: 50vh;
  width: 160px;
  overflow-y: hidden;
  align-self: flex-end;
  z-index: 10;
  margin-top: 56px;

  ${props => props.theme.screen === ScreenWidthEnum.phone && css`
    height: 30vh;
    margin-top: 8px;
    flex-grow: 1;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    height: 30vh;
    width: 200px;
    margin-top: 0;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.tablet && css`
    margin-top: ${props.isSpecializationView ? '8px' : '64px'};
  `}

  ${props => props.theme.screen >= ScreenWidthEnum.tablet && css`
    margin-left: auto;
    width: 40%;
  `}
`;

interface GenderSwitchProps {
  disabled?: boolean
}

export const GenderSwitch = styled.img<GenderSwitchProps>`
  ${props => props.disabled && css`
    filter: grayscale(100%);
  `} 
`