import React, { useMemo } from 'react';
import { IOrganSymptoms } from '../../../../models/Symptom';
import { symptomsSelector } from '../../../../store/symptoms';
import { useSelector } from 'react-redux';
import MenuItem from '../../../../components/MenuItem';
import { v4 } from 'uuid';
import { alphabetSort } from '../../../../helpers/sort';
import { CheckboxListWrapper, SymptomsListWrapper } from '../SymptomsList/styles';

interface IOrgansList {
  organs?: IOrganSymptoms[];
  handleSymptomsModal?: (organSymptoms: IOrganSymptoms) => void;
}

const OrgansList: React.FC<IOrgansList> = ({
  handleSymptomsModal,
  organs
}) => {
  const selectedSymptoms = useSelector(symptomsSelector);

  const handleCheckboxClick = (organ: IOrganSymptoms) => {
    if (handleSymptomsModal) handleSymptomsModal(organ);
  };

  const isChecked = (organId: string): number | undefined => {
    return selectedSymptoms?.find((item) => item.organId === organId)?.symptoms?.length;
  };

  const list = useMemo(() => {
    return alphabetSort(organs)?.map((option) =>
      <MenuItem
        type='checkbox'
        selected={!!isChecked(option.id)}
        count={isChecked(option.id)}
        caption={option.title}
        onClick={() => handleCheckboxClick(option)}
        key={v4()}
      />
    );
  }, [selectedSymptoms, handleSymptomsModal, organs]);

  return (
    <SymptomsListWrapper type='blue'>
      <CheckboxListWrapper type='blue'>
        {list}
      </CheckboxListWrapper>
    </SymptomsListWrapper>
  );
};

export default OrgansList;
