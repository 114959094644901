import React from 'react';
import NewPasswordForm from './NewPasswordForm';

const NewPassword: React.FC = () => {
  return (
    <NewPasswordForm />
  );
};

export default NewPassword;
