import styled from 'styled-components/macro';
import Colors from '../../enums/Colors';

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${Colors.WHITE};
  border-radius: 16px;
  padding: 16px 24px 24px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
`;

export const StyledForm = styled.form`
  width: 100%;
  display: grid;
  grid-row-gap: 16px;
  justify-items: center;
  margin-top: 16px;

  a {
    text-decoration: none;
  }
`;
