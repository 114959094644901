import { UploadMode } from "../../../../store/medicalTest";

const TITLES = new Map<string, string>();
TITLES.set(`${UploadMode.IMAGE}.add`, 'Add photo from gallery');
TITLES.set(`${UploadMode.DOCUMENT}.add`, 'Add PDF document');
TITLES.set(`${UploadMode.ALL}.add`, 'Add file');

TITLES.set(`${UploadMode.IMAGE}.addNew`, 'Add new photo from gallery');
TITLES.set(`${UploadMode.DOCUMENT}.addNew`, 'Add new PDF file');
TITLES.set(`${UploadMode.ALL}.addNew`, 'Add file');

export default TITLES