import styled, { css } from 'styled-components/macro';
import ColorsEnum from "../../enums/Colors";
import { ButtonType } from "../BaseButton";

interface CounterProps {
  type: ButtonType
}

export const Counter = styled.div<CounterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  border-radius: 50%;
  border: 1px solid ${ColorsEnum.WHITE};
  background: ${ColorsEnum.GREEN};
  width: 24px;
  height: 24px;

  p {
    color: ${ColorsEnum.WHITE};
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  ${props => props.type === 'transparent' && css`
    p {
      color: ${ColorsEnum.GREEN};
      background: ${ColorsEnum.WHITE};
      border: 2px solid ${ColorsEnum.GREEN};
      display: block;
      width: 100%;
      border-radius: 50%;
    }
  `}  
`;
