import styled, { css } from "styled-components/macro";
import { ScreenWidthEnum } from "../../enums/screenWidth";

export const MenuBody = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
  height: ${(props) => props.theme.height}px;
  padding: 16px 24px 24px;

  a {
    text-decoration: none;
  }

  button {
    margin-top: 16px;
    width: 100%;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > :last-child {
    margin-top: auto;
  }

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    max-height: 90vh;
    overflow-y: scroll;
  `}
`;
