import React, { useEffect, useState } from 'react';
import ImageModal from '../ImageModal';
import { useDispatch } from 'react-redux';
import { addImage, deleteImage } from '../../../../store/userImages';
import ImageInput from '../../../../components/ImageInput';
import { getSessionImages, getUserImage } from '../../../../services/attachment';
import { removeFromArray } from '../../../../helpers/removeFromArray';

const UserImages: React.FC = () => {
  const [userImages, setUserImages] = useState<string[]>([]);
  const [item, setItem] = useState<number>();
  const dispatch = useDispatch();

  useEffect(() => {
    getSessionImages().then((response) => {
        const images = response?.map((item) => item.fileId);
        setUserImages(images?.map(() => ""));
        const promises = images?.map((item) => getUserImage(item).then((response) => response)) || [];
        Promise.all(promises).then((response) => setUserImages(response));
      }
    );
  }, []);

  const handleImageClick = (photo: string) => {
    setItem(userImages?.findIndex(el => el === photo));
  };

  const handleImageVisibility = (visible: boolean) =>
    !visible && setItem(undefined);

  const handleImageChange = (image: File) => {
    dispatch(addImage(image));
    setUserImages(arr => [...arr, URL.createObjectURL(image)])
  };

  const handleImageDelete = (i: number) => {
    dispatch(deleteImage(i))
    setUserImages(arr => removeFromArray(arr, i, 1))
  };
  return (
    <div>
      <ImageInput
        options={userImages}
        maxImages={10}
        onChange={handleImageChange}
        onDelete={handleImageDelete}
        onClick={handleImageClick}
      />
      {(userImages?.length > 0 && typeof item === 'number') && 
        <ImageModal
          handleVisibility={handleImageVisibility}
          images={userImages}
          title='Your photos'
          currentImageIndex={item || 0}
          secondAction={() => handleImageVisibility(false)}
          firstAction={handleImageDelete}
          buttonTitles={['Remove', 'Close']}
        />
      }
    </div>
  );
};

export default UserImages;
