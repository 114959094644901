import styled, { css } from 'styled-components/macro';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import Button from '../BaseButton';
import Typography from '../Typography';

export enum navHeightEnum {
  desktop = 96,
  default = 72,
}

export const navHeight = (screen: ScreenWidthEnum) => {
  switch (screen) {
    case ScreenWidthEnum.desktop: 
      return navHeightEnum.desktop
    default: return navHeightEnum.default 
  }
};

export const HeaderWrapper = styled.header`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  text-align: center;

  ${(props) => props.theme.screen >= ScreenWidthEnum.landscapePhone && css`
    & > :nth-child(2) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  `}
  
  ${(props) => props.theme.screen === ScreenWidthEnum.phone && css`
  flex-flow: column;
  align-items: flex-start;
  height: fit-content;
  
  > button {
    margin-top: 16px;
  }
  `}
  ${props => css`
    min-height: ${navHeight(props.theme.screen)}px;
  `}
`;

export const GreenTypography = styled(Typography)`
  display: inline;
  white-space: nowrap;
`
export const Header = styled(Typography)`
  width: 55%;
`

export const StyledButton = styled(Button)`
  position: absolute;
  left: 16px;
`