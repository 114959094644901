import styled from 'styled-components/macro';
import Colors from '../../enums/Colors';

export const PageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;

  & > :last-child {
    align-self: flex-end;
    margin: 0px 27px 20px 0px;
  }
`;

export const ContentWrapper = styled.div`
  margin: 1.666rem 0rem;
  width: 90vw;
  max-width: 408px;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.666rem;
`;
