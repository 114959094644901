import styled from 'styled-components/macro';
import { VIEWPORT_HEIGHT } from '../../../../constants/viewport';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  
  & > p {
    max-height: calc((270 / ${VIEWPORT_HEIGHT}) * 100vh);
    white-space: pre-wrap;
  }
  
  button {
    margin-top: 24px;
  }
`;
