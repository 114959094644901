import { ScreenWidthEnum } from './../../enums/screenWidth';
import { IAction, IReduxState } from './../index';

enum ACTIONS {
  SET_WIDTH = "SET_WIDTH"
}

type Action = IAction<ACTIONS.SET_WIDTH, ScreenWidthEnum>

const initialState = {
  width: ScreenWidthEnum.phone
}

export const screenReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ACTIONS.SET_WIDTH:
      return {
        ...state,
        width: action.payload
      }
    default:
      return state
  }
}

export const screenActions = {
  setWidth: (payload: ScreenWidthEnum) => ({ type: ACTIONS.SET_WIDTH, payload })
}

export const screenSelectors = {
  width: (state: IReduxState) => state.screenReducer.width
}