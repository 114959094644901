import { v4 } from "uuid";
import { Gender } from "../constants/avatar";
import RoutesEnum from "../enums/Routes";

export const showTermsModalKey = 'showTermsModal';
export const showMedicalTestModalKey = 'showTestsModal';
const sessionIdKey = 'sessionId';
const genderKey = 'gender';
const mainMenuPathKey = 'mainMenuPath';

type ModalKey = 'showTermsModal' | 'showTestsModal';

export const setShowModal = (key: ModalKey, val: boolean) => localStorage.setItem(key, val.toString());
export const setGender = (gender?: Gender) => localStorage.setItem(genderKey, gender || 'm');
export const setMainMenuPath = (route: RoutesEnum) => localStorage.setItem(mainMenuPathKey, route);

export const newSession = (gender?: Gender) => {
  localStorage.setItem(sessionIdKey, v4());
  setGender(gender);
};

export const sessionId = () => localStorage.getItem(sessionIdKey) || '';
export const showModal = (key: ModalKey) => localStorage.getItem(key) !== (false).toString();
export const showMedicalTestModal = () => localStorage.getItem(showMedicalTestModalKey) === 'true';
export const gender = (): Gender => localStorage.getItem(genderKey) === 'f' ? 'f' : 'm';
export const mainMenuPath = (): RoutesEnum => localStorage.getItem(mainMenuPathKey)?.toString() as RoutesEnum;
