import IBaseItem from "../models/BaseItem";

export const findOption = (key?: string, array?: IBaseItem[]) => {
  return array?.filter((item) => item.title.toLowerCase() === key).pop();
};

export function searchOptions<T extends IBaseItem>(key?: string, array?: T[]): T[] {
  return array?.filter((item) => {
    if (key && item.title.toLowerCase().indexOf(key) === 0)
      return item;
  }) || [];
};
