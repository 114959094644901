import React from 'react';
import Logo from '../../../components/Logo';
import Typography from '../../../components/Typography';
import Colors from '../../../enums/Colors';
import * as UI from './styles';

const LoadingScreen: React.FC = () => (
  <UI.Wrapper>
    <Logo logoType='large' />
    <Typography type='button' color={Colors.WHITE}>18+</Typography>
  </UI.Wrapper>
);

export default LoadingScreen;
