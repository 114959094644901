import React from 'react';
import Typography from '../../../components/Typography';
import Button from '../../../components/BaseButton';
import * as UI from './styles';
import Colors from '../../../enums/Colors';

const ErrorMessage: React.FC = () => (
  <UI.ErrorPageWrapper>
    <UI.ErrorMessageWrapper>
      <Typography type="h1" color={Colors.DARK_PINK}>SORRY</Typography>
      <Typography type="button" color={Colors.GRAY}>
        Service is temporarily unavailable.<br/>Please try again later.
      </Typography>
      <Button
        caption="Try again"
        onClick={() => window.location.reload()}
      />
    </UI.ErrorMessageWrapper>
  </UI.ErrorPageWrapper>
);

export default ErrorMessage;
