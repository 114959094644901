import React from 'react';
import Typography from '../../components/Typography';
import Colors from '../../enums/Colors';
import * as UI from './styles';
import Logo from '../../components/Logo';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <UI.PageWrapper>
      <UI.ContentWrapper>
        <UI.PageHeader>
          <Logo logoType='medium' />
        </UI.PageHeader>
        {children}
      </UI.ContentWrapper>
      <Typography type="button" color={Colors.GRAY}>18+</Typography>
    </UI.PageWrapper>
  );
};

export default AuthLayout;
