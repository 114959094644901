import React, { useEffect } from 'react';
import * as UI from './styles';
import Typography from '../Typography';
import { useHistory } from 'react-router';
import Colors from '../../enums/Colors';
import Routes from '../../enums/Routes';
import navItems from '../../constants/navItems';
import { getSessionSymptoms, symptomsSentSelector } from '../../store/symptoms';
import { useDispatch, useSelector } from 'react-redux';

const NavBar: React.FC = () => {
  const symptomsSent = useSelector(symptomsSentSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSessionSymptoms());
  }, [dispatch]);

  const getTextColor = (path: Routes, disabled: boolean) => {
    if (!disabled || path === history.location.pathname) return Colors.BLUE;
    else return Colors.LIGHT_GRAY;
  };

  return (
    <UI.NavBarWrapper>
      <UI.NavItemsWrapper>
        {navItems.map((item, i) => {
          const isCurrentPath = item.route === history.location.pathname;
          const disabled = !symptomsSent && !item.enabled;
          const route = disabled ? '#' : item.route;

          return (
            <UI.NavItem
              isCurrentPath={isCurrentPath}
              to={route}
              key={i}
            >
              <UI.NavIconWrapper
                disabled={disabled}
                isCurrentPath={isCurrentPath}
              >
                {item.icon}
              </UI.NavIconWrapper>
              <UI.Title
                type="menu"
                color={getTextColor(item.route, disabled)}
              >
                {item.title}
              </UI.Title>
            </UI.NavItem>
          )
        })}
      </UI.NavItemsWrapper>
    </UI.NavBarWrapper>
  );
};

export default NavBar;
