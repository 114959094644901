import axios, { AxiosRequestConfig } from 'axios';

export const publicUrl = process.env.REACT_APP_BASE_URL || 'https://medzard.dev.itrev.ru';
export const apiBaseUrl = (process.env.REACT_APP_BASE_URL || '') + '/api';

export const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  }
});

export const getRequest = async (url: string, config?: AxiosRequestConfig) => {
  try {
    const response = await api.get(url, config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const postRequest = async (url: string, data?: Object) => {
  try {
    const response = await api.post(url, data);
    
    return response;
  } catch (err) {
      console.error(err);
  }
};

export const getBase64Img = (api: string, id: string) => {
  return getRequest(api + id, {
      responseType: 'arraybuffer'
    })
    .then(response => response && ("data:image/png;base64," + Buffer.from(response, 'binary').toString('base64')));
};
