import React from 'react';
import { getFileIcon } from '../../constants/fileIcons';
import { formatFileSize } from '../../helpers/formatFileSize';
import { IUploadFile } from '../../models/UploadFiles';
import MenuItem from '../MenuItem';
import Typography from '../Typography';
import * as UI from './styles';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteFile.svg';

interface IProps {
  files: IUploadFile[];
  deleteFile: (file: IUploadFile) => void;
}

const FileList: React.VFC<IProps> = ({ deleteFile, files, ...props }) => {
  const handleDelete = (file: IUploadFile) => {
    deleteFile(file);
  };

  const fileList = files.map((el) => {
    const matchs = el.name.match(/\.[0-9a-zA-Z]+$/);
    if (!matchs?.length) return null;
    const name = el.name.replace(/\.[0-9a-zA-Z]+$/, '');
    const ext = matchs[0].toLowerCase();
    const icon = getFileIcon(ext);

    return (
      <MenuItem CustomMark={() => <DeleteIcon onClick={() => handleDelete(el)} />} key={el.id}>
        <UI.FileInfo>
          {icon}
          <UI.FileDescription>
            <UI.FileName type="p">{name}</UI.FileName>
            <Typography type="p">{formatFileSize(el.size)}</Typography>
          </UI.FileDescription>
        </UI.FileInfo>
      </MenuItem>
    );
  });

  return <UI.Wrapper {...props}>{fileList}</UI.Wrapper>;
};

export default FileList;
