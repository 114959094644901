import RoutesEnum from "../enums/Routes";

export const getMenuRouteAction = (path: RoutesEnum | undefined, locationPath: string) => {
  const pathname = path || '';
  return locationPath !== pathname
    ? {
        pathname,
        state: {
          fromMenu: !!path,
        },
      }
    : pathname;
};
