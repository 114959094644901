import React from 'react';
import ButtonCounter from '../../../../components/ButtonCounter';
import Typography from '../../../../components/Typography';
import * as UI from '../styles'
import plus from '../../../../assets/icons/plus.svg';

interface IProps {
  handleAddButton: () => void
  specializationView: boolean
  bodySymtomsLength?: number
}

const AddGeneralComplaints: React.FC<IProps> = ({ handleAddButton, specializationView, bodySymtomsLength }) => {
  return (
    <UI.AddButton onClick={handleAddButton} isSpecializationView={specializationView}>
      <UI.PlusButton>
        <img src={plus} />
        <UI.PlusCounter isSpecializationView={specializationView}>
          {!!bodySymtomsLength && (
            <ButtonCounter type="transparent">{bodySymtomsLength}</ButtonCounter>
          )}
        </UI.PlusCounter>
      </UI.PlusButton>
      <Typography type="button">Add general complaints</Typography>
    </UI.AddButton>
  );
};

export default AddGeneralComplaints;
