import styled from 'styled-components/macro';
import Colors from '../../../enums/Colors';

export const ErrorPageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  background-color: ${Colors.OFF_WHITE};
  align-items: center;
`;

export const ErrorMessageWrapper = styled.div`
  margin: auto;
  text-align: center;

  & > :last-child {
    margin-top: 24px;
  }
`;