import ModalWrapper from './ModalWrapper';
import { createGlobalStyle } from 'styled-components';
import styled, { css, keyframes } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';

const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface IModalWrapper {
  visible?: boolean;
}

export const ModalBody = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  height: auto;
  max-height: 85vh;
  overflow-y: auto;
  padding: 24px;
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 0px 2px 8px -2px rgba(0, 26, 51, 0.2), 0px 8px 32px -8px rgba(0, 26, 51, 0.2);
  width: 90vw;
  max-width: 328px;

  & > :not(:last-child) {
    margin-bottom: 24px;

    ${props => props.theme.screen <= ScreenWidthEnum.landscapePhone && css`
      margin-bottom: 16px;
    `}
  }

  ${props => props.theme.screen >= ScreenWidthEnum.laptop && css`
    max-width: 480px;
  `}
`;

export const StyledModalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: ${({theme}) => theme.height}px;
  background-color: ${Colors.TRANSPARENT_BLUE};
  animation: ${opacity} 500ms ease forwards;

  ${(props: IModalWrapper) => props.visible === false && css`
    display: none;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  line-height: 1;

  img {
    align-self: baseline;
    width: 12px;
    margin-right: -10px;
    margin-left: auto;
    cursor: pointer;
  }
`;

export const ModalStyles = createGlobalStyle`
  body {
    background: ${Colors.TRANSPARENT_BLUE};
  }
`;

export {ModalWrapper}