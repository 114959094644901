import { screenReducer } from './screen/index';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import interceptorReducer from './interceptor';
import symptomsReducer from './symptoms';
import photosReducer from './diseasePhotos';
import userImagesReducer from './userImages';
import medicalTestReducer from './medicalTest';
import notificationReducer from './notification';
import modalReducer from './modal';

const reducers = combineReducers({
  interceptorReducer,
  symptomsReducer,
  photosReducer,
  userImagesReducer,
  screenReducer,
  medicalTestReducer,
  notificationReducer,
  modalReducer
});

export type IReduxState = ReturnType<typeof reducers>;

export type IAction<Type, Payload> = {
  type: Type;
  payload: Payload;
};

export default configureStore({reducer: reducers, middleware: [thunk]});
