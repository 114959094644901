import React from 'react';
import { useHistory } from 'react-router';
import Button from '../../../../components/BaseButton';
import Typography from '../../../../components/Typography';
import RoutesEnum from '../../../../enums/Routes';
import * as UI from './styles';
import success from '../../../../assets/icons/successCircle.svg';

const PasswordChanged: React.FC = () => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push(RoutesEnum.SIGN_IN);
  };

  return (
    <UI.TextWrapper>
      <img src={success} alt='' />
      <Typography type='h1' styles={UI.HeaderStyles}>Password has been changed</Typography>
      <Typography type='p'>
        Your password has been successfully changed. Now you need to sign in.<br/><br/>
        Follow the rules of digital security. Thank you.
      </Typography>
      <Button
        caption='Sign in'
        onClick={handleSignInClick}
      />
    </UI.TextWrapper>
  );
};

export default PasswordChanged;
