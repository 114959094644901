import styled, { css } from 'styled-components/macro';
import { CONTENT_WIDTH, VIEWPORT_HEIGHT, VIEWPORT_WIDTH } from '../../constants/viewport';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import { AlertType } from '../../models/Notifications';

export interface IAlertWrapper {
  type?: AlertType;
}

export const AlertWrapper = styled.div<IAlertWrapper>`
  display: flex;
  flex-flow: row;
  padding: 0px 16px;
  align-items: center;
  min-height: 40px;
  width: calc((${CONTENT_WIDTH} / ${VIEWPORT_WIDTH}) * 100%);
  max-width: 400px;
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    max-width: 610px;
  `}
  border-radius: 8px;
  background-color: ${(props) => props.type === "success" ? Colors.LIGHT_BLUE : Colors.PINK};
  position: absolute;
  bottom: calc((110 / ${VIEWPORT_HEIGHT}) * 100vh);
  left: 50%;
  transform: translate(-50%);

  & > :first-child {
    margin-right: 8px;
  }

  & > :last-child {
    height: 16px;
    margin-left: auto;
    opacity: 25%;
    cursor: pointer;
  }
`;
