import React, { useEffect } from 'react';
import * as UI from './styles';
import { ModalWrapper, ModalBody, ModalHeader } from '../../../../components/Modal';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import Typography from '../../../../components/Typography';
import CloseButton from '../../../../components/CloseButton';
import Button from '../../../../components/BaseButton';

export interface IDiseaseModalContent {
  title: string;
  body: string;
}

interface IDiseaseModal {
  handleVisibility: (visible: boolean) => void;
  content: IDiseaseModalContent;
}

const DiseaseModal: React.FC<IDiseaseModal> = ({
  handleVisibility,
  content
}) => {
  const { ref, isComponentVisible, setComponentVisible, handleChangeComponentVisibility } = useComponentVisible(
    !!content
  );

  useEffect(() => {
    if (content) setComponentVisible(!!content);
  }, [content]);

  useEffect(() => {
    handleVisibility(isComponentVisible);
  }, [isComponentVisible]);

  const handleClose = () => {
    setComponentVisible(false)
  }

  return (
    <ModalWrapper>
      <ModalBody ref={ref}>
        <ModalHeader>
          <Typography type="h2">
            {content.title}
          </Typography>
          <CloseButton onClick={handleChangeComponentVisibility} />
        </ModalHeader>
        <UI.ParagraphWrapper>
          <Typography type="p">
            {content.body}
          </Typography>
        </UI.ParagraphWrapper>
        <Button
          onClick={handleClose}
          buttonType="filled"
          caption="Close"
          width="100%"
        />
      </ModalBody>
    </ModalWrapper>
  );
};

export default DiseaseModal;
