import { getRequest, postRequest } from "../../api";
import { sessionId } from "../../helpers/session";
import { IDiagnosis } from "../../models/Disease";
import { IUsageHistory } from "../../models/Usage";

export const saveDiseases = (diseases: IDiagnosis[]) => {
  return postRequest("/usage/add/", diseases.map((disease) => ({
    diseaseId: disease.diseases.id,
    sessionId: sessionId(),
    place: disease.place,
    weight: disease.weight
  })));
};

export const getUsageHistory = (): Promise<IUsageHistory> => {
  return getRequest('/usage/get/');
};

export const getPreviousDay = (dateOffset: number): Promise<IUsageHistory> => {
  return getRequest(`/usage/get/?dateOffset=${dateOffset}`);
};
