import React from 'react';
import close from '../../assets/icons/close.svg';

interface ICloseButton {
  onClick: () => void;
}

const CloseButton: React.FC<ICloseButton> = ({
  onClick
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return <img src={close} onClick={handleClick} />;
};

export default CloseButton;
