import styled, { css } from 'styled-components';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';

export const LayoutWrapper = styled.div`
  display: flex;
  height: 100vh;
  height: ${(props) => props.theme.height}px;
  background-color: ${Colors.OFF_WHITE};
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  ${props => props.theme.screen >= ScreenWidthEnum.laptop && css`
    overflow: hidden;
  `}
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${props => props.theme.screen >= ScreenWidthEnum.laptop && css`
    overflow-y: scroll;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);

    ::-webkit-scrollbar {
      background-color: ${Colors.OFF_WHITE};
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${Colors.TRANSPARENT_BLACK};
      border-radius: 8px;
      width: 8px;
    }
  `}
`;

export const LayoutContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  width: 90vw;
  max-width: 376px;
  height: ${(props) => props.theme.height}px;
  height: 100%;

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    max-width: 90vw;
  `}
  
  ${props => props.theme.screen === ScreenWidthEnum.laptop && css`
    max-width: 680px;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    max-width: 824px;
  `}
`;
