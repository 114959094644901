import React, { useState, ChangeEventHandler, useRef } from 'react';
import * as UI from './styles';
import eye from '../../assets/icons/eye.svg';
import Typography from '../Typography';
import Colors from '../../enums/Colors';

export interface IInputBase {
  name: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'password';
  label?: string;
  hint?: string;
  showPasswordToggle?: boolean;
  autocomplete?: 'on' | 'off' | 'new-password';
}

interface IInput extends IInputBase {
  value?: string;
  error?: string | boolean;
  onBlur?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Input: React.FC<IInput> = ({
  name,
  value,
  placeholder,
  error,
  label,
  hint,
  type = 'text',
  onBlur,
  onChange,
  showPasswordToggle,
  autocomplete
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const ref = useRef<HTMLInputElement>(null)

  const handlePasswordToggleClick = () => {
    setShowPassword(!showPassword);
  };


  return (
    <UI.InputWrapper>
      {label && <UI.Label htmlFor={name}>{label}</UI.Label>}
      {(type === 'password' && showPasswordToggle) ? 
        <UI.StyledInputWrapper>
          <UI.StyledInput
            ref={ref}
            id={name}
            value={value}
            name={name}
            placeholder={placeholder}
            error={!!error}
            type={showPassword ? 'text' : type}
            onBlur={onBlur}
            onChange={onChange}
            isPassword
            autoComplete={autocomplete}
          />
          <UI.PasswordToggle
            src={eye}
            dark={showPassword}
            onClick={handlePasswordToggleClick}
            alt=''
          />
        </UI.StyledInputWrapper> :
        <UI.StyledInput
          ref={ref}
          id={name}
          value={value}
          name={name}
          placeholder={placeholder}
          error={!!error}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
          autoComplete={autocomplete}
        />
      }
      {(typeof error === 'string' || hint) && 
        <Typography
          type='hint'
          color={error ? Colors.RED_ERROR : Colors.GRAY}
        >
          {error || hint}
        </Typography>
      }
    </UI.InputWrapper>
  );
};

export default Input;
