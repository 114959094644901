import styled, { css } from 'styled-components/macro';
import { ILogo, logoType } from '.';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';

type logoFontSizeType = {
  [type in logoType]: number;
};

const logoFontSize: logoFontSizeType = {
  'large': 48,
  'medium': 32,
  'small': 24
}

const logoFontSizeDesktop: logoFontSizeType = {
  'large': 64,
  'medium': 40,
  'small': 32
}

export const MedSpan = styled.span<ILogo>`
  color: ${props => props.logoType === 'large' ? Colors.WHITE : Colors.BLUE};
`;

export const ZardSpan = styled.span`
  color: ${Colors.TEAL};
`;

export const Paragraph = styled.p<ILogo>`
  font-family: 'Suez One';
  font-size: ${props => logoFontSize[props.logoType]}px;
  line-height: ${props => logoFontSize[props.logoType]}px;
  letter-spacing: -0.2px;

  ${props => props.logoType === 'large' && css`
    text-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  `};

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    font-size: ${logoFontSizeDesktop[props.logoType]}px;
    line-height: ${logoFontSizeDesktop[props.logoType]}px;
  `}
`;
