import styled, { css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import checkmark from '../../assets/icons/checkmarkWhite.svg';
import { ScreenWidthEnum } from '../../enums/screenWidth';

export type CheckboxType = "image" | "text";

export interface IStyledCheckbox {
  checked?: boolean;
  type?: CheckboxType;
}

export const CheckboxWrapper = styled.div<IStyledCheckbox>`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;

  p {
    white-space: pre-wrap;
    line-height: normal;
  }

  ${(props) => props.type === "image" && css`
    display: block;
    width: 76px;
    height: 76px;
    ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
      width: 95px;
      height: 95px;
    `}
    ${props => props.theme.screen === ScreenWidthEnum.tablet && css`
      width: 88px;
      height: 88px;
    `}
    ${props => props.theme.screen === ScreenWidthEnum.laptop && css`
      width: 164px;
      height: 164px;
    `}
    ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
      width: 200px;
      height: 200px;
    `}
    border-radius: 8px;
    border: 1px solid ${props.checked ? Colors.BLUE : Colors.DARK_GRAY};
    position: relative;
    background-color: ${Colors.DARK_GRAY};

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `}
`;

export const CheckboxBox = styled.div<IStyledCheckbox>`
  min-width: 24px;
  min-height: 24px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid ${Colors.LIGHT_GRAY};
  background-color: ${Colors.WHITE};

  ${(props) => props.checked && css`
    background: url(${checkmark}) no-repeat, ${Colors.BLUE};
    background-position: center center;
    border-color: ${Colors.BLUE};
  `}

  ${(props) => props.type === "image" && css`
    position: absolute;
    right: 4px;
    top: 4px;
    margin-right: 0px;
  `}
`;
