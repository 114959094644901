import { IReduxState } from '..';
import { api } from '../../api';

enum ACTIONS {
  INTERCEPT_STATUS = 'INTERCEPT_STATUS'
}

type IAction<Type, Payload> = {
  type: Type;
  payload: Payload;
};

type Action = IAction<ACTIONS.INTERCEPT_STATUS, number>;

interface IInterceptorState {
  status?: number;
}

const initialState: IInterceptorState = {
  status: 200,
};

export const statusInterceptor = () => (dispatch: Function) => {
  api.interceptors.response.use(
    (response) => {
      if (response.status === 200)
        dispatch({ type: ACTIONS.INTERCEPT_STATUS, payload: response.status });
      return response;
    },
    (error) => {
      dispatch({
        type: ACTIONS.INTERCEPT_STATUS,
        payload: error.response ? error.response.status : undefined,
      });
      return Promise.reject(error);
    }
  );
};

const interceptorReducer = (
  state: IInterceptorState = initialState,
  action: Action
): IInterceptorState => {
  switch (action.type) {
    case ACTIONS.INTERCEPT_STATUS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

export const statusSelector = (state: IReduxState) => state.interceptorReducer.status;

export default interceptorReducer;
