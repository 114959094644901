import styled, { css } from 'styled-components/macro';
import { MenuItemType } from '.';
import Colors from '../../enums/Colors';

export interface IMenuItemWrapper {
  selected?: boolean;
  type?: MenuItemType;
  boxShadow?: boolean;
}

export const MenuItemWrapper = styled.div<IMenuItemWrapper>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.type !== "checkbox" ? "space-between" : "flex-start"};
  width: 100%;
  padding: ${(props) => props.type === "checkbox" || props.type === "whitelist" ? "8px" : "13px"} 16px;
  position: relative;
  border-radius: 8px;
  background-color: ${Colors.WHITE};
  cursor: pointer;
 
  ${props => props.boxShadow && css`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  `}

  ${props => props.type === 'whitelist' && css`
    border: 2px solid transparent;
  `}

  ${(props) => props.selected && css`
    background-color: ${props.type !== "checkbox" && Colors.OFF_WHITE};
    ${props.type === 'whitelist' && css`
      border: 2px solid ${Colors.BLUE};
      background-color: ${Colors.WHITE};
    `}
  `}

  ${(props) => props.selected && props.type === "checkbox" && css`
    border: 2px solid ${Colors.BLUE};
    justify-content: space-between;
  `}

  p {
    max-width: 90%;
  }
`;


export const Counter = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`
