import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileList from '../../../../components/FileList';
import HeaderWithTitle from '../../../../components/HeaderWithTitle';
import RoutesEnum from '../../../../enums/Routes';
import { IUploadFile } from '../../../../models/UploadFiles';
import {
  medicalTestActions,
  medicalTestSelectors,
  MedicalTestStepEnum,
} from '../../../../store/medicalTest';
import * as RootUI from '../styles';
import UploadMedicalFile from '../UploadMedicalFile';
import * as UI from './styles'

const ConfirmFiles: React.VFC = () => {
  const currentTest = useSelector(medicalTestSelectors.currentTest);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentTest?.files.length) {
      dispatch(medicalTestActions.setStep(MedicalTestStepEnum.LOAD_DATA))
    }
  }, [currentTest])

  const handleDeleteFile = (file: IUploadFile) => {
    if (currentTest)
      dispatch(
        medicalTestActions.removeFile({
          testId: currentTest?.id,
          ...file,
        }),
      );
  };
  const nextStep = () => {
    dispatch(medicalTestActions.setStep(MedicalTestStepEnum.RESULT_PROCESSING));
  };

  return (
    <RootUI.Wrapper>
      <HeaderWithTitle
        backRoute={RoutesEnum.CORRECTIONS}
        pageTitle="Medical Test / "
        greenTitle={currentTest?.title}
        description="Your uploaded documents. You can delete or change before submitting"
      />
      <UI.StyledContent>
        {currentTest && <UI.StyledFileList files={currentTest?.files} deleteFile={handleDeleteFile} />}
        <RootUI.Control>
          <UploadMedicalFile />

          <RootUI.StyledButton
            buttonType="filled"
            caption="Upload and recognize"
            onClick={nextStep}
          />
        </RootUI.Control>
      </UI.StyledContent>
    </RootUI.Wrapper>
  );
};

export default ConfirmFiles;
