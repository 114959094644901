import Colors from '../enums/Colors';
import Routes from '../enums/Routes';
import {ReactComponent as Symptoms} from '../assets/icons/nav/symptoms.svg';
import {ReactComponent as Corrections} from '../assets/icons/nav/corrections.svg';
import {ReactComponent as Test} from '../assets/icons/nav/tests.svg';
import {ReactComponent as Treatment} from '../assets/icons/nav/treatment.svg';
import { ReactElement } from 'react';

interface INavItem {
  title: string;
  route: Routes;
  icon: ReactElement;
  enabled?: boolean
}

const navItems: INavItem[] = [
  {
    title: 'Symptoms',
    route: Routes.SYMPTOMS,
    enabled: true,
    icon: <Symptoms />
  },
  {
    title: 'Corrections',
    route: Routes.CORRECTIONS,
    icon: <Corrections />
  },
  {
    title: 'Medical test',
    route: Routes.TEST,
    icon: <Test />
  },
  {
    title: 'Treatment',
    route: Routes.TREATMENT,
    icon: <Treatment />
  },
];

export const navRoutes = navItems.map((item) => item.route);

export default navItems;
