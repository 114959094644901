import styled, { css } from 'styled-components/macro';
import { ButtonItemType } from '.';
import Colors from '../../enums/Colors';

interface IButtonItemWrapper {
  itemType?: ButtonItemType;
}

export const ButtonItemWrapper = styled.button<IButtonItemWrapper>`
  display: flex;
  flex-flow: row;
  padding: ${(props) => props.itemType === "filled" ? '0px 16px' : '10px 8px 10px 16px'};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  appearance: none;
  background: none;
  min-height: fit-content;

  ${(props) => props.itemType === "filled" && css`
    height: 40px;
    border: auto;
    background: ${Colors.WHITE};
    border-radius: 8px;

    :hover, :active {
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    }
  `}
`;
