import styled from 'styled-components/macro';
import { ModalBody } from '../../../../components/Modal';

export const ListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(45vh - 88px);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  & > :first-child {
    margin-bottom: 8px;
  }
`;

export const StyledModalBody = styled(ModalBody)`
  max-height: none;
`;
