import React from 'react';
import Button from '../../../../components/BaseButton';
import Typography from '../../../../components/Typography';
import { StyledForm, FormWrapper } from '../../../../components/Form';
import { useForm } from 'react-hook-form';
import ControlledInput from '../../../../components/ControlledInput';
import EmailInput from '../../../../components/EmailInput';
import PasswordInput from '../../../../components/PasswordInput';

interface ISignUpForm {
  email: string;
  firstName: string;
  lastName?: string;
  password: string;
}

const SignUpForm: React.FC = () => {
  const { handleSubmit, control, formState: { errors } } = useForm<ISignUpForm>();

  const handleSignUp = (data: ISignUpForm) => {};

  return (
    <FormWrapper>
      <Typography type='h1'>Sign up</Typography>
      <StyledForm
        onSubmit={handleSubmit(data => handleSignUp(data))}
        autoComplete='off'
      >
        <EmailInput
          control={control}
          error={errors.email}
        />
        <ControlledInput
          name='firstName'
          control={control}
          rules={{
            required: 'Required field'
          }}
          label='First name'
          error={errors.firstName}
          autocomplete='off'
        />
        <ControlledInput
          name='lastName'
          control={control}
          label='Last name (optional)'
          autocomplete='off'
        />
        <PasswordInput 
          control={control}
          error={errors.password}
          label='Password'
          showPasswordToggle
          showRequiredMessage
          useLengthRules
        />
        <Button isSubmit caption='Sign up' />
      </StyledForm>
    </FormWrapper>
  );
};

export default SignUpForm;
