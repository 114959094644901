import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';

type TypographyType = 'h1' | 'h2' | 'p' | 'link' | 'menu' | 'button' | 'hint';

type FontColorType = {
  [type in TypographyType]: Colors;
};

type FontSizeType = {
  [type in TypographyType]: number;
};

interface ITypographyProps {
  type: TypographyType;
  color?: string;
  lineHeight?: number;
  styles?: FlattenSimpleInterpolation;
}

const fontSize: FontSizeType = {
  'h1': 24,
  'h2': 18,
  'p': 15,
  'link': 16,
  'button': 16,
  'menu': 14,
  'hint': 12
};

const desktopFontSize: FontSizeType = {
  'h1': 32,
  'h2': 24,
  'p': 18,
  'link': 20,
  'button': 20,
  'menu': 16,
  'hint': 16
};

const fontWeight: FontSizeType = {
  'h1': 700,
  'h2': 700,
  'p': 400,
  'link': 500,
  'button': 600,
  'menu': 600,
  'hint': 400
};

const fontHeight: FontSizeType = {
  'h1': 32,
  'h2': 24,
  'p': 20,
  'link': 24,
  'button': 20,
  'menu': 12,
  'hint': 16
};

const desktopFontHeight: FontSizeType = {
  'h1': 40,
  'h2': 32,
  'p': 24,
  'link': 20,
  'button': 24,
  'menu': 20,
  'hint': 20
};

const fontColor: FontColorType = {
  'h1': Colors.BLACK,
  'h2': Colors.BLACK,
  'p': Colors.GRAY,
  'link': Colors.BLUE,
  'button': Colors.BLUE,
  'menu': Colors.BLUE,
  'hint': Colors.GRAY
};

const Typography = styled.p<ITypographyProps>`
  ${(props) => css`
    font-size: ${fontSize[props.type]}px;
    ${props.theme.screen === ScreenWidthEnum.desktop && css`
      font-size: ${desktopFontSize[props.type]}px;
    `}
    font-weight: ${fontWeight[props.type]};
    line-height: ${props.lineHeight || fontHeight[props.type]}px;
    ${props.theme.screen === ScreenWidthEnum.desktop && css`
      line-height: ${props.lineHeight || desktopFontHeight[props.type]}px;
    `}
    color: ${props.color || fontColor[props.type]};
  `}

  ${(props) => props.type === 'link' && css`
    border-bottom: 1px solid;
    line-height: 1.2;
  `}
  
  ${({ styles }) => styles}
`;

export default Typography;
