import { getRequest } from "../../api";
import { Gender } from "../../constants/avatar";
import { IOrganSymptoms, ISessionSymptoms } from "../../models/Symptom";
import queryString from 'querystring'

export const fetchSpecializationSymptoms = (specialization_id: string, organ_id: string): Promise<IOrganSymptoms[]> => {
  const query = queryString.stringify({
    specialization_id,
    organ_id
  })
  return getRequest(`/organ/specialization/?${query}`);
};

export const fetchPositionSymptoms = (position: string, sex: Gender): Promise<IOrganSymptoms[]> => {
  return getRequest(`/organ/position/?position=${position}&sex=${sex}`);
};

export const fetchOrgan = (organId: string): Promise<IOrganSymptoms> => {
  return getRequest(`/organ/${organId}`);
};
