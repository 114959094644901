import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditImageModal from '../../../../components/EditImageModal';
import Typography from '../../../../components/Typography';
import { ACCEPT_MIME, MAX_SIZE } from '../../../../constants/image';
import { removeFromArray } from '../../../../helpers/removeFromArray';
import { uploadFileChecker } from '../../../../helpers/uploadFileChecker';
import { Files } from '../../../../models/UploadFiles';
import {
  medicalTestActions,
  medicalTestSelectors,
  MedicalTestStepEnum,
  UploadMode,
} from '../../../../store/medicalTest';
import { notificationActions } from '../../../../store/notification';
import * as RootUI from '../styles';
import * as UI from './styles';
import TITLES from './Titles';
import * as LoadDataStyles from '../LoadData/styles' 

interface IProps {
  newFile?: boolean;
}

type ChangeEvent = React.ChangeEventHandler<HTMLInputElement>;

const UploadMedicalFile: React.FC<IProps> = ({ newFile }) => {
  const currentMode = useSelector(medicalTestSelectors.mode);
  const currentTest = useSelector(medicalTestSelectors.currentTest);

  const dispatch = useDispatch();
  const [images, setImages] = useState<File[]>([]);

  const acceptString = ACCEPT_MIME.join(', ');

  const setAlert = (message: string) => {
    dispatch(
      notificationActions.add({
        message,
        type: 'error',
      }),
    );
  };

  const handleAddImages = (files: Files) => {
    if (!files) return;
    const checkedFiles = uploadFileChecker(files, ACCEPT_MIME, MAX_SIZE, setAlert);

    setImages((arr) => [...arr, ...checkedFiles]);
  };
  const handleApply = (url: string) => {
    const file = images[0];
    if (!currentTest || !images[0]) return;

    dispatch(
      medicalTestActions.addFile({
        testId: currentTest?.id,
        name: file.name,
        size: file.size,
        url,
      }),
    );

    setImages((arr) => {
      if (arr.length === 1) newFile && dispatch(medicalTestActions.setStep(MedicalTestStepEnum.CONFIRM_FILES));
      return removeFromArray(arr, 0, 1)
    });
    
  };
  const handleAddDocument = (files: Files, redirect = true) => {
    if (!files || !currentTest) return;
    const checkedFiles = uploadFileChecker(files, ['application/pdf'], MAX_SIZE, setAlert);
    checkedFiles.forEach(async (el) => {
      dispatch(
        medicalTestActions.addFile({
          testId: currentTest.id,
          name: el.name,
          size: el.size,
          url: await el.text(),
        }),
      );
    });
    redirect && dispatch(medicalTestActions.setStep(MedicalTestStepEnum.CONFIRM_FILES));
    currentMode !== UploadMode.ALL && dispatch(medicalTestActions.setMode(UploadMode.DOCUMENT));
  };

  const handleAddFile = (fileList: Files) => {
    if (!fileList) return;

    const files = uploadFileChecker(
      fileList,
      [...ACCEPT_MIME, 'application/pdf'],
      MAX_SIZE,
      setAlert,
    );

    const documents = files.filter((el) => el.type === 'application/pdf');
    const images = files.filter((el) => ACCEPT_MIME.some((mime) => mime === el.type));

    handleAddImages(images);
    handleAddDocument(documents, !images.length);
  };

  const clearValue: ChangeEvent = (e) => {
    e.target.value = ''
  }
  const addImage: ChangeEvent = (e) => {
    handleAddImages(e.target.files)
    clearValue(e)
  };
  const addDocument: ChangeEvent = (e) => {
    handleAddDocument(e.target.files)
    clearValue(e)
  };
  const addFile: ChangeEvent = (e) => {
    handleAddFile(e.target.files)
    clearValue(e)
  };

  const handleClose = () => {
    setImages((arr) => {
      const newArr = removeFromArray(arr, 0, 1);

      if (!newArr.length) {
        dispatch(medicalTestActions.setStep(MedicalTestStepEnum.CONFIRM_FILES));
        currentMode !== UploadMode.ALL && dispatch(medicalTestActions.setMode(UploadMode.IMAGE));
      }
      return newArr;
    });
  }

  return (
    <>
      <RootUI.StyledButton buttonType="transparent">
        <LoadDataStyles.Label htmlFor={currentMode || ''}>
          <Typography type='button'>{TITLES.get(`${currentMode}.${newFile ? 'addNew' : 'add'}`)}</Typography>
        </LoadDataStyles.Label>
      </RootUI.StyledButton>
      <UI.Input
        type="file"
        id={UploadMode.IMAGE}
        accept={acceptString}
        multiple
        onChange={addImage}
      />
      <UI.Input
        type="file"
        id={UploadMode.DOCUMENT}
        multiple
        accept="application/pdf"
        onChange={addDocument}
      />
      <UI.Input type="file" id={UploadMode.ALL} onChange={addFile} multiple />
      {!!images?.length && (
        <EditImageModal
          isVisible={!!images[0]}
          image={URL.createObjectURL(images[0])}
          onApply={handleApply}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default UploadMedicalFile;
