import { IResult } from './ResultFrame';

export const RESULT: IResult[] = [
  {
    date: new Date(),
    testsList: [
      {
        files: ['Protocol_186574665', 'Protocol_036895789', 'Protocol_123456789'],
        title: 'Blood test',
      },
      {
        files: ['Protocol_186512312', 'Protocol_0361232329', 'Protocol_23111189'],
        title: 'Urine test',
      },
    ],
  },
  {
    date: new Date(),
    testsList: [
      {
        files: ['Protocol_186574665', 'Protocol_036895789', 'Protocol_123456789'],
        title: 'Blood test',
      },
      {
        files: ['Protocol_186512312', 'Protocol_0361232329', 'Protocol_23111189'],
        title: 'Urine test',
      },
      {
        files: ['Protocol_186512312', 'Protocol_0361232329', 'Protocol_23111189'],
        title: 'Cardiogram',
      },
    ],
  },
];
