import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 24px;

  & > :first-child {
    margin-bottom: 5px;
  }
`;
