import React, { useState } from 'react';
import * as UI from './styles';
import Typography from '../Typography';
import plus from '../../assets/icons/dropdown/plus.svg';
import minus from '../../assets/icons/dropdown/minus.svg';
import ColorsEnum from '../../enums/Colors';

interface IDropdownContent {
  caption: string;
}

const DropdownContent: React.FC<IDropdownContent> = ({
  caption,
  children
}) => {
  const [showContent, setShowContent] = useState(false);

  const handleContentVisibility = () => {
    setShowContent(!showContent);
  };

  return (
    <UI.DropdownWrapper>
      <UI.DropdownHeader onClick={handleContentVisibility}>
        <UI.Title type="p" color={ColorsEnum.BLACK}>{caption}</UI.Title>
        <img src={showContent ? minus : plus} />
      </UI.DropdownHeader>
      {children &&
        <UI.DropdownContentWrapper show={showContent}>
          {children}
        </UI.DropdownContentWrapper>
      }
    </UI.DropdownWrapper>
  );
};

export default DropdownContent;
