import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  background: linear-gradient(180deg, #2E75B5 0%, #266298 100%);
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  align-items: center;
  position: fixed;

  & > :first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  & > :last-child {
    align-self: flex-end;
    margin: 0px 27px 20px 0px;
  }
`;
