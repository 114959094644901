import styled, { css } from 'styled-components/macro';

export const TextWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
  justify-items: center;
`;

export const HeaderStyles = css`
  text-align: center;
`;
