import { useState, useEffect, useRef, useCallback } from 'react';

export default function useComponentVisible(
  initialVisible: boolean,
  outsideCallback?: () => void
) {
  const [isComponentVisible, setComponentVisible] = useState(initialVisible);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (isComponentVisible && outsideCallback) {
        outsideCallback();
      }
      setComponentVisible(false);
    }
    return null;
  };

  const handleChangeComponentVisibility = useCallback(() =>
    setComponentVisible(!isComponentVisible),
  [setComponentVisible, isComponentVisible]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setComponentVisible, handleChangeComponentVisibility };
};
