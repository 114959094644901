import { Files } from '../models/UploadFiles';

export const uploadFileChecker = (
  files: Files,
  acceptMIME: string[],
  maxSize: number,
  onError: (error: string) => void,
) => {
  const acceptString = acceptMIME.join(', ');
  const result: File[] = [];

  if (files?.length) {
    for (let i = 0; i < files.length; i++) {
      if (!acceptMIME.includes(files[i].type)) {
        onError('Accepted file types: ' + acceptString.replaceAll(/[a-zA-Z]+\//g, '.'));
      } else if (files[i].size > maxSize) {
        onError('Uploaded image exceeded the maximum file size (10 MB).');
      } else if (files[i].size <= 0) {
        onError('Uploaded image is invalid.');
      } else result.push(files[i]);
    }
  }

  return result;
};
