export class SessionSymptom {
  id: string;
  organId: string;
  sex: string;
  position: string;

  constructor(id: string, organId: string, sex: string, position: string) {
    this.id = id;
    this.organId = organId;
    this.sex = sex;
    this.position = position;
  }
};
