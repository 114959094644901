import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditImageModal from '../../../../components/EditImageModal';
import {
  medicalTestActions,
  medicalTestSelectors,
  MedicalTestStepEnum,
  UploadMode,
} from '../../../../store/medicalTest';
import { ACCEPT_MIME, MAX_SIZE } from '../../../../constants/image';
import * as RootUI from '../styles';
import * as UI from './styles';
import 'cropperjs/dist/cropper.css';
import { removeFromArray } from '../../../../helpers/removeFromArray';
import { uploadFileChecker } from '../../../../helpers/uploadFileChecker';
import { notificationActions } from '../../../../store/notification';
import { screenSelectors } from '../../../../store/screen';
import { ScreenWidthEnum } from '../../../../enums/screenWidth';
import HeaderWithTitle from '../../../../components/HeaderWithTitle';
import RoutesEnum from '../../../../enums/Routes';
import { Files } from '../../../../models/UploadFiles';
import Typography from '../../../../components/Typography';

const LoadData: React.VFC = () => {
  const currentTest = useSelector(medicalTestSelectors.currentTest);
  const currentMode = useSelector(medicalTestSelectors.mode);
  const screen = useSelector(screenSelectors.width);
  const dispatch = useDispatch();
  const [images, setImages] = useState<File[]>([]);

  const acceptString = ACCEPT_MIME.join(', ');

  const setAlert = (message: string) => {
    dispatch(
      notificationActions.add({
        message,
        type: 'error',
      }),
    );
  };

  const handleAddImages = (files: Files) => {
    if (!files) return;
    const checkedFiles = uploadFileChecker(files, ACCEPT_MIME, MAX_SIZE, setAlert);

    setImages((arr) => [...arr, ...checkedFiles]);
  };
  const handleAddDocument = async (files: Files, redirect = true) => {
    if (!files || !currentTest) return;
    const checkedFiles = uploadFileChecker(files, ['application/pdf'], MAX_SIZE, setAlert);
    await Promise.all(checkedFiles.map(async el => dispatch(
        medicalTestActions.addFile({
          testId: currentTest.id,
          name: el.name,
          size: el.size,
          url: await el.text(),
        }),
      )
    ))

    redirect && dispatch(medicalTestActions.setStep(MedicalTestStepEnum.CONFIRM_FILES));
    currentMode !== UploadMode.ALL && dispatch(medicalTestActions.setMode(UploadMode.DOCUMENT));
  };

  const handleApply = (url: string) => {
    const file = images[0];
    if (!currentTest || !images[0]) return;

    dispatch(
      medicalTestActions.addFile({
        testId: currentTest.id,
        name: file.name,
        size: file.size,
        url,
      }),
    );

    setImages((arr) => {
      const newArr = removeFromArray(arr, 0, 1);

      if (!newArr.length) {
        dispatch(medicalTestActions.setStep(MedicalTestStepEnum.CONFIRM_FILES));
        currentMode !== UploadMode.ALL && dispatch(medicalTestActions.setMode(UploadMode.IMAGE));
      }
      return newArr;
    });
  };
  const handleDrop = (fileList: FileList | null) => {
    if (!fileList) return;
    const files = uploadFileChecker(
      fileList,
      [...ACCEPT_MIME, 'application/pdf'],
      MAX_SIZE,
      setAlert,
    );

    const documents = files.filter((el) => el.type === 'application/pdf');
    const images = files.filter((el) => ACCEPT_MIME.some((mime) => mime === el.type));

    images.length && handleAddImages(images);
    documents.length && handleAddDocument(documents, !images.length);
  };
  const handleClose = () => {
    setImages((arr) => {
      const newArr = removeFromArray(arr, 0, 1);

      if (!newArr.length) {
        dispatch(medicalTestActions.setStep(MedicalTestStepEnum.CONFIRM_FILES));
        currentMode !== UploadMode.ALL && dispatch(medicalTestActions.setMode(UploadMode.IMAGE));
      }
      return newArr;
    });
  }

  const handleAttachFile = (callback: (fileList: FileList | null) => void, e: React.ChangeEvent<HTMLInputElement>) => {
    callback(e.target.files)
    e.currentTarget.value = ''
  }

  return (
    <RootUI.Wrapper>
      <HeaderWithTitle
        backRoute={RoutesEnum.CORRECTIONS}
        pageTitle="Medical Test / "
        description="Choose a convenient way to download test results. Please note that the laboratory test
          form must contain typewritten text, MedZard is not yet able to process handwritten results"
        greenTitle={currentTest?.title}
      />
      <UI.Content>
        <UI.Control>
          {screen >= ScreenWidthEnum.laptop ? (
            <>
              <UI.StyledDragAndDrop onDrop={handleDrop} />
              <RootUI.StyledButton buttonType="transparent" width="100%">
                <UI.Label htmlFor="all"><Typography type="button">Upload file</Typography></UI.Label>
              </RootUI.StyledButton>
            </>
          ) : (
            <>
              <RootUI.StyledButton buttonType="transparent" width="100%">
                <UI.Label htmlFor="gallery"><Typography type="button">Select photo from galery</Typography></UI.Label>
              </RootUI.StyledButton>
              <RootUI.StyledButton buttonType="transparent" width="100%">
                <UI.Label htmlFor="document"><Typography type="button">Upload PDF document</Typography></UI.Label>
              </RootUI.StyledButton>
            </>
          )}
        </UI.Control>
      </UI.Content>

      <UI.Input type="file" id="all" multiple onChange={(e) => handleAttachFile(handleDrop, e)} value={undefined} />
      <UI.Input
        type="file"
        id="gallery"
        accept={acceptString}
        multiple
        onChange={(e) => handleAttachFile(handleAddImages, e)}
      />
      <UI.Input
        type="file"
        id="document"
        accept="application/pdf"
        onChange={(e) => handleAttachFile(handleAddDocument, e)}
      />
      {!!images?.length && (
        <EditImageModal
          isVisible={!!images[0]}
          image={URL.createObjectURL(images[0])}
          onApply={handleApply}
          onClose={handleClose}
        />
      )}
    </RootUI.Wrapper>
  );
};

export default LoadData;
