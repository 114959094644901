import React, { useState } from 'react';
import Button from '../../../components/BaseButton';
import SignInForm from './SignInForm';
import TermsModal from './TermsModal';
import { setShowModal, showModal, showTermsModalKey } from '../../../helpers/session';
import { useHistory } from 'react-router';
import QuestionCaption from '../../../components/QuestionCaption';
import * as UI from './styles'
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../store/modal';

const SignIn: React.FC = () => {
  const [termsModal, setTermsModal] = useState(showModal(showTermsModalKey));
  const dispatch = useDispatch()
  const [alert, setAlert] = useState('');

  const handleModal = () => {
    setShowModal(showTermsModalKey, false);
    setTermsModal(false);
  };

  const handleSignUpClick = () => {
    dispatch(modalActions.open('featureInDev'))
  };

  const handleCloseAlert = () =>
    setAlert('');

  return (
    <>
      <SignInForm setAlert={setAlert} />
      <QuestionCaption caption='Not a member?' />
      <Button
        buttonType="transparent"
        caption="Sign up"
        onClick={handleSignUpClick}
      />
      {termsModal && <TermsModal handleVisibility={handleModal} />}
      {alert &&
        <UI.StyledAlert
          type='error'
          caption={alert}
          handleClose={handleCloseAlert}
        />
      }
    </>
  );
};

export default SignIn;
