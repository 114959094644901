import React from 'react'
import { ButtonType } from '../BaseButton'
import * as UI from './styles'

interface IProps {
  type?: ButtonType
}

const ButtonCounter: React.FC<IProps> = ({type = "filled", children}) => {
  return (
    <UI.Counter type={type}><p>{children}</p></UI.Counter>
  )
}

export default ButtonCounter