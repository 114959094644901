import styled, { css } from 'styled-components/macro';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import Button from "../BaseButton";

export const BackButton = styled(Button)`
  width: 104px;
  position: absolute;
  left: 16px;
  
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    width: 130px;
  `}
`