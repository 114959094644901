import styled, { keyframes } from 'styled-components/macro';

export const CheckboxListWrapper = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
`;

const loadingAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(
      90deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.2) 50%,
      rgba(255,255,255,0) 100%
    );
    animation: ${loadingAnimation} 2s infinite;
  }
`;
