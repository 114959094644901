import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../../components/BaseButton';
import Typography from '../../../../components/Typography';
import { fetchCurrentUser } from '../../../../services/user';
import { auth } from '../../../../helpers/auth';
import { StyledForm, FormWrapper } from '../../../../components/Form';
import { useForm } from 'react-hook-form';
import RoutesEnum from '../../../../enums/Routes';
import ControlledInput from '../../../../components/ControlledInput';
import PasswordInput from '../../../../components/PasswordInput';
import ColorsEnum from '../../../../enums/Colors';
import { modalActions } from '../../../../store/modal';
import { useDispatch } from 'react-redux';
import { resetSessionSymptoms } from '../../../../store/symptoms';

interface ISignInForm {
  login: string;
  password: string;
}
interface IProps {
  setAlert: (v: string) => void
}
const SignInForm: React.FC<IProps> = ({setAlert}) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { handleSubmit, control, formState: { errors }, setError } = useForm<ISignInForm>();

  const handleSignIn = (data: ISignInForm) => {
    fetchCurrentUser(
      auth(data.login.toString(), data.password.toString())
    ).then((response) => {
      if (response) {
        history.push(RoutesEnum.PATIENT);
        dispatch(resetSessionSymptoms())
        dispatch(modalActions.open('medicalTestAttention'))
      }
      else {
        setAlert('Invalid email or password.')
        setError('login', {})
        setError('password', {})
      };
    });
  };

  return (
    <>
      <FormWrapper>
        <Typography type='h1'>Sign in</Typography>
        <StyledForm
          onSubmit={handleSubmit(data => handleSignIn(data))}
        >
          <ControlledInput
            name='login'
            control={control}
            rules={{
              required: true
            }}
            placeholder='Email'
            error={errors.login}
          />
          <PasswordInput 
            control={control}
            error={errors.password}  
            placeholder='Password'
            useAutocomplete
          />
          <Button isSubmit caption='Sign in' />
          <Link to={RoutesEnum.PASSWORD_RESET}>
            <Typography type='link' color={ColorsEnum.GRAY}>
              Forgot Password?
            </Typography>
          </Link>
        </StyledForm>
      </FormWrapper>
    </>
  );
};

export default SignInForm;
