import React from 'react';
import { Control, Controller, FieldError, FieldValues, RegisterOptions } from 'react-hook-form';
import Input, { IInputBase } from '../Input';

export interface IControlledInput extends IInputBase {
  control: Control<any, object>;
  error?: FieldError;
  rules?: Omit<RegisterOptions<FieldValues, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

const ControlledInput: React.FC<IControlledInput> = ({
  control,
  name,
  rules,
  error,
  placeholder,
  label,
  hint,
  type = 'text',
  showPasswordToggle,
  autocomplete
}) => {
  return(
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={(controllerProps) => 
        <Input
          {...controllerProps.field}
          label={label}
          hint={hint}
          placeholder={placeholder}
          type={type}
          error={error?.message ? error.message : !!error}
          showPasswordToggle={showPasswordToggle}
          autocomplete={autocomplete}
        />
    }
  />
  );
};

export default ControlledInput;
