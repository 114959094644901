import React, { useState,useMemo } from 'react';
import * as UI from './styles';
import ImageModal from '../ImageModal';
import { IDiseasePhoto } from '../../../../models/Disease';
import { useDispatch, useSelector } from 'react-redux';
import { addPhoto, deletePhoto, photosSelector } from '../../../../store/diseasePhotos';
import Checkbox from '../../../../components/Checkbox';
import { Placeholder } from '../../../../components/ImageInput/styles';

interface IDiseaseExamples {
  options?: IDiseasePhoto[];
}

interface IItem {
  id: string;
  photo: string;
}

const DiseaseExamples: React.FC<IDiseaseExamples> = ({
  options
}) => {
  const [item, setItem] = useState<IItem | null>(null);
  const selectedPhotos = useSelector(photosSelector);
  const dispatch = useDispatch();
  
  const handleCheckboxClick = (id: string) => {
    if (selectedPhotos.includes(id)) {
      dispatch(deletePhoto(id));
    } else {
      dispatch(addPhoto(id));
    }
  };

  const handleImageClick = (id: string, photo: string) => {
    setItem({id: id, photo: photo});
  };

  const handleImageVisibility = (visible: boolean) =>
    !visible && setItem(null);

  const checkList = useMemo(() => options?.map(
    (item) => (
      <Checkbox
        checked={selectedPhotos.includes(item.id)}
        onClick={() => handleCheckboxClick(item.id)} 
        type="image"
        key={item.id}
      >
        {item.photo ? 
          <img
            src={item.photo}
            onClick={() => handleImageClick(item.id, item.photo)} 
          /> : 
          <Placeholder />
        }
      </Checkbox>
    )
  ), [options, selectedPhotos]);

  const images = useMemo(() => {
    return options?.map((el) => el.photo)
  }, [options])

  const handleAddPhoto = (i: number) => options && dispatch(addPhoto(options[i].id))
  const handleDeletePhoto = (i: number) => options && dispatch(deletePhoto(options[i].id))

  const currentImageIndex = options?.findIndex(el => el.id === item?.id) || 0

  return (
    <>
      <UI.CheckboxListWrapper>
        {checkList}
      </UI.CheckboxListWrapper>
      {(images?.length && item) && 
        <ImageModal
          title="Is it your disease?"
          secondAction={handleAddPhoto}
          firstAction={handleDeletePhoto}
          handleVisibility={handleImageVisibility}
          images={images}
          buttonTitles={['No', "Yes"]}
          currentImageIndex={currentImageIndex}
        />
      }
    </>
  );
};

export default DiseaseExamples;
