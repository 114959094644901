import React, { useState, useEffect } from 'react';
import Typography from '../../../components/Typography';
import Button from '../../../components/BaseButton';
import * as UI from './styles';
import DropdownContent from '../../../components/DropdownContent';
import { useHistory } from 'react-router';
import Routes from '../../../enums/Routes';
import DiseaseExamples from './DiseaseExamples';
import UserImages from './UserImages';
import { fetchPrimaryDiseases } from '../../../services/disease';
import { IDiseasePhoto, IDiagnosis } from '../../../models/Disease';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionPhotos, photosSelector } from '../../../store/diseasePhotos';
import { uploadSessionImages } from '../../../services/attachment';
import { sessionUserImages } from '../../../store/userImages';
import { getDiseaseImage, uploadDiseasePhotos } from '../../../services/photo';
import { allSymptomsSelector } from '../../../store/symptoms';
import { medicalTestActions } from '../../../store/medicalTest';
import HeaderWithTitle from '../../../components/HeaderWithTitle';

const Corrections: React.FC = () => {
  const [diseaseImages, setDiseaseImages] = useState<IDiseasePhoto[]>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionImages = useSelector(sessionUserImages);
  const sessionPhotos = useSelector(photosSelector);
  const allSymptoms = useSelector(allSymptomsSelector);

  useEffect(() => {
    fetchPrimaryDiseases().then((response: IDiagnosis[]) => {
      dispatch(getSessionPhotos());
      const images = response?.reduce((acc: IDiseasePhoto[], item) => acc.concat(item.diseases.diseasePhotos), []);
      setDiseaseImages(images?.map((item) => ({id: item.id, photo: ""})));
      const promises = images?.map((item) => getDiseaseImage(item.photo).then((response) => ({
        id: item.id, photo: response
      })));
      Promise.all(promises).then((response) => setDiseaseImages(response));
    });
  }, []);

  const handleProceed = () => {
    sessionPhotos.length && uploadDiseasePhotos(sessionPhotos);
    sessionImages.length && uploadSessionImages(sessionImages);
    dispatch(medicalTestActions.resetTests())
    history.push(Routes.TEST);
  };

  return (
    <>
      <HeaderWithTitle
        backRoute={Routes.SYMPTOMS}
        pageTitle='Corrections'
        description='For accurate determination diagnosis do the following steps'
      />
      <UI.CorrectionsWrapper>
        <UI.DropdownsContainer>
          <DropdownContent caption="Photos">
            <UserImages />
          </DropdownContent>
          <DropdownContent caption="Questions">
            <Typography type="p">What do you feel when...?</Typography>
          </DropdownContent>
          <DropdownContent caption="Disease examples">
            <Typography type="p">Look at the photos, define your disease.</Typography>
            <DiseaseExamples options={diseaseImages} />
          </DropdownContent>
          <DropdownContent caption="Recent activity (past 1 hour)" />
        </UI.DropdownsContainer>
        <UI.ButtonWrapper>
          <Button
              onClick={handleProceed}
              buttonType="filled"
              caption="Proceed"
              counter={allSymptoms?.length}
            />
        </UI.ButtonWrapper>
      </UI.CorrectionsWrapper>
    </>
  );
};

export default Corrections;
