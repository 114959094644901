import Routes from "../enums/Routes";

interface IMenuItem {
  title: string;
  route?: Routes;
}

const menuItems: IMenuItem[] = [
  {
    title: 'Find vDoctor by name',
    route: Routes.SEARCH
  },
  {
    title: 'Treatment',
    route: Routes.TREATMENT
  },
  {
    title: 'Daily exam',
  },
  {
    title: 'Emergency',
  },
  {
    title: 'Statistics',
  },
  {
    title: 'Info',
  },
  {
    title: 'Help',
  },
  {
    title: 'Profile',
  }
];

export default menuItems;
