import styled from 'styled-components'
import ColorsEnum from '../../enums/Colors'

export const DragZone = styled.div`
  height: 153px;
  width: 100%;
  border-radius: 8px;
  margin: 0 auto;
  background: ${ColorsEnum.GRAY_3};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #B7BFCE;
  > * + * {
    margin-top: 8px;
  }
  p {
    font-size: 16px;
  }
`
