import { ScreenWidthEnum } from './../../enums/screenWidth';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import ColorsEnum from '../../enums/Colors';

export const Wrapper = styled.div`
  height: fill-available;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${ColorsEnum.WHITE};
  z-index: 200;
`;

export const Document = styled.div`
  height: 70vh;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 2px 8px -2px rgba(0, 26, 51, 0.2), 0px 8px 32px -8px rgba(0, 26, 51, 0.2);

  > p {
    padding: 10px;
    text-align: center;
  }
  ${(props) =>
    props.theme.screen >= ScreenWidthEnum.laptop &&
    css`
      width: 100%;
      height: 95vh;
    `}
  ${(props) =>
    props.theme.screen <= ScreenWidthEnum.tablet &&
    css`
      width: 80vw;
      height: 70vh;
    `}
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  ${(props) =>
    props.theme.screen <= ScreenWidthEnum.tablet &&
    css`
      width: 80vw;
    `}

  button {
    width: 49%;
  }

  p {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }

  ${(props) =>
    props.theme.screen >= ScreenWidthEnum.laptop &&
    css`
      flex-direction: column;
      align-self: flex-end;
      width: 60%;
      margin-bottom: 110px;
      button {
        width: 90%;
      }
      > * + * {
        margin-top: 8px;
      }
    `}
`;
export const Close = styled(CloseIcon)`
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 30px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.theme.screen >= ScreenWidthEnum.laptop &&
    css`
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `}
`;
