import styled, { css } from 'styled-components';
import Button from '../../../components/BaseButton';
import { ScreenWidthEnum } from '../../../enums/screenWidth';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: ${(props) => props.theme.height}px;
`;
export const Content = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;
  width: 100%;

  > div + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.theme.screen <= ScreenWidthEnum.tablet &&
    css`
      max-width: 400px;
    `}
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.laptop &&
    css`
      max-width: 680px;
    `}
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.desktop &&
    css`
      max-width: 824px;
    `}
`;
export const Control = styled.div`
  margin-bottom: 106px;
  ${(props) =>
    props.theme.screen >= ScreenWidthEnum.laptop &&
    css`
      display: flex;
      justify-content: space-between;
      > * + * {
        margin-left: 8px; 
      }
    `}
`;

export const StyledButton = styled(Button)`
  margin-bottom: 8px;
  p,
  label {
    width: 100%;
  }
`;
