import Colors from '../enums/Colors';
import { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import {ReactComponent as HeadMale} from '../assets/icons/body/male/parts/front/head.svg';
import {ReactComponent as NeckMale} from '../assets/icons/body/male/parts/front/neck.svg';
import {ReactComponent as ChestMale} from '../assets/icons/body/male/parts/front/chest.svg';
import {ReactComponent as AbdomenMale} from '../assets/icons/body/male/parts/front/abdomen.svg';
import {ReactComponent as PelvisMale} from '../assets/icons/body/male/parts/front/pelvis.svg';
import {ReactComponent as LegsMale} from '../assets/icons/body/male/parts/front/legs.svg';
import {ReactComponent as HandLeftMale} from '../assets/icons/body/male/parts/front/handLeft.svg';
import {ReactComponent as HandRightMale} from '../assets/icons/body/male/parts/front/handRight.svg';
import {ReactComponent as BackNeckMale} from '../assets/icons/body/male/parts/back/neck.svg';
import {ReactComponent as BackMale} from '../assets/icons/body/male/parts/back/back.svg';
import {ReactComponent as LowerBackMale} from '../assets/icons/body/male/parts/back/lowerBack.svg';
import {ReactComponent as HeadFemale} from '../assets/icons/body/female/parts/front/head.svg';
import {ReactComponent as NeckFemale} from '../assets/icons/body/female/parts/front/neck.svg';
import {ReactComponent as ChestFemale} from '../assets/icons/body/female/parts/front/chest.svg';
import {ReactComponent as AbdomenFemale} from '../assets/icons/body/female/parts/front/abdomen.svg';
import {ReactComponent as PelvisFemale} from '../assets/icons/body/female/parts/front/pelvis.svg';
import {ReactComponent as LegsFemale} from '../assets/icons/body/female/parts/front/legs.svg';
import {ReactComponent as HandLeftFemale} from '../assets/icons/body/female/parts/front/handLeft.svg';
import {ReactComponent as HandRightFemale} from '../assets/icons/body/female/parts/front/handRight.svg';
import {ReactComponent as BackNeckFemale} from '../assets/icons/body/female/parts/back/neck.svg';
import {ReactComponent as BackFemale} from '../assets/icons/body/female/parts/back/back.svg';
import {ReactComponent as LowerBackFemale} from '../assets/icons/body/female/parts/back/lowerBack.svg';
import frontMale from '../assets/icons/body/male/front.svg';
import backMale from '../assets/icons/body/male/back.svg';
import frontFemale from '../assets/icons/body/female/front.svg';
import backFemale from '../assets/icons/body/female/back.svg';
import { VIEWPORT_HEIGHT } from './viewport';

export type Gender = "m" | "f";
export type View = "front" | "back";

export interface IFile {
  fill?: Colors;
  stroke?: Colors;
  onClick?: () => void;
}

export interface IBodyPart {
  id: string;
  title: string;
  gender: Gender;
  view?: View;
  component: React.FC<IFile>;
  styles?: FlattenSimpleInterpolation;
}

interface Dimensions {
  width: number;
  height: number;
}

type ViewDimensions = {
  [view in View]: Dimensions;
};

type ImageDimensions = {
  [gender in Gender]: ViewDimensions;
};

export const partsDimensions: ImageDimensions = {
  f: {
    front: {
      width: 168,
      height: 351,
    },
    back: {
      width: 155,
      height: 340,
    }
  },
  m: {
    front: {
      width: 176,
      height: 352,
    },
    back: {
      width: 163,
      height: 340,
    }
  },
};

export const backImageDimensions: ImageDimensions = {
  f: {
    front: {
      width: 154,
      height: 340,
    },
    back: {
      width: 155,
      height: 340,
    }
  },
  m: {
    front: {
      width: 165,
      height: 338,
    },
    back: {
      width: 163,
      height: 340,
    }
  },
};

type BackgroundImageView = {
  [view in View]: string;
}

type BackgroundImage = {
  [gender in Gender]: BackgroundImageView;
}

export const backImage: BackgroundImage = {
  m: {
    front: frontMale,
    back: backMale
  },
  f: {
    front: frontFemale,
    back: backFemale
  }
};

const male = "m";
const female = "f";
const front = "front";
const back = "back";

const bodyParts: IBodyPart[] = [
  {
    id: '1',
    title: 'Head',
    gender: male,
    view: front,
    component: (props) => <HeadMale {...props} />,
    styles: css`
      height: calc((46 / ${partsDimensions[male][front].height}) * 100%);
      width: calc((46 / ${partsDimensions[male][front].width}) * 100%);
    `,
  },
  {
    id: '2',
    title: 'Throat',
    gender: male,
    view: front,
    component: (props) => <NeckMale {...props} />,
    styles: css`
      height: calc((20 / ${partsDimensions[male][front].height}) * 100%);
      width: calc((52 / ${partsDimensions[male][front].width}) * 100%);
    `,
  },
  {
    id: '3',
    title: 'Thorax',
    gender: male,
    view: front,
    component: (props) => <ChestMale {...props} />,
    styles: css`
      height: calc((53 / ${partsDimensions[male][front].height}) * 100%);
      width: calc((70 / ${partsDimensions[male][front].width}) * 100%);
    `,
  },
  {
    id: '4',
    title: 'Abdomen',
    gender: male,
    view: front,
    component: (props) => <AbdomenMale {...props} />,
    styles: css`
      height: calc((38 / ${partsDimensions[male][front].height}) * 100%);
      width: calc((78 / ${partsDimensions[male][front].width}) * 100%);
    `,
  },
  {
    id: '5',
    title: 'Inguinal region',
    gender: male,
    view: front,
    component: (props) => <PelvisMale {...props} />,
    styles: css`
      height: calc((29 / ${partsDimensions[male][front].height}) * 100%);
      width: calc((80 / ${partsDimensions[male][front].width}) * 100%);
    `,
  },
  {
    id: '6',
    title: 'Lower limb',
    gender: male,
    view: front,
    component: (props) => <LegsMale {...props} />,
    styles: css`
      height: calc((159 / ${partsDimensions[male][front].height}) * 100%);
      width: calc((78 / ${partsDimensions[male][front].width}) * 100%);
    `,
  },
  {
    id: '7',
    title: 'Upper limb',
    gender: male,
    view: front,
    component: (props) => (
      <>
        <HandRightMale {...props} />
        <HandLeftMale {...props} />
      </>
    ),
    styles: css`
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      position: absolute;
      top: calc((59 / ${partsDimensions[male][front].height}) * 100%);
      height: calc((147 / ${partsDimensions[male][front].height}) * 100%);
      width: 100%;
      svg {
        width: calc((61 / ${partsDimensions[male][front].width}) * 100%);
      }
    `,
  },
  {
    id: '8',
    title: 'Neck area',
    gender: male,
    view: back,
    component: (props) => <BackNeckMale {...props} />,
    styles: css`
      height: calc((19 / ${partsDimensions[male][back].height}) * 100%);
      width: calc((52 / ${partsDimensions[male][back].width}) * 100%);
      margin-top: calc((43 / ${VIEWPORT_HEIGHT}) * 100vh);
    `,
  },
  {
    id: '9',
    title: 'Dorsum',
    gender: male,
    view: back,
    component: (props) => <BackMale {...props} />,
    styles: css`
      height: calc((91 / ${partsDimensions[male][back].height}) * 100%);
      width: calc((73 / ${partsDimensions[male][back].width}) * 100%);
    `,
  },
  {
    id: '10',
    title: 'Pelvis',
    gender: male,
    view: back,
    component: (props) => <LowerBackMale {...props} />,
    styles: css`
      height: calc((28 / ${partsDimensions[male][back].height}) * 100%);
      width: calc((79 / ${partsDimensions[male][back].width}) * 100%);
      margin-bottom: calc((154 / ${VIEWPORT_HEIGHT}) * 100vh);
    `,
  },
  {
    id: '1',
    title: 'Head',
    gender: female,
    view: front,
    component: (props) => <HeadFemale {...props} />,
    styles: css`
      height: calc((46 / ${partsDimensions[female][front].height}) * 100%);
      width: calc((46 / ${partsDimensions[female][front].width}) * 100%);
    `,
  },
  {
    id: '2',
    title: 'Throat',
    gender: female,
    view: front,
    component: (props) => <NeckFemale {...props} />,
    styles: css`
      height: calc((19 / ${partsDimensions[female][front].height}) * 100%);
      width: calc((51 / ${partsDimensions[female][front].width}) * 100%);
    `,
  },
  {
    id: '3',
    title: 'Thorax',
    gender: female,
    view: front,
    component: (props) => <ChestFemale {...props} />,
    styles: css`
      height:  calc((52 / ${partsDimensions[female][front].height}) * 100%);
      width: calc((62 / ${partsDimensions[female][front].width}) * 100%);
    `,
  },
  {
    id: '4',
    title: 'Abdomen',
    gender: female,
    view: front,
    component: (props) => <AbdomenFemale {...props} />,
    styles: css`
      height:  calc((30 / ${partsDimensions[female][front].height}) * 100%);
      width: calc((68 / ${partsDimensions[female][front].width}) * 100%);
    `,
  },
  {
    id: '5',
    title: 'Inguinal region',
    gender: female,
    view: front,
    component: (props) => <PelvisFemale {...props} />,
    styles: css`
      height:  calc((25 / ${partsDimensions[female][front].height}) * 100%);
      width: calc((77 / ${partsDimensions[female][front].width}) * 100%);
    `,
  },
  {
    id: '6',
    title: 'Lower limb',
    gender: female,
    view: 'front',
    component: (props) => <LegsFemale {...props} />,
    styles: css`
      height:  calc((165 / ${partsDimensions[female][front].height}) * 100%);
      width: calc((71 / ${partsDimensions[female][front].width}) * 100%);
    `,
  },
  {
    id: '7',
    title: 'Upper limb',
    gender: female,
    view: front,
    component: (props) => (
      <>
        <HandRightFemale {...props} />
        <HandLeftFemale {...props} />
      </>
    ),
    styles: css`
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      position: absolute;
      top: calc((62.5 / ${partsDimensions[female][front].height}) * 100%);
      height: calc((135 / ${partsDimensions[female][front].height}) * 100%);
      width: 100%;
      svg {
        width: calc((56 / ${partsDimensions[female][front].width}) * 100%);
      }
    `,
  },
  {
    id: '8',
    title: 'Neck area',
    gender: female,
    view: back,
    component: (props) => <BackNeckFemale {...props} />,
    styles: css`
      height: calc((19 / ${partsDimensions[female][back].height}) * 100%);
      width: calc((51 / ${partsDimensions[female][back].width}) * 100%);
      margin-top: calc((43 / ${VIEWPORT_HEIGHT}) * 100vh);
    `,
  },
  {
    id: '9',
    title: 'Dorsum',
    gender: female,
    view: back,
    component: (props) => <BackFemale {...props} />,
    styles: css`
      height: calc((83 / ${partsDimensions[female][back].height}) * 100%);
      width: calc((68 / ${partsDimensions[female][back].width}) * 100%);
    `,
  },
  {
    id: '10',
    title: 'Pelvis',
    gender: female,
    view: back,
    component: (props) => <LowerBackFemale {...props} />,
    styles: css`
      height: calc((30 / ${partsDimensions[female][back].height}) * 100%);
      width: calc((77 / ${partsDimensions[female][back].width}) * 100%);
      margin-bottom: calc((158 / ${VIEWPORT_HEIGHT}) * 100vh);
    `,
  },
];

export default bodyParts;
