import { createAction, createAsyncThunk, createReducer, createSelector } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { IReduxState } from '..';
import { INotification } from '../../models/Notifications';

const initialState = {
  notifications: [] as INotification[],
};

export const notificationActions = {
  remove: createAction<string>('NOTIFICATION/REMOVE'),
  clearTimeout: createAction<string>('NOTIFICATION/CLEAR_TIMEOUT'),
  get add() {
    return createAsyncThunk<INotification, Omit<INotification, 'id' | 'timer'>>(
      'NOTIFICATION/ADD',
      async (action, store) => {
        const id = v4();
        const timer = window.setTimeout(() => {
          store.dispatch(this.remove(id));
        }, 2000);
        return {
          ...action,
          id,
          timer,
        };
      },
    );
  },
};

const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(notificationActions.add.fulfilled, (state, action) => {
      state.notifications.push(action.payload);
    })
    .addCase(notificationActions.remove, (state, action) => {
      state.notifications = state.notifications.filter((el) => el.id !== action.payload);
    })
    .addCase(notificationActions.clearTimeout, (state, action) => {
      const timer = state.notifications.find((el) => el.id !== action.payload)?.timer
      window.clearTimeout(timer)
    });
});

const defaultSelector = (state: IReduxState) => state.notificationReducer;

export const notificationSelectors = {
  list: createSelector(defaultSelector, (state) => state.notifications),
};

export default notificationReducer;
