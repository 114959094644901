import React, { useState } from 'react';
import { v4 } from 'uuid';
import Reader from '../../../../../components/Reader';
import Typography from '../../../../../components/Typography';
import { getFormattedDate } from '../../../../../helpers/date';
import ResultDropdown, { IDropdown } from '../ResultDropdown';
import * as UI from './styles';

export interface IMedicalTest {
  title: string;
  // TODO: Заменить на тип файлов, когда они будут приходить 
  files: string[]
}

export interface IResult {
  date: Date;
  testsList: IMedicalTest[];
}


const ResultFrame: React.FC<IResult> = ({ date, testsList }) => {
  const [showDocument, setShowDocument] = useState('')

  const handleClick = (file: string) => {
    setShowDocument(file)
  }
  const handleClose = () => setShowDocument('')

  const tests = testsList.map((el) => {
    const buttons: IDropdown[] = el.files.map(file => ({
      title: file,
      action: () => handleClick(file)
    }))
    return <ResultDropdown key={v4()} dropdownButtons={buttons} title={el.title} />;
  });

  return (
    <UI.Wrapper>
      <Typography type="h2">{getFormattedDate(date)}</Typography>
      <UI.Tests>
        {tests}
      </UI.Tests>
      {showDocument && <Reader onClose={handleClose}/>}
    </UI.Wrapper>
  );
};

export default ResultFrame;
