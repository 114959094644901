import React, { useEffect } from 'react';
import * as UI from './styles';
import { ModalWrapper, ModalBody, ModalHeader } from '../../../../components/Modal';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/BaseButton';
import CloseButton from '../../../../components/CloseButton';

interface IComplaintModal {
  visible: boolean;
  handleVisibility: (visible: boolean) => void;
  handleGenderSwitch: () => void;
}

const SwitchGenderModal: React.FC<IComplaintModal> = ({
  visible,
  handleVisibility,
  handleGenderSwitch
}) => {
  const { ref, isComponentVisible, setComponentVisible, handleChangeComponentVisibility } = useComponentVisible(
    visible
  );

  useEffect(() => {
    if (visible) setComponentVisible(visible);
  }, [visible]);

  useEffect(() => {
    handleVisibility(isComponentVisible);
  }, [isComponentVisible]);

  const handleYesClick = () => {
    handleGenderSwitch();
    handleChangeComponentVisibility();
  };

  return (
    <ModalWrapper>
      <ModalBody ref={ref}>
        <ModalHeader>
          <Typography type='h2' lineHeight={14}>
            Change gender?
          </Typography>
          <CloseButton onClick={handleChangeComponentVisibility} />
        </ModalHeader>
        <Typography type='p'>
          This action will result in the loss of the previously entered symptoms.<br /><br />Are you sure?
        </Typography>
        <UI.ButtonsWrapper>
          <Button buttonType='transparent' caption='No' onClick={handleChangeComponentVisibility} />
          <Button buttonType='filled' caption='Yes' onClick={handleYesClick} />
        </UI.ButtonsWrapper>
      </ModalBody>
    </ModalWrapper>
  );
};

export default SwitchGenderModal;
