import React from 'react'
import { useSelector } from 'react-redux';
import ColorsEnum from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import { screenSelectors } from '../../store/screen';
import HeaderWithButton, { IHeaderWithButton } from '../HeaderWithButton';
import Typography from '../Typography';
import * as UI from './styles'

interface IProps extends IHeaderWithButton {
  description?: string;
  greenTitle?: string;
  className?: string
}

const HeaderWithTitle: React.FC<IProps> = ({ description, className, greenTitle, pageTitle, ...props }) => {
  const screen = useSelector(screenSelectors.width);

  const isPhone = screen <= ScreenWidthEnum.landscapePhone

  return (
    <div className={className}>
      <HeaderWithButton
        pageTitle={!isPhone ? pageTitle : ''}
        isBackButton
        greenTitle={!isPhone ? greenTitle : ''}
        {...props}
      />
      {screen === ScreenWidthEnum.phone && (
        <UI.Header type="h1">
          {pageTitle}
          {greenTitle && (
            <Typography type="h1" color={ColorsEnum.GREEN}>
              {greenTitle}
            </Typography>
          )}
        </UI.Header>
      )}
      {!!description && <UI.Description type="p">{description}</UI.Description>}
    </div>
  );
};

export default HeaderWithTitle;
