import { ModalBody } from '../../../../components/Modal/index';
import styled, { css } from 'styled-components/macro';
import ColorsEnum from '../../../../enums/Colors';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;

  & > :first-child {
    margin-right: 8px;
  }
`;

export const StyledImage = styled.img`
  border-radius: 8px;
  max-height: 1000px;
  max-width: 100%;
  align-self: center;
  object-fit: cover;
  
  filter: drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.08));
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IImageButtonProps {
  active?: boolean
}

export const ImageButton = styled.div<IImageButtonProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${ColorsEnum.GRAY_2};
  cursor: pointer;
  ${props => props.active && css`
    background: ${ColorsEnum.BLUE};
  `}
`
export const ImageControlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 12px;
  > * + * {
    margin-left: 8px;
  }
`

export const MultiImageBody = styled(ModalBody)`
  width: fit-content;
`;
