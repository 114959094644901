import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Colors from '../../enums/Colors';

export const ImageLinkWrapper = styled(Link)`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 153px;
  border-radius: 12px;
  background-color: ${Colors.WHITE};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
  text-decoration: none;
`;

export const CaptionWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 44px;
  padding: 0px 20px 0px 24px;
  align-items: center;
  justify-content: space-between;
`;

interface IImageWrapper {
  background?: Colors;
}

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background-color: ${(props: IImageWrapper) => props.background || Colors.WHITE};
  border-radius: 12px 12px 0px 0px;

  img {
    height: 100%;
    mix-blend-mode: multiply;
  }
`;
