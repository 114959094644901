import React, { useEffect, useMemo, useState } from 'react';
import * as UI from './styles';
import { addSymptom, deleteSymptom, getSessionSymptoms, noSymptomsSelector, symptomsSelector } from '../../../../store/symptoms';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '../../../../components/Checkbox';
import Typography from '../../../../components/Typography';
import MenuItem from '../../../../components/MenuItem';
import { v4 } from 'uuid';
import ColorsEnum from '../../../../enums/Colors';
import { IOrgan } from '../../../../models/Organ';
import { alphabetSort } from '../../../../helpers/sort';
import { searchOptions } from '../../../../helpers/search';
import { saveSessionSymptoms } from '../../../../services/session';
import { fetchSpecializationSymptoms } from '../../../../services/organs';
import { IOrganSymptoms } from '../../../../models/Symptom';

interface ISymptomsList {
  organSymptoms: IOrganSymptoms[];
  isSpecialization?: boolean;
  specializationId?: string;
  isModal?: boolean;
}

const SymptomsList: React.FC<ISymptomsList> = ({
  isSpecialization,
  organSymptoms,
  isModal,
  specializationId,
}) => {
  const [allSymptoms, setAllSymptoms] = useState<IOrganSymptoms[]>([]);
  const [symptoms, setSymptoms] = useState<IOrganSymptoms[]>([]);
  const [search, setSearch] = useState('');
  const type = isSpecialization ? 'text' : 'gray';
  const selectedSymptoms = useSelector(symptomsSelector);
  const noSymptoms = useSelector(noSymptomsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setSearch('')
      if (specializationId) {
        const promises = await Promise.all(organSymptoms.map(el => fetchSpecializationSymptoms(specializationId, el.id)))
        setAllSymptoms(organSymptoms.map((el, index) => ({
          ...el,
          symptoms: promises[index]
        })))
      }
      else {
        setAllSymptoms(organSymptoms)
      }
    })()
  }, [organSymptoms])

  useEffect(() => {
    if (noSymptoms && selectedSymptoms?.length) {
      saveSessionSymptoms(selectedSymptoms).then(() => {
        dispatch(getSessionSymptoms());
      });
    }
  }, [noSymptoms, selectedSymptoms])

  useEffect(() => {
    if (!!search) setSymptoms(allSymptoms.map((organ) => ({...organ, symptoms: searchOptions(search?.toLowerCase(), organ.symptoms)})));
    else setSymptoms(allSymptoms);
  }, [search, allSymptoms]);

  const isChecked = (symptomId: string, organId: string) => {
    return !!selectedSymptoms?.find((item) => item.organId === organId)?.symptoms.includes(symptomId);
  };

  const symptomClick = (symptomId: string, organ: IOrgan) => {
    if (isChecked(symptomId, organ.id))
      dispatch(deleteSymptom(symptomId, organ));
    else dispatch(addSymptom(symptomId, organ));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const list = useMemo(() => {
    if (isSpecialization) {
      return alphabetSort(symptoms)?.map((organ) => 
        alphabetSort(organ.symptoms)?.map((symptom) => {
          const {symptoms, ...symptomOrgan} = organ;
          const checked = isChecked(symptom.id, symptomOrgan.id)
          return (
            <Checkbox 
              checked={checked}
              onClick={() => symptomClick(symptom.id, symptomOrgan)} 
              type='text'
              key={v4()}
            >
              <Typography type='p' lineHeight={14} color={checked ? ColorsEnum.CHECKBOX_ACTIVE : ColorsEnum.BLACK}>
                {symptom.title + '\n' + organ.title}
              </Typography>
            </Checkbox> 
          );
        }
      ));
    }

    return symptoms?.map((organ) => {
      const {symptoms, ...symptomOrgan} = organ;
      return alphabetSort(organ.symptoms)?.map((symptom) =>
        <MenuItem
          type='list'
          caption={symptom.title}
          selected={isChecked(symptom.id, symptomOrgan.id)}
          onClick={() => symptomClick(symptom.id, symptomOrgan)}
          key={v4()}
          noBoxShadow
        />
      )}
    );
  }, [isSpecialization, selectedSymptoms, symptoms]);

  return (
    <UI.SymptomsListWrapper type={type}>
      <UI.StyledSearchInput 
        value={search}
        onChange={handleInputChange}
        placeholder='What do you feel?'
      />
      <UI.CheckboxListWrapper type={type} useModalLayout={isModal}>
        {list}
      </UI.CheckboxListWrapper>
    </UI.SymptomsListWrapper>
  );
};

export default SymptomsList;
