enum RoutesEnum {
  PROFILE='/profile',
  DAILY_EXAM = '/daily',
  EMERGENCY = '/emergency',
  PATIENT = '/patient',
  INFO = '/info',
  HELP = '/help',
  SEARCH = '/search',
  SYMPTOMS = '/symptoms',
  CORRECTIONS = '/corrections',
  TEST = '/test',
  TREATMENT = '/treatment',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  PASSWORD_RESET = '/password-reset',
  NEW_PASSWORD = '/new-password',
  DEFAULT = '/'
};

export default RoutesEnum;
