import styled, { css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';

export const CaptionWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin: 16px 0;
  justify-content: space-between;
  align-items: center;
  
  &::before {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: ${Colors.LIGHT_GRAY};
    margin-right: 8px;
  }

  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: ${Colors.LIGHT_GRAY};
    margin-left: 8px;
  }

  p {
    margin: 0 8px;
    text-align: center;
  }

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    margin: 20px 0;
  `}
`;
