import React, { useState, useEffect } from 'react';
import * as UI from './styles';
import Search from '../../../../components/Search';
import { findOption, searchOptions } from '../../../../helpers/search';
import { fetchSpecializations } from '../../../../services/specialization';
import { useHistory } from 'react-router';
import Routes from '../../../../enums/Routes';
import { sendSessionSpecialization } from '../../../../services/session';
import IBaseItem from '../../../../models/BaseItem';

const FindByName: React.FC = () => {
  const [options, setOptions] = useState<IBaseItem[]>([]);
  const [results, setResults] = useState<IBaseItem[]>([]); 
  const [search, setSearch] = useState<string | undefined>('');
  const history = useHistory();

  const onSearch = (search?: string) => {
    setSearch(search);
  };

  useEffect(() => {
    fetchSpecializations().then((response) => setOptions(response));
  }, []);

  useEffect(() => {
    setResults(searchOptions<IBaseItem>(search?.toLowerCase(), options));
  }, [options, search]);

  const handleFind = () => {
    const result = findOption(search?.toLowerCase(), options);
    if (result) handleOptionClick(result.id);
    else setResults([]);
  };

  const handleOptionClick = (id: string) => {
    sendSessionSpecialization(id).then(() => {
      history.push(Routes.SYMPTOMS);
    });
  };

  return (
    <UI.Wrapper>
      <Search
        options={results}
        onSearch={onSearch}
        onClick={handleFind}
        onOptionClick={handleOptionClick}
      />
    </UI.Wrapper>
  );
};

export default FindByName;
