import styled, { css } from 'styled-components';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import Typography from '../Typography';

export const Header = styled(Typography)`
  margin-bottom: 8px;
  p {
    display: inline;
  }
`;

export const Description = styled(Typography)`
  margin-bottom: 16px;
  ${(props) =>
    props.theme.screen >= ScreenWidthEnum.landscapePhone &&
    css`
      text-align: center;
    `}
`;
