import React, { useEffect } from 'react';
import * as UI from './styles';
import { ModalWrapper, ModalHeader, ModalBody } from '../../../../components/Modal';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/BaseButton';
import { IOrganSymptoms } from '../../../../models/Symptom';
import { allSymptomsSelector, symptomsSelector } from '../../../../store/symptoms';
import { useSelector } from 'react-redux';
import CloseButton from '../../../../components/CloseButton';
import SymptomsList from '../SymptomsList';

interface IComplaintsModal {
  title: string;
  visible: boolean;
  handleVisibility: (visible: boolean) => void;
  options: IOrganSymptoms[];
  handleProceed: () => void;
  specializationId?: string;
}

const ComplaintsModal: React.FC<IComplaintsModal> = ({
  visible,
  handleVisibility,
  options,
  handleProceed,
  title,
  specializationId
}) => {
  const { ref, isComponentVisible, setComponentVisible, handleChangeComponentVisibility } = useComponentVisible(
    visible
  );
  const selectedSymptoms = useSelector(symptomsSelector);
  const allSymptoms = useSelector(allSymptomsSelector);
  const currentOrganSymptoms = options?.length && selectedSymptoms?.find(el => options && el.organId === options[0].id);

  useEffect(() => {
    if (visible) setComponentVisible(visible);
  }, [visible]);

  useEffect(() => {
    handleVisibility(isComponentVisible);
  }, [isComponentVisible]);

  if (!visible) return null

  return (
    <ModalWrapper>
      <UI.StyledModalBody ref={ref}>
        <ModalHeader>
          <Typography type='h2'>{title}</Typography>
          <CloseButton onClick={handleChangeComponentVisibility} />
        </ModalHeader>
        <UI.ListWrapper>
          <SymptomsList
            organSymptoms={options}
            specializationId={specializationId}
            isModal
          />
        </UI.ListWrapper>
        <UI.ButtonsWrapper>
          <Button
            buttonType='back'
            caption='Add symptoms'
            onClick={handleChangeComponentVisibility}
            counter={currentOrganSymptoms && currentOrganSymptoms?.symptoms.length}
          />
          <Button
            buttonType='filled'
            caption='Proceed'
            disabled={!selectedSymptoms?.length}
            onClick={handleProceed}
            counter={allSymptoms?.length}
          />
        </UI.ButtonsWrapper>
      </UI.StyledModalBody>
    </ModalWrapper>
  );
};

export default ComplaintsModal;
