import { ScreenWidthEnum } from '../../../../enums/screenWidth';
import styled, { css } from "styled-components"
import Typography from "../../../../components/Typography"
import { IProps } from '../styles';

interface IWrapperProps extends IProps {
  useSmallFont?: boolean;
}

export const Header = styled(Typography)`
  display: inline-block;
  word-break: break-all;
`;

export const Description = styled(Typography)<IWrapperProps>`
  width: 60%;
  margin-top: 20px;
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    margin-top: 28px;
  `}

  ${props => props.theme.screen <= ScreenWidthEnum.landscapePhone && css`
    margin-top: 8px;
  `}


  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    width: ${props.isSpecializationView ? '100%' : '55%'};
  `}
`;

export const SymptomsHeaderWrapper = styled.div<IWrapperProps>`
  ${props => props.theme.screen > ScreenWidthEnum.landscapePhone && css`
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    p {
      width: unset;
      white-space: nowrap;
    }
  `}

  ${(props) => [ScreenWidthEnum.landscapePhone].some(el => el === props.theme.screen) && css`
    pointer-events: none;
    display: flex;
    flex-direction: column;
  `}

  ${(props) => props.useSmallFont && props.theme.screen === ScreenWidthEnum.phone && css`
    ${Header} {
      font-size: 20px;
      line-height: 24px;
    }
  `}
`;

export const SymptomsHeaderWrapperLandScape = styled(SymptomsHeaderWrapper)<IWrapperProps>`
  flex-direction: column;
  display: flex;
  word-wrap: break-word;
  overflow-wrap: break-word;

  > p:nth-child(2) {
    margin-top: 0;
    max-width: 80%;
  }

  ${(props) => props.useSmallFont && css`
    ${Header} {
      font-size: 20px;
      line-height: 24px;
    }
  `}
`;
