import React from 'react';
import * as UI from './index';

const ModalWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <UI.StyledModalWrapper {...props}>
      <UI.ModalStyles />
      {children}
    </UI.StyledModalWrapper>
  );
};

export default ModalWrapper;
