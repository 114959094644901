import React from 'react';
import { Control, FieldError } from 'react-hook-form';
import ControlledInput from '../ControlledInput';

export interface IPasswordInput {
  control: Control<any, object>;
  error?: FieldError;
  name?: string;
  label?: string;
  placeholder?: string;
  showPasswordToggle?: boolean;
  useLengthRules?: boolean;
  showRequiredMessage?: boolean;
  useAutocomplete?: boolean;
}

const PasswordInput: React.FC<IPasswordInput> = ({
  control,
  error,
  name,
  label,
  placeholder,
  showPasswordToggle,
  useLengthRules,
  showRequiredMessage,
  useAutocomplete
}) => {
  return(
    <ControlledInput
      name={name || 'password'}
      control={control}
      rules={{
        required: showRequiredMessage ? 'Required field' : true,
        minLength: useLengthRules ? {
          value: 8,
          message: 'Password must contain at least 8 characters'
        } : undefined
      }}
      label={label}
      placeholder={placeholder}
      hint={useLengthRules ? 'Password must contain at least 8 characters' : undefined}
      type='password'
      showPasswordToggle={showPasswordToggle}
      error={error}
      autocomplete={useAutocomplete ? 'on' : 'new-password'}
    />
  );
};

export default PasswordInput;
