export const getFormattedDate = (date: Date) => {
  const locale = 'en-US';
  const separator = ', ';

  const day = date.toLocaleString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const weekday = date.toLocaleString(locale, {
    weekday: 'long',
  });

  const time = date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
  });

  return day + separator + weekday + separator + time;
};
