import styled, { keyframes } from 'styled-components';
import Typography from '../../../../components/Typography';
import ColorsEnum from '../../../../enums/Colors';
import { ReactComponent as LoadingIcon } from '../../../../assets/icons/loading.svg';
import * as RootUI from '../styles'

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  padding: 10px 0;
  > svg {
    margin-left: 6px;
    margin-right: 12px;
    min-width: 22px;
  }
`;

export const FileDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const FileName = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${ColorsEnum.BLACK};
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(LoadingIcon)`
  animation: ${rotate} linear 1s infinite;
`;

export const Label = styled(Typography)`
  white-space: nowrap;
`;

export const FileListWrapper = styled.div`
  height: 75%;
  overflow: auto;
  > * + * {
    margin-top: 8px;
  }
`;
export const Content = styled(RootUI.Content)`
  overflow: auto;
`;