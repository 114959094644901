import styled from 'styled-components/macro';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;

  & > :first-child {
    margin-right: 8px;
  }
`;
