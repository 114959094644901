import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  width: 100%;
  align-self: center;

  & > :first-child {
    margin-bottom: 8px;
  }
`;
