import React from 'react';
import Typography from '../../../../components/Typography';
import { getFormattedDate } from '../../../../helpers/date';
import { IDisease } from '../../../../models/Disease';
import DiseaseDropdown from '../DiseaseDropdown';
import { DropdownsWrapper } from '../styles';

interface IDiagnosis {
  date: Date;
  diseases: IDisease[];
}

const Diagnosis: React.FC<IDiagnosis> = ({
  date,
  diseases
}) => {
  return (
    <div>
      <Typography type='h2'>
        {getFormattedDate(date)}
      </Typography>
      <DropdownsWrapper>
        {diseases?.map((disease) =>
          <DiseaseDropdown
            disease={disease}
            key={disease.id}  
          />)
        }
      </DropdownsWrapper>
    </div>
  )
};

export default Diagnosis;
