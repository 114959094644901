import styled, { css } from 'styled-components/macro';
import Colors from '../../../enums/Colors';
import { MenuItemWrapper } from '../../MenuItem/styles';
import { MenuBody } from '..';

interface IMenu {
  show?: boolean;
}

export const MobileMenuBody = styled(MenuBody)`
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;
  min-width: 240px;
  background: ${Colors.WHITE};
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-flow: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  height: ${(props) => props.theme.height}px;
  background-color: ${Colors.TRANSPARENT_BLUE};
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;

  ${(props: IMenu) => props.show && css`
    opacity: 1;
    visibility: visible;

    ${MobileMenuBody} {
      opacity: 1;
      transform: translateX(0);
    }
  `}
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 8px;

  img {
    align-self: baseline;
    cursor: pointer;
  }
`;

export const StyledMenuItem = styled(MenuItemWrapper)`
  :hover {
    background: ${Colors.OFF_WHITE};
  }

  :active {
    background: ${Colors.OFF_WHITE};
    color: ${Colors.BLUE}
  }
`;

export const MenuBars = styled.img`
  position: absolute;
  top: 28px;
  right: 28px;
  z-index: 100;
`;
