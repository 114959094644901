import React from 'react';
import Button from '../../../components/BaseButton';
import SignUpForm from './SignUpForm';
import { useHistory } from 'react-router';
import RoutesEnum from '../../../enums/Routes';
import QuestionCaption from '../../../components/QuestionCaption';

const SignUp: React.FC = () => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push(RoutesEnum.SIGN_IN);
  };

  return (
    <>
      <SignUpForm />
      <QuestionCaption caption='Already a member?' />
      <Button
        buttonType="transparent"
        caption="Sign in"
        onClick={handleSignInClick}
      />
    </>
  );
};

export default SignUp;
