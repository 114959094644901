import styled, { keyframes, css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import plus from '../../assets/icons/dropdown/plus.svg';
import { ScreenWidthEnum } from '../../enums/screenWidth';

export const Input = styled.input`
  display: none;
`;

export const CustomInput = styled.label`
  display: block;
  width: 76px;
  height: 76px;
  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    width: 95px;
    height: 95px;
  `}
  ${props => props.theme.screen === ScreenWidthEnum.tablet && css`
    width: 88px;
    height: 88px;
  `}
  ${props => props.theme.screen === ScreenWidthEnum.laptop && css`
    width: 164px;
    height: 164px;
  `}
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    width: 200px;
    height: 200px;
  `}
  border-radius: 8px;
  border: 2px solid ${Colors.DARK_GRAY};
  background: url(${plus}) no-repeat, #D2D6DF;
  background-position: center center;
`;

export const ImageWrapper = styled.div`
  width: 76px;
  height: 76px;
  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    width: 95px;
    height: 95px;
  `}
  ${props => props.theme.screen === ScreenWidthEnum.tablet && css`
    width: 88px;
    height: 88px;
  `}
  ${props => props.theme.screen === ScreenWidthEnum.laptop && css`
    width: 164px;
    height: 164px;
  `}
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    width: 200px;
    height: 200px;
  `}
  border-radius: 8px;
  border: 1px solid ${Colors.DARK_GRAY};
  position: relative;
  background-color: ${Colors.DARK_GRAY};

  & > :first-child {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  & > :last-child {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const ImageInputWrapper = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
`;

const loadingAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(
      90deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.2) 50%,
      rgba(255,255,255,0) 100%
    );
    animation: ${loadingAnimation} 2s infinite;
  }
`;
