import { AxiosRequestConfig } from "axios";
import { getRequest } from "../../api"
import { auth } from "../../helpers/auth";

export const fetchCurrentUser = (config?: AxiosRequestConfig) => {
  return getRequest("/user/current", config);
};

export const signOut = () => {
  return getRequest("/user/current", auth("", ""));
};
