import React from 'react';
import { useHistory } from 'react-router';
import Button from '../../../../components/BaseButton';
import Typography from '../../../../components/Typography';
import RoutesEnum from '../../../../enums/Routes';
import * as UI from './styles';

interface IEmailSent {
  email: string;
}

const EmailSent: React.FC<IEmailSent> = ({
  email
}) => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push(RoutesEnum.SIGN_IN);
  };

  return (
    <UI.TextWrapper>
      <Typography type='h1'>Email sent</Typography>
      <Typography type='p'>
        Email has been sent to your verified email address. Please follow the link in the email for setup a new password.
      </Typography>
      <Typography type='h2'>{email}</Typography>
      <Typography type='p'>
        The link is valid for 24 hours.
      </Typography>
      <Button
        caption='Sign in'
        onClick={handleSignInClick}
      />
    </UI.TextWrapper>
  );
};

export default EmailSent;
