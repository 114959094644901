import React, { useState, useEffect } from 'react';
import Button from '../../../components/BaseButton';
import * as UI from './styles';
import EndSessionModal from './EndSessionModal';
import phone from '../../../assets/icons/phone.svg';
import { IDiagnosis, IGroupedDiagnosis } from '../../../models/Disease';
import { fetchBaseDiseases } from '../../../services/disease';
import { reportUrl } from '../../../services/report';
import { useHistory, useLocation } from 'react-router';
import Routes from '../../../enums/Routes';
import { getPreviousDay, saveDiseases } from '../../../services/usage';
import HeaderWithTitle from '../../../components/HeaderWithTitle';
import Diagnosis from './Diagnosis';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../store/modal';
import { symptomsSentSelector } from '../../../store/symptoms';

const Treatment: React.FC = () => {
  const symptomsSent = useSelector(symptomsSentSelector)
  const [endSessionModal, setEndSessionModal] = useState(false);
  const [currentDiagnosis, setCurrentDiagnosis] = useState<IDiagnosis[]>([]);
  const [diagnosesHistory, setDiagnosesHistory] = useState<IGroupedDiagnosis[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [dateOffset, setDateOffset] = useState(0);
  const [isHistoryEnd, setIsHistoryEnd] = useState(false);
  const history = useHistory();
  const location = useLocation<{fromMenu?: boolean}>()
  const dispatch = useDispatch()
  const currentDate = new Date();

  useEffect(() => {
    (async () => {
      const diseases = await fetchBaseDiseases();
      setCurrentDiagnosis(diseases);

      const response = await getPreviousDay(0)
      const diagnoses = parseDiagnosis(response.historyUsages)
      setDiagnosesHistory(diagnoses);
      response.endHistory && setIsHistoryEnd(true);
    })();
  }, []);

  const handlePhoneCall = () => {
    window.open('tel:911', '_self');
  };

  const handleEndSessionButton = () => setEndSessionModal(true);

  const handleEndSessionVisibility = (visible: boolean) => setEndSessionModal(visible);

  const handleModalClick = (report?: boolean) => {
    if (currentDiagnosis && currentDiagnosis.length)
      saveDiseases(currentDiagnosis).then(() => {
        if (report) window.location.href = reportUrl();
        history.push(Routes.PATIENT);
      });
    else history.push(Routes.PATIENT);
  };

  const parseDiagnosis = (disgnosis: IDiagnosis[]) => {
    return disgnosis.reduce<IGroupedDiagnosis[]>((acc, el) => {
      const lastElement = acc[acc.length - 1]
      if (lastElement) {
        if (lastElement.sessionId === el.sessionId) {
          lastElement.diseases.push(el.diseases)
          return acc
        }
      }
      acc.push({
        ...el,
        diseases: [el.diseases]
      })

      return acc
    }, [])
  }

  const handlePreviousDayClick = async () => {
    setShowHistory(true)
    if (!showHistory) return setDateOffset((offset) => offset + 1);
    const response = await getPreviousDay(dateOffset)
    const diagnoses = parseDiagnosis(response.historyUsages)

    setIsHistoryEnd(response.endHistory);
    setDiagnosesHistory((diagnosesHistory) => [...diagnosesHistory, ...diagnoses]);
    setDateOffset((offset) => offset + 1);
  };

  const handleFindHospital = () => {
    dispatch(modalActions.open('featureInDev'))
  }

  const handleBack = () => {
    if (history.action !== 'POP' && location.state?.fromMenu) history.goBack()
    else if (symptomsSent) history.push(Routes.TEST)
    else history.push(Routes.PATIENT)
  }
  
  return (
    <>
      <HeaderWithTitle pageTitle="Treatment" onButtonClick={handleBack} />
      <UI.DiagnosesContainer>
        <Diagnosis
          date={currentDate}
          diseases={currentDiagnosis.map((disease) => disease.diseases)}
        />
        {!!(diagnosesHistory.length && showHistory) &&
          diagnosesHistory.map((diagnosis) => (
            <Diagnosis
              date={new Date(diagnosis.added)}
              diseases={diagnosis.diseases}
              key={diagnosis.id}
            />
          ))}
      </UI.DiagnosesContainer>
      <UI.ButtonsWrapper>
        <Button
          buttonType="transparent"
          caption="Show previous day"
          onClick={handlePreviousDayClick}
          disabled={(showHistory && isHistoryEnd) || !diagnosesHistory.length}
        />
        <UI.ButtonRow>
          <Button
            onClick={handlePhoneCall}
            buttonType="transparent"
            caption="Call 911"
            image={phone}
          />
          <Button buttonType={'transparent'} caption={'Find hospital'} onClick={handleFindHospital} />
        </UI.ButtonRow>
        <Button onClick={handleEndSessionButton} buttonType="filled" caption="End session" />
      </UI.ButtonsWrapper>
      {endSessionModal && (
        <EndSessionModal
          visible={endSessionModal}
          handleVisibility={handleEndSessionVisibility}
          handleYes={() => handleModalClick(true)}
          handleNo={handleModalClick}
        />
      )}
    </>
  );
};

export default Treatment;
