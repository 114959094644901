import React from 'react';
import { ModalWrapper, ModalBody, ModalHeader } from '../../../../components/Modal';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/BaseButton';
import { ModalContentWrapper } from './styles';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../store/modal';

interface IProps {
  onClose?: () => void
}

const MedicalTestModal: React.FC<IProps> = () => {
  const dispatch = useDispatch()

  const handleOkClick = () => {
    dispatch(modalActions.close('medicalTestAttention'));
  };

  return (
    <ModalWrapper>
      <ModalBody>
        <ModalHeader>
          <Typography type="h2">Attention</Typography>
        </ModalHeader>
        <ModalContentWrapper>
          <Typography type="p">
            This service is not intended to provide diagnosis, treatment, or medical advice.<br/><br/>
            Products, services, information and other materials presented on this site, 
            including information posted on this site directly or by linking to third party 
            websites, are provided for informational purposes only.<br/><br/>
            Please consult your doctor for any health, diagnosis and treatment issues.
          </Typography>
          <Button
            onClick={handleOkClick}
            buttonType="filled"
            caption="OK"
            width="100%"
          />
        </ModalContentWrapper>
      </ModalBody>
    </ModalWrapper>
  );
};

export default MedicalTestModal;

