import styled, { css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import { ButtonType } from '../BaseButton';

export interface IStyledButton {
  buttonType?: ButtonType;
  disabled?: boolean;
  width?: string;
}

const StyledButton = styled.button`
  display: flex;
  width: ${(props: IStyledButton) => props.width || '100%'};
  align-items: center;
  justify-content: center;
  appearance: none;
  outline: none;
  min-height: 40px;
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    min-height: 48px;
  `}
  border-radius: 8px;
  border: 2px solid ${Colors.BLUE};
  background-color: ${Colors.TRANSPARENT};
  position: relative;

  * {
    cursor: pointer;
  }
  
  & > * {
    transition: opacity 0.4s ease-in-out;
    opacity: 100%;
  }

  p {
    line-height: normal;
  }

  & > img {
    margin-right: 8px;
  }

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    height: 48px;
    min-height: 48px;
  `}

  ${(props: IStyledButton) => props.buttonType === "filled" && css`
    background-color: ${Colors.BLUE};
    p {
      color: ${Colors.WHITE};
    }
  `}

  ${(props: IStyledButton) => props.disabled && css`
    background: ${props.buttonType === "filled" ? Colors.DISABLE : Colors.TRANSPARENT};
    border: 2px solid ${Colors.DISABLE};

    p {
      color: ${props.buttonType === "filled" ? Colors.WHITE : Colors.DISABLE};
    }
  `}
`;

export default StyledButton;
