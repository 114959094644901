import React, { useState } from 'react';
import * as UI from './styles';
import { IDisease } from '../../../../models/Disease';
import DropdownContent from '../../../../components/DropdownContent';
import ButtonItem from '../../../../components/ButtonItem';
import Colors from '../../../../enums/Colors';
import Typography from '../../../../components/Typography';
import DiseaseModal, { IDiseaseModalContent } from '../DiseaseModal';
import { bulletListString } from '../../../../helpers/list';
import { getFormattedDate } from '../../../../helpers/date';

interface IDiseaseDropdown {
  disease: IDisease;
}

const DiseaseDropdown: React.FC<IDiseaseDropdown> = ({
  disease
}) => {
  const [modalContent, setModalContent] = useState<IDiseaseModalContent | null>(null);

  const handleButtonItemClick = (content: IDiseaseModalContent) => {
    setModalContent(content);
  };

  const handleModalVisibility = (visible: boolean) =>
    !visible && setModalContent(null);

  return (
    <>
      <DropdownContent caption={disease.title}>
        <UI.ButtonListWrapper>
          <ButtonItem
            caption="Description"
            onClick={() => handleButtonItemClick({
              title: "Description",
              body: disease.description
            })}
          />
          <ButtonItem
            caption="Cause"
            onClick={() => handleButtonItemClick({
              title: "Cause",
              body: disease.cause
            })}
          />
          <ButtonItem
            caption="Prescription"
            onClick={() => handleButtonItemClick({
              title: "Prescription",
              body: bulletListString(disease.drugs.map((item) => item.title))
            })}
          />
          <ButtonItem
            caption="Medical Actions"
            onClick={() => handleButtonItemClick({
              title: "Medical Actions",
              body: bulletListString(disease.medicalActions.map((item) => item.title))
            })}
          />
          <ButtonItem
            caption="vDoctors"
            onClick={() => handleButtonItemClick({
              title: "vDoctors",
              body: bulletListString(disease.specializations.map((item) => item.title))
            })}
          />
        </UI.ButtonListWrapper>
        <UI.Button>
          <Typography type="button" color={Colors.WHITE}>
            Guide me
          </Typography>
        </UI.Button>
      </DropdownContent>
      {modalContent && <DiseaseModal content={modalContent} handleVisibility={handleModalVisibility}  />}
    </>
  );
};

export default DiseaseDropdown;
