import React from 'react'
import * as UI from './styles'

interface IProps {
  images: string[]
  item: number
  selectImage: (item: number) => void
}

const ImageControl: React.FC<IProps> = ({ images, item, selectImage }) => {
  const buttons = images.map((el, index) => (
    <UI.ImageButton onClick={() => selectImage(index)} active={index === item} key={index}></UI.ImageButton>
  ))

  return (
    <UI.ImageControlWrapper>
      {buttons}
    </UI.ImageControlWrapper>
  )
}

export default ImageControl