import React, { useState } from 'react';
import Button from '../../../../components/BaseButton';
import Typography from '../../../../components/Typography';
import { StyledForm, FormWrapper } from '../../../../components/Form';
import { useForm } from 'react-hook-form';
import PasswordChanged from '../PasswordChanged';
import PasswordInput from '../../../../components/PasswordInput';

interface INewPasswordForm {
  password: string;
}

const NewPasswordForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const { handleSubmit, control, formState: { errors } } = useForm<INewPasswordForm>();

  const handlePasswordReset = (data: INewPasswordForm) => {
    setPassword(data.password);
  };

  return (
    <FormWrapper>
      {!password ? (
        <>
          <Typography type='h1'>Set new password</Typography>
          <StyledForm
            onSubmit={handleSubmit(data => handlePasswordReset(data))}
            autoComplete='off'
          >
            <PasswordInput 
              control={control}
              error={errors.password}
              label='New password'
              showPasswordToggle
              showRequiredMessage
              useLengthRules
            />
            <Button isSubmit caption='Confirm' />
          </StyledForm>
        </>) :
        <PasswordChanged />
      } 
    </FormWrapper>
  );
};

export default NewPasswordForm;
