import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HeaderWithTitle from '../../../../components/HeaderWithTitle';
import RoutesEnum from '../../../../enums/Routes';
import { medicalTestActions, MedicalTestStepEnum } from '../../../../store/medicalTest';
import * as RootUI from '../styles';

const WaitingResults: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.setTimeout(() => {
      dispatch(medicalTestActions.setStep(MedicalTestStepEnum.RESULTS))
    }, 1500)
  }, [])

  return (
    <RootUI.Wrapper>
      <HeaderWithTitle
        description='Your future digitized test results will appear here'
        greenTitle='Decryption results'
        backRoute={RoutesEnum.CORRECTIONS}
      />
    </RootUI.Wrapper>
  );
};

export default WaitingResults;
