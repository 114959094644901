import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderWithTitle from '../../../../components/HeaderWithTitle';
import MenuItem from '../../../../components/MenuItem';
import { getFileIcon } from '../../../../constants/fileIcons';
import RoutesEnum from '../../../../enums/Routes';
import {
  medicalTestActions,
  medicalTestSelectors,
  MedicalTestStepEnum,
} from '../../../../store/medicalTest';
import * as RootUI from '../styles';
import UploadMedicalFile from '../UploadMedicalFile';
import * as UI from './styles';

const ResultProcessing: React.FC = () => {
  const selectedTests = useSelector(medicalTestSelectors.selectedTests);
  const currentTest = useSelector(medicalTestSelectors.currentTest);
  const dispatch = useDispatch();

  const fileList = currentTest?.files.map((el) => {
    const matchs = el.name.match(/\.[0-9a-zA-Z]+$/);
    if (!matchs?.length) return;
    const name = el.name.replace(/\.[0-9a-zA-Z]+$/, '');
    const ext = matchs[0].toLowerCase();
    const icon = getFileIcon(ext);

    return (
      <MenuItem CustomMark={() => <UI.Loading />} key={el.id}>
        <UI.FileInfo>
          {icon}
          <UI.FileDescription>
            <UI.Label type="h2">Results processing...</UI.Label>
            <UI.FileName type="p">{name}</UI.FileName>
          </UI.FileDescription>
        </UI.FileInfo>
      </MenuItem>
    );
  });
  const handleDecryption = () => {
    if (selectedTests.length > 1 && currentTest) {
      dispatch(medicalTestActions.completeTest(currentTest));
      dispatch(medicalTestActions.setStep(MedicalTestStepEnum.LOAD_DATA));
    } else dispatch(medicalTestActions.setStep(MedicalTestStepEnum.WAITING_RESULTS));
  };

  return (
    <RootUI.Wrapper>
      <HeaderWithTitle
        backRoute={RoutesEnum.CORRECTIONS}
        description="Your uploaded documents. You can delete or change before submitting"
        greenTitle="Results processing"
      />
      <UI.Content>
        <UI.FileListWrapper>{fileList}</UI.FileListWrapper>
        <RootUI.Control>
          <UploadMedicalFile newFile />
          <RootUI.StyledButton
            buttonType="filled"
            caption="Decryption results"
            onClick={handleDecryption}
          />
        </RootUI.Control>
      </UI.Content>
    </RootUI.Wrapper>
  );
};

export default ResultProcessing;
