import { IReduxState } from '..';
import { sessionId } from '../../helpers/session';
import { fetchDiseasePhotos } from '../../services/session';

enum ACTIONS {
  ADD_PHOTO = 'ADD_PHOTO',
  DELETE_PHOTO = 'DELETE_PHOTO',
  SET_PHOTOS = 'SET_PHOTOS',
}

type IAction<Type, Payload> = {
  type: Type;
  payload: Payload;
};

type Action = 
  IAction<ACTIONS.ADD_PHOTO | ACTIONS.DELETE_PHOTO, string> | 
  IAction<ACTIONS.SET_PHOTOS, string[]>;

interface IDiseasePhotosState {
  photoId: string[];
  sessionId: string;
}

const initialState: IDiseasePhotosState = {
  photoId: [],
  sessionId: sessionId(),
};

export const addPhoto = (photoId: string) => (dispatch: Function) => dispatch(
  { type: ACTIONS.ADD_PHOTO, payload: photoId }
);

export const deletePhoto = (photoId: string) => (dispatch: Function) => dispatch (
  { type: ACTIONS.DELETE_PHOTO, payload: photoId }
);

export const getSessionPhotos = () => (dispatch: Function) => {
  fetchDiseasePhotos().then((response) => {
    const sessionPhotos = (response && response?.map((photo) => photo.id) ) || [];
    dispatch({ type: ACTIONS.SET_PHOTOS, payload: sessionPhotos });
  });
};

const photosReducer = (
  state: IDiseasePhotosState = initialState,
  action: Action
): IDiseasePhotosState => {
  switch (action.type) {
    case ACTIONS.ADD_PHOTO:
      return {...state, photoId: [...state.photoId, action.payload]};
    case ACTIONS.DELETE_PHOTO:
      return {...state, photoId: state.photoId.filter((photo) => photo !== action.payload)};
    case ACTIONS.SET_PHOTOS:
      return {...state, photoId: action.payload};
    default:
      return state;
  }
};

export const photosSelector = (state: IReduxState) => state.photosReducer.photoId;

export default photosReducer;
