import styled, { css } from 'styled-components';
import DragAndDrop from '../../../../components/DragAndDrop';
import { ScreenWidthEnum } from '../../../../enums/screenWidth';
import * as RootUI from '../styles';

export const Input = styled.input`
  display: none;
`;

export const Control = styled(RootUI.Control)`
  ${(props) =>
    props.theme.screen <= ScreenWidthEnum.landscapePhone &&
    css`
      margin-top: 30px;
      margin-bottom: 0;
    `}
  ${(props) =>
    props.theme.screen >= ScreenWidthEnum.laptop &&
    css`
      width: 615px;
      margin: 0 auto;
      display: block;
      > * + * {
        margin-left: 0; 
      }
      button {
        width: 100%;
      }
    `}
`;
export const Content = styled(RootUI.Content)`
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.tablet &&
    css`
      justify-content: flex-end;
    `}
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.desktop &&
    css`
      margin-top: 100px;
      justify-content: flex-start;
    `}
`;

export const StyledDragAndDrop = styled(DragAndDrop)`
  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  height: 40px;
  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    height: 48px;
  `}
  p {
    display: block;
  }
` 