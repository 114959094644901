import React, { useState } from 'react';
import Button from '../../../../components/BaseButton';
import Typography from '../../../../components/Typography';
import { StyledForm, FormWrapper } from '../../../../components/Form';
import { useForm } from 'react-hook-form';
import EmailSent from '../EmailSent';
import { useHistory } from 'react-router';
import RoutesEnum from '../../../../enums/Routes';
import QuestionCaption from '../../../../components/QuestionCaption';
import EmailInput from '../../../../components/EmailInput';

interface IPasswordResetForm {
  email: string;
}

const PasswordResetForm: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const { handleSubmit, control, formState: { errors } } = useForm<IPasswordResetForm>();

  const handleSignInClick = () => {
    history.push(RoutesEnum.SIGN_IN);
  };

  const handlePasswordReset = (data: IPasswordResetForm) => {
    setEmail(data.email);
  };

  return (
    <>
      <FormWrapper>
        {!email ? (
          <>
            <Typography type='h1'>Reset password</Typography>
            <StyledForm
              onSubmit={handleSubmit(data => handlePasswordReset(data))}
              autoComplete='off'  
            >
              <Typography type='p'>
                Enter your user account's verified email address and we will send you a password reset link
              </Typography>
              <EmailInput
                control={control}
                error={errors.email}
              />
              <Button isSubmit caption='Send password reset email' />
            </StyledForm>
          </>) :
          <EmailSent email={email} />
        }
      </FormWrapper>
      {!email && 
        <>
          <QuestionCaption caption='Want to go back?' />
          <Button
            buttonType='transparent'
            caption='Sign in'
            onClick={handleSignInClick}
          />
        </>
      }
    </>
  );
};

export default PasswordResetForm;
