import { MODALS } from '../../constants/modals';
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { IReduxState } from '..';
import { ModalKeys } from '../../models/modal';

const initialState = {
  modals: [] as ModalKeys[]
}

export const modalActions = {
  open: createAction<ModalKeys>('MODAL/OPEN'),
  close: createAction<ModalKeys>('MODAL/CLOSE')
}; 

const modalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(modalActions.open, (state, action) => {
      state.modals.push(action.payload)
    })
    .addCase(modalActions.close, (state, action) => {
      state.modals = state.modals.filter(el => el !== action.payload)
    })
});

const defaultSelector = (state: IReduxState) => state.modalReducer;

export const modalSelectors = {
  modals: createSelector(defaultSelector, (state) => state.modals),
};

export default modalReducer;
