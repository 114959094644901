import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from '../enums/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { statusInterceptor, statusSelector } from '../store/interceptor';
import { fetchCurrentUser } from '../services/user';
import { sessionId } from '../helpers/session';
import { screenActions, screenSelectors } from '../store/screen';
import { ScreenBreakPointEnum, ScreenWidthEnum } from '../enums/screenWidth';
import { ThemeProvider } from 'styled-components';
import AuthorizationSwitch from './Switch/Authorization';
import AppSwitch from './Switch/App';
import ErrorMessage from '../pages/Service/ErrorMessage';
import NotificationProvider from '../components/NotificationProvider';
import ScreenOrientationEnum from '../enums/ScreenOrientation';
import ModalProvider from '../components/ModalProvider';

const AppRouter: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const screenWidth = useSelector(screenSelectors.width);
  const [windowHeigth, setWindowHeigth] = useState(window.screen.height)

  const setViewportHeight = () => {
    const viewport: HTMLMetaElement | null = document.querySelector('meta[name=viewport]');
    const viewportContent = viewport?.content;
    const viewportString = viewportContent?.substring(0, viewportContent?.indexOf(', height=')) || viewportContent;
    viewport?.setAttribute('content', viewportString + ', height=' + window.innerHeight);
  };

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const clientHeight = document.documentElement.clientHeight;
    const screenHeight = window.screen.height;
    const isFullHeight = height > (screenHeight / 1.5);
    const orientation = window.screen.orientation;
    const portraitOrientation = orientation?.type === ScreenOrientationEnum.PORTRAIT_PRIMARY || orientation?.type === ScreenOrientationEnum.PORTRAIT_SECONDARY;
    const landscapeOrientation = orientation?.type === ScreenOrientationEnum.LANDSCAPE_PRIMARY || orientation?.type === ScreenOrientationEnum.LANDSCAPE_SECONDARY;
    const isPortrait = orientation ? (portraitOrientation && isFullHeight) : height > width;
    const isLandscape = orientation ? (landscapeOrientation && isFullHeight) : width > height;

    setWindowHeigth(clientHeight)
    if (isPortrait || isLandscape) {
      setViewportHeight();
      if (height <= ScreenBreakPointEnum.phone && isLandscape)
        dispatch(screenActions.setWidth(ScreenWidthEnum.landscapePhone));
      else if (width >= ScreenBreakPointEnum.phone && width < ScreenBreakPointEnum.tablet && (orientation ? portraitOrientation : isPortrait))
        dispatch(screenActions.setWidth(ScreenWidthEnum.tablet));
      else if (width >= ScreenBreakPointEnum.phone && width < ScreenBreakPointEnum.laptop) dispatch(screenActions.setWidth(ScreenWidthEnum.laptop));
      else if (width >= ScreenBreakPointEnum.laptop) dispatch(screenActions.setWidth(ScreenWidthEnum.desktop));
      else if (width < ScreenBreakPointEnum.phone) dispatch(screenActions.setWidth(ScreenWidthEnum.phone));
    }
  }, []);

  dispatch(statusInterceptor());

  useEffect(() => {
    if (!sessionId()) history.push(Routes.DEFAULT);
    fetchCurrentUser().then(() => {
      if (history.location.pathname === Routes.DEFAULT) history.push(Routes.PATIENT);
    });
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const router = useMemo(
    () =>
      ((status === 403 || status === 404 || (status && status >= 500)) && <ErrorMessage />) ||
      (status === 401 && <AuthorizationSwitch />) || <AppSwitch />,
    [status],
  );

  return (
    <ThemeProvider
      theme={{
        screen: screenWidth,
        height: windowHeigth
      }}>
      <NotificationProvider>{router}</NotificationProvider>
      <ModalProvider />
    </ThemeProvider>
  );
};

export default AppRouter;
