import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RoutesEnum from '../../../enums/Routes';
import AuthLayout from '../../../layout/Authorization';
import NewPassword from '../../../pages/Authorization/NewPassword';
import PasswordReset from '../../../pages/Authorization/PasswordReset';
import SignIn from '../../../pages/Authorization/SignIn';
import SignUp from '../../../pages/Authorization/SignUp';
import * as UI from './styles'

const AuthorizationSwitch: React.FC = () => {


  return (
    <AuthLayout>
      <UI.AuthStyles />
      <Switch>
        <Route exact path={RoutesEnum.NEW_PASSWORD} component={NewPassword} />
        <Route exact path={RoutesEnum.PASSWORD_RESET} component={PasswordReset} />
        <Route exact path={RoutesEnum.SIGN_UP} component={SignUp} />
        <Route exact path={RoutesEnum.SIGN_IN} component={SignIn} />
        <Route component={SignIn} />
      </Switch>
    </AuthLayout>
  );
};

export default AuthorizationSwitch;
