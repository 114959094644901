import React from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { MODALS } from "../constants/modals";
import { modalSelectors } from "../store/modal";

const ModalProvider: React.FC = () => {
  const openedModals = useSelector(modalSelectors.modals)

  const modalComponents = openedModals.map(el => {
    const Component = MODALS[el]
    return <Component key={v4()} />
  })
  
  return <>{modalComponents}</>
}

export default ModalProvider