import {ReactComponent as Jpg} from '../assets/icons/files/jpg.svg'
import {ReactComponent as Pdf} from '../assets/icons/files/pdf.svg'

const fileIcons = {
  jpg: <Jpg />,
  pdf: <Pdf />
}

export const getFileIcon = (ext: string) => {
  const icon = Object.entries(fileIcons).find(([key, value]) => `.${key}` === ext)
  // TODO: Переделать под другие расширения когда появятся иконки
  return icon ? icon[1] : fileIcons.jpg
}