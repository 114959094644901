import { getRequest, postRequest, publicUrl, getBase64Img } from "../../api";
import { sessionId } from "../../helpers/session";
import { IImageResponse } from "../../models/Image";

export const uploadSessionImages = (files: File[]) => {
  const data = new FormData();
  for (const file of files) {
    data.append("file", file);
  }
  data.append("sessionId", sessionId());
  return postRequest("/attachment/upload", data);
};

export const getSessionImages = (): Promise<IImageResponse[]> => {
  return getRequest(`/attachment/get?sessionId=${sessionId()}`);
};

export const userImgUrl = (url: string) =>
  publicUrl + `/api/jr/download/attachment?fileId=${url}`;

export const getUserImage = (id: string) => getBase64Img('/jr/download/attachment?fileId=', id);
