import { getRequest } from "../../api";
import { ISpecializationOrgan } from "../../models/Symptom";

export const fetchSpecializations = (id?: string) => {
  return getRequest(id ? "/specialization/" + id : "/specialization/");
};

export const fetchSpecializationBodyparts = (specializationId?: string): Promise<ISpecializationOrgan[]> => {
  return getRequest(`/organ/position/specialization/?specialization_id=${specializationId}`);
};
