import styled from 'styled-components/macro';
import StyledButton from '../../../../components/Button';
import Colors from '../../../../enums/Colors';

export const ButtonListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const Button = styled(StyledButton)`
  width: 100%;
  background: ${Colors.LIGHT_GRAY};
  border: none;
  margin-top: 20px;
`;
