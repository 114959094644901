import React from 'react';
import * as UI from './styles';
import RoutesEnum from '../../enums/Routes';
import BackButton from '../BackButton';
import ColorsEnum from '../../enums/Colors';

export interface IHeaderWithButton {
  pageTitle?: string;
  greenTitle?: string;
  buttonCaption?: string;
  backRoute?: RoutesEnum;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  isBackButton?: boolean;
}

const HeaderWithButton: React.FC<IHeaderWithButton> = ({
  pageTitle,
  buttonCaption,
  backRoute,
  onButtonClick,
  buttonDisabled,
  isBackButton = true,
  greenTitle,
  ...props
}) => (
  <UI.HeaderWrapper {...props}>
    {isBackButton ? (
      <BackButton backRoute={backRoute} onClick={onButtonClick} disabled={buttonDisabled} />
    ) : (
      <UI.StyledButton
        caption={buttonCaption}
        onClick={onButtonClick}
        buttonType="transparent"
        disabled={buttonDisabled}
        width="160px"
      />
    )}
    {(pageTitle || greenTitle) && (
      <UI.Header type="h1">
        {pageTitle}
        {greenTitle && (
          <UI.GreenTypography type="h1" color={ColorsEnum.GREEN}>
            {greenTitle}
          </UI.GreenTypography>
        )}
      </UI.Header>
    )}
  </UI.HeaderWrapper>
);

export default HeaderWithButton;
