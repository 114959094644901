import React from 'react';
import * as UI from './styles';
import Typography from '../Typography';
import arrow from '../../assets/icons/arrowGrayRight.svg';

export type ButtonItemType = "filled" | "transparent";

interface IButtonItem {
  caption: string;
  onClick?: () => void;
  itemType?: ButtonItemType;
}

const ButtonItem: React.FC<IButtonItem> = ({
  caption, 
  onClick, 
  itemType = "transparent"
}) => (
  <UI.ButtonItemWrapper onClick={onClick} itemType={itemType}>
    <Typography type={itemType === "transparent" ? "link" : "h2"}>{caption}</Typography>
    <img src={arrow} />
  </UI.ButtonItemWrapper>
);

export default ButtonItem;
