import React, { useEffect, useState } from 'react';
import * as UI from './styles';
import { ModalWrapper, ModalBody, ModalHeader } from '../../../../components/Modal';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/BaseButton';
import CloseButton from '../../../../components/CloseButton';
import ImageControl from './ImageControl';

interface IMultiImageModal {
  title?: string;
  visible?: boolean;
  handleVisibility: (visible: boolean) => void;
  firstAction: (i: number) => void;
  secondAction: (i: number) => void;
  buttonTitles: [string, string]
  currentImageIndex: number
  images: string[];
}

const ImageModal: React.FC<IMultiImageModal> = ({
  visible = true,
  handleVisibility,
  firstAction,
  secondAction,
  images,
  currentImageIndex,
  title,
  buttonTitles,
}) => {
  const [imageIndex, setImageIndex] = useState(currentImageIndex)
  const { ref, isComponentVisible, setComponentVisible, handleChangeComponentVisibility } = useComponentVisible(
    visible
  );

  useEffect(() => {
    if (visible) setComponentVisible(visible);
  }, [visible]);
  useEffect(() => {
    handleVisibility(isComponentVisible);
  }, [isComponentVisible]);

  const handleFirstAction = () => {
    if (firstAction) firstAction(imageIndex);
    handleChangeComponentVisibility();
  };
  const handleSecondAction = () => {
    if (secondAction) secondAction(imageIndex);
    handleChangeComponentVisibility();
  };
  const selectImage = (item: number) => {
    setImageIndex(item)
  }

  return (
    <ModalWrapper>
      <UI.MultiImageBody ref={ref}>
        <ModalHeader>
          {title &&
            <Typography type="h2" lineHeight={14}>
              {title}
            </Typography>
          }
          <CloseButton onClick={handleChangeComponentVisibility} />
        </ModalHeader>
        <UI.ModalContentWrapper>
          <UI.StyledImage src={images[imageIndex]} />
          <ImageControl images={images} item={imageIndex} selectImage={selectImage} />
          <UI.ButtonsWrapper>
            <Button buttonType="transparent" caption={buttonTitles[0]} onClick={handleFirstAction} />
            <Button buttonType="filled" caption={buttonTitles[1]} onClick={handleSecondAction} />
          </UI.ButtonsWrapper>
        </UI.ModalContentWrapper>
      </UI.MultiImageBody>
    </ModalWrapper>
  );
}
export default ImageModal;
