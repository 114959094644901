import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions, notificationSelectors } from '../store/notification';
import Alert from './Alert';

const NotificationProvider: React.FC = ({ children }) => {
  const notifications = useSelector(notificationSelectors.list);
  const dispatch = useDispatch();

  const handleAlert = (id: string) => {
    dispatch(notificationActions.remove(id));
    dispatch(notificationActions.clearTimeout(id))
  };

  const notificationsList = notifications.map((el) => 
    <Alert
      type={el.type}
      caption={el.message}
      handleClose={() => handleAlert(el.id)}
      key={el.id}
    />
  );
  return (
    <>
      {children}
      {notificationsList}
    </>
  );
};

export default NotificationProvider;
