import React from 'react';
import Button from '../BaseButton';
import Typography from '../Typography';
import * as UI from './styles';

interface IProps {
  onClose: () => void;
}

const Reader: React.FC<IProps> = ({ onClose }) => {
  return (
    <UI.Wrapper>
      <UI.Close onClick={onClose} />
      <UI.DocumentWrapper>
        <UI.Document>
          <Typography type='h2'>In this window you will see the result of analysis</Typography>
        </UI.Document>
        <UI.ButtonWrapper>
          <Button buttonType="filled" disabled>
            <Typography type="button">
              <a href={`#`}>Download</a>
            </Typography>
          </Button>
          <Button buttonType="transparent" caption="Send to email">
            <Typography type="button">
              <a href={`mailto:test@mail.ru`}>Send to email</a>
            </Typography>
          </Button>
        </UI.ButtonWrapper>
      </UI.DocumentWrapper>
    </UI.Wrapper>
  );
};

export default Reader;
