import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonItem from '../../../../components/ButtonItem';
import Typography from '../../../../components/Typography';
import Routes from '../../../../enums/Routes';
import ISpecialization from '../../../../models/Specialization';
import { getSpecializationHistory, sendSessionSpecialization } from '../../../../services/session';
import * as UI from './styles';

const MyDoctors: React.FC = () => {
  const [myDoctors, setMyDoctors] = useState<ISpecialization[]>([]);
  const history = useHistory();

  useEffect(() => {
    getSpecializationHistory().then((specializationHistory) => {
      setMyDoctors(specializationHistory);
    });
  }, []);

  const handleOptionClick = (id: string) => {
    sendSessionSpecialization(id).then(() => {
      history.push(Routes.SYMPTOMS);
    });
  };

  return (
    <>
      {(myDoctors && myDoctors.length > 0) &&
        <UI.Wrapper>
          <Typography type='h2'>My vDoctors</Typography>
          {myDoctors.map((specialization) => 
            <ButtonItem
              onClick={() => handleOptionClick(specialization.id)}
              caption={specialization.title}
              key={specialization.id}
            />
          )}
        </UI.Wrapper>
      }
    </>
  );
};

export default MyDoctors;
