import React from 'react';
import * as UI from './styles';
import menuBars from '../../../assets/icons/menuBars.svg';
import useComponentVisible from '../../../hooks/useComponentVisible';
import Typography from '../../Typography';
import menuItems from '../../../constants/menu';
import { Link } from 'react-router-dom';
import Button from '../../BaseButton';
import Colors from '../../../enums/Colors';
import { signOut } from '../../../services/user';
import CloseButton from '../../CloseButton';
import Logo from '../../Logo';
import { ItemsContainer } from '..';
import { modalActions } from '../../../store/modal';
import { useDispatch } from 'react-redux';
import { getMenuRouteAction } from '../../../helpers/getMenuRouteAction';
import { useLocation } from 'react-router-dom'

const MobileMenu: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { ref, isComponentVisible, handleChangeComponentVisibility } = useComponentVisible(
    false
  );

  const openFeatureInDevModal = () => {
    dispatch(modalActions.open('featureInDev'))
  }

  const menu = menuItems.map((item, index) => {
    const action = getMenuRouteAction(item.route, location.pathname)

    return (
      <Link to={action} onClick={handleChangeComponentVisibility} key={index}>
        <UI.StyledMenuItem onClick={!item.route ? openFeatureInDevModal : undefined}>
          <Typography type='p' color={Colors.BLACK}>{item.title}</Typography>
        </UI.StyledMenuItem>
      </Link>
    );
  })

  return (
    <>
      <UI.MenuBars src={menuBars} onClick={handleChangeComponentVisibility} />
      <UI.MenuWrapper show={isComponentVisible}>
        <UI.MobileMenuBody ref={ref}>
          <UI.MenuHeader>
            <Logo logoType='small' />
            <CloseButton onClick={handleChangeComponentVisibility} />
          </UI.MenuHeader>
          <ItemsContainer>
            {menu}
          </ItemsContainer>
          <Button caption='Log out' onClick={signOut} buttonType='transparent' />
        </UI.MobileMenuBody>
      </UI.MenuWrapper>
    </>
  );
}
export default MobileMenu;
