import styled, { css } from 'styled-components/macro';
import { ScreenWidthEnum } from '../../../enums/screenWidth';

export const DropdownsWrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  flex-flow: column;
  margin-top: 8px;
`;

export const DiagnosesContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
  overflow-y: auto;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-row-gap: 24px;
  align-self: center;
  width: 100%;
  margin-top: 24px;
  padding-bottom: 100px;

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
      padding-bottom: 70px;
  `}
`;

export const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 9px;
`;
