import React from 'react';
import * as UI from './styles';

export type logoType = 'large' | 'medium' | 'small';

export interface ILogo {

  logoType: logoType;
}

const Logo: React.FC<ILogo> = ({
  logoType
}) => (
  <UI.Paragraph logoType={logoType}>
    <UI.MedSpan logoType={logoType}>Med</UI.MedSpan>
    <UI.ZardSpan>Zard</UI.ZardSpan>
  </UI.Paragraph>
);

export default Logo;
