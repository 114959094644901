import styled from 'styled-components/macro';

export const ParagraphWrapper = styled.div`
  overflow-y: auto;
  p {
    overflow-y: auto;
    white-space: pre-wrap;
    line-height: 1.5;
    padding-right: 10px;
  }
`;
