import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import DesktopMenu from '../../components/SideMenu/DesktopMenu';
import MobileMenu from '../../components/SideMenu/MobileMenu';
import NavBar from '../../components/NavBar';
import { menuRoutes } from '../../constants/menuRoutes';
import { navRoutes } from '../../constants/navItems';
import RoutesEnum from '../../enums/Routes';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import { newSession, setMainMenuPath } from '../../helpers/session';
import { screenSelectors } from '../../store/screen';
import * as UI from './styles';

const Layout: React.FC = ({children}) => {
  const location = useLocation();
  const screenWidth = useSelector(screenSelectors.width);
  const currentRoute = location.pathname as RoutesEnum;

  useEffect(() => {
    if (menuRoutes.includes(currentRoute)) {
      setMainMenuPath(currentRoute);
      newSession();
    }
  }, [location]);

  return (
      <UI.LayoutWrapper>
        {(screenWidth === ScreenWidthEnum.laptop || screenWidth === ScreenWidthEnum.desktop) ? <DesktopMenu /> : <MobileMenu />}
        <UI.LayoutContainer>
          <UI.ContentWrapper>
            <UI.ContentContainer>
              {children}
            </UI.ContentContainer>
          </UI.ContentWrapper>
          {navRoutes.includes(currentRoute) && <NavBar />}
        </UI.LayoutContainer>
      </UI.LayoutWrapper>
  );
};

export default Layout;
