import React from 'react';
import Typography from '../Typography';
import StyledButton, { IStyledButton } from '../Button';
import arrow from '../../assets/icons/arrow.svg';
import ButtonCounter from '../ButtonCounter';
import * as UI from './styles';

export type ButtonType = "filled" | "transparent" | "back";

interface IButton extends IStyledButton {
  onClick?: () => void;
  caption?: string;
  isSubmit?: boolean;
  counter?: number;
  image?: string;
}

const Button: React.FC<IButton> = ({
  onClick,
  buttonType = "filled",
  caption,
  disabled,
  width,
  isSubmit,
  counter,
  image,
  children,
  ...props
}) => {
  return (
    <StyledButton
      onClick={onClick}
      buttonType={buttonType}
      disabled={disabled}
      width={width}
      type={isSubmit ? "submit" : "button"}
      {...props}
    >
      {(buttonType === "back" || image) && <img src={image || arrow} />}
      {children || <Typography type="button">{caption}</Typography>}
      {!!counter && <UI.Counter><ButtonCounter type={buttonType}>{counter}</ButtonCounter></UI.Counter>}
    </StyledButton>
  );
};

export default Button;
