import styled, { css } from 'styled-components/macro';
import { ItemType } from '../../../../components/CheckboxList/ListItem';
import { SearchInput } from '../../../../components/Search/styles';
import Typography from '../../../../components/Typography';
import { ScreenWidthEnum } from '../../../../enums/screenWidth';

interface ICheckboxListWrapper {
  type: ItemType;
  useModalLayout?: boolean;
}

export const CheckboxListWrapper = styled.div<ICheckboxListWrapper>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  max-height: 100%;
  overflow-y: auto;


  & > div {
    word-break: break-word;
    height: max-content;
  }

  ${props => props.theme.screen <= ScreenWidthEnum.landscapePhone && css`
    padding-right: 10px;
  `}

  ${props => !props.useModalLayout && css`
    > * + * {
      margin-top: 8px;
    }
  `}

  ${props => props.type === 'text' && css`
    margin-top: 16px;

    ${props => props.theme.screen <= ScreenWidthEnum.landscapePhone && css`
      margin-top: 8px;
    `}
  `}
`;

export const StyledSearchInput = styled(SearchInput)`
  border-radius: 8px;
  position: sticky;
  top: 0;
  max-height: 40px;
`;

export const SymptomsListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${(props: ICheckboxListWrapper) => props.type !== "gray" && css`
    align-self: flex-end;
  `}
`;
