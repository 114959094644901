import React from 'react';
import { Control, FieldError } from 'react-hook-form';
import { emailRegEx } from '../../helpers/regex';
import ControlledInput from '../ControlledInput';

export interface IEmailInput {
  control: Control<any, object>;
  error?: FieldError;
  name?: string;
  useAutocomplete?: boolean;
}

const EmailInput: React.FC<IEmailInput> = ({
  control,
  error,
  name,
  useAutocomplete
}) => {
  return(
    <ControlledInput
      name={name || 'email'}
      control={control}
      rules={{
        required: 'Required field',
        pattern: {
          value: emailRegEx,
          message: 'Invalid email'
        }
      }}
      placeholder='your.email@example.com'
      label='Email'
      error={error}
      autocomplete={useAutocomplete ? 'on' : 'off'}
    />
  );
};

export default EmailInput;
