import styled from 'styled-components/macro';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  button {
    margin-top: 14px;
  }
`;
