import React from 'react';
import PasswordResetForm from './PasswordResetForm';

const PasswordReset: React.FC = () => {
  return (
    <PasswordResetForm />
  );
};

export default PasswordReset;
