import React, { useState, useEffect, useMemo } from 'react';
import * as UI from './styles';
import BaseButton from '../BaseButton';
import ButtonItem from '../ButtonItem';
import Typography from '../Typography';
import IBaseItem from '../../models/BaseItem';
import ColorsEnum from '../../enums/Colors';

interface ISearch {
  onSearch: (search?: string) => void;
  onClick: () => void;
  options?: IBaseItem[];
  onOptionClick?: (id: string) => void;
}

const Search: React.FC<ISearch> = ({
  onSearch,
  onClick,
  options,
  onOptionClick
}) => {
  const [search, setSearch] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    onSearch(search);
  }, [search, onSearch]);

  useEffect(() => {
    if (showMessage) setShowMessage(false);
  }, [search]);

  const handleClick = () => {
    onClick();
    setShowMessage(true);
  };

  const handleOptionClick = (id: string) => {
    if (onOptionClick) onOptionClick(id);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const searchOptions = useMemo(() => {
    if (!options?.length && showMessage)
      return (
        <Typography
          type='p'
          color={ColorsEnum.BLACK}
        >
          Not found
        </Typography>
      );
    else
      return options?.map((option) =>
        <ButtonItem
          itemType='transparent'
          caption={option.title}
          key={option.id}
          onClick={() => handleOptionClick(option.id)} />);
  }, [options, showMessage]);

  return (
    <UI.SearchWrapper>
      <UI.SearchBar>
        <UI.SearchInput 
          value={search}
          onChange={handleInputChange}
        />
        <BaseButton
          caption='Find'
          onClick={handleClick}
        />
      </UI.SearchBar>
      <UI.OptionsWrapper>
        {searchOptions}
      </UI.OptionsWrapper>
    </UI.SearchWrapper>
  );
};

export default Search;
