import styled, { css } from 'styled-components/macro';
import { Gender, View, partsDimensions, backImageDimensions } from '../../constants/avatar';
import { VIEWPORT_HEIGHT } from '../../constants/viewport';
import { ScreenWidthEnum } from '../../enums/screenWidth';

interface IAvatarWrapper {
  gender: Gender;
  view: View;
  preview?: boolean;
}

export const AvatarWrapper = styled.div<IAvatarWrapper>`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 2rem;
  z-index: 3;
  pointer-events: none;

  ${(props) => css`
    width: calc((${partsDimensions[props.gender][props.view].width} / ${VIEWPORT_HEIGHT}) * 100vh);
    height: calc((${partsDimensions[props.gender][props.view].height} / ${VIEWPORT_HEIGHT}) * 100vh);
  `}

  ${props => props.theme.screen === ScreenWidthEnum.phone && css`
    transform: scale(.8);
    bottom: 0;
    top: -100px;
    margin-left: ${props.preview ? '0px' : 'auto'};
  `}

  ${props => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    bottom: 4rem;
  `}

  ${(props) => props.preview && (ScreenWidthEnum.tablet === props.theme.screen) && css`
    margin-left: 20px;
  `}

  img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & > :first-child {
    top: 0;
    z-index: 2;

    ${(props) => css `
      width: calc((${backImageDimensions[props.gender][props.view].width} / ${VIEWPORT_HEIGHT}) * 100vh);
      height: calc((${backImageDimensions[props.gender][props.view].height} / ${VIEWPORT_HEIGHT}) * 100vh);
    `}
  }

  & > :last-child {
    z-index: 1;

    ${(props) => css`
      height: calc((30 / ${partsDimensions[props.gender][props.view].height}) * 100%);
    `}
  }
`;

export const BodyWrapper = styled.div<IAvatarWrapper>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
`;
