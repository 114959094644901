import React from 'react';
import * as UI from './styles';
import Typography from '../Typography';
import Colors from '../../enums/Colors';
import arrow from '../../assets/icons/arrowGrayRight.svg';
import Routes from '../../enums/Routes';

interface IMenuItem {
  color?: Colors;
  image?: string;
  caption: string;
  route: Routes;
}

const ImageLink: React.FC<IMenuItem> = ({color, image, caption, route}) => (
  <UI.ImageLinkWrapper to={route}>
    <UI.ImageWrapper background={color}>
      <img src={image} />
    </UI.ImageWrapper>
    <UI.CaptionWrapper>
      <Typography type='p' lineHeight={14} color={Colors.BLACK}>{caption}</Typography>
      <img src={arrow} />
    </UI.CaptionWrapper>
  </UI.ImageLinkWrapper>
);

export default ImageLink;
