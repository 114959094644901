import React from 'react';
import FindDoctor from './FindDoctor';
import MyDoctors from './MyDoctors';
import HeaderWithTitle from '../../../components/HeaderWithTitle';
import { MyDoctorsWrapper } from './styles';

const Patient: React.FC = () => {
  return (
    <>
      <HeaderWithTitle
        pageTitle='Find vDoctor'
        buttonCaption='Last Exam'
        buttonDisabled
        isBackButton={false}
      />
      <FindDoctor />
      <MyDoctorsWrapper>
        <MyDoctors />
      </MyDoctorsWrapper>
    </>
  );
};

export default Patient;
