import styled from 'styled-components'
import FileList from '../../../../components/FileList'
import * as RootUI from '../styles'

export const StyledContent = styled(RootUI.Content)`
  overflow: auto;
`

export const StyledFileList = styled(FileList)`
  height: 75%;
  overflow: auto;
`