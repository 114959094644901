import { IReduxState } from '..';
import { sessionId } from '../../helpers/session';

enum ACTIONS {
  ADD_IMAGE = 'ADD_IMAGE',
  DELETE_IMAGE = 'DELETE_IMAGE'
}

type IAction<Type, Payload> = {
  type: Type;
  payload: Payload;
};

type Action = 
  IAction<ACTIONS.ADD_IMAGE, File> |
  IAction<ACTIONS.DELETE_IMAGE, number>;

interface IUserImagesState {
  images: File[];
  sessionId: string;
}

const initialState: IUserImagesState = {
  images: [],
  sessionId: sessionId(),
};

export const addImage = (image: File) => (dispatch: Function) => dispatch(
  { type: ACTIONS.ADD_IMAGE, payload: image }
);

export const deleteImage = (index: number) => (dispatch: Function) => dispatch (
  { type: ACTIONS.DELETE_IMAGE, payload: index }
);

const userImagesReducer = (
  state: IUserImagesState = initialState,
  action: Action
): IUserImagesState => {
  switch (action.type) {
    case ACTIONS.ADD_IMAGE:
      return {...state, images: [...state.images, action.payload]};
    case ACTIONS.DELETE_IMAGE:
      return {...state, images: state.images.filter((image, i) => i !== action.payload)};
    default:
      return state;
  }
};

export const sessionUserImages = (state: IReduxState) => state.userImagesReducer.images;

export default userImagesReducer;
