import styled from 'styled-components'

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > * + * {
    margin-left: 8px;
  }
  button {
    width: 48px;
    height: 48px;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`

export const Content = styled.div`
  > * + * {
    margin-top: 16px;
  }
`