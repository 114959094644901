import styled, { FlattenSimpleInterpolation, css } from 'styled-components/macro';

interface IImageWrapper {
  styles?: FlattenSimpleInterpolation;
  pointer?: boolean;
}

export const ImageWrapper = styled.div<IImageWrapper>`
  z-index: 3;
  pointer-events: none;

  svg:focus {
    border: none;
    outline: none;
  }

  svg {
    width: 100%;
    height: 100%;

    ${(props) => props.pointer && css`
      cursor: pointer;  
      pointer-events: initial;
    `}
  }

  ${({ styles }) => styles};
`;
