import styled, { css } from 'styled-components/macro';
import { MenuBody } from '..';
import Colors from '../../../enums/Colors';
import { ScreenWidthEnum } from '../../../enums/screenWidth';
import { MenuItemWrapper } from '../../MenuItem/styles';

export const DesktopMenuBody = styled(MenuBody)`
  width: 224px;
  min-width: 224px;
  background: ${Colors.OFF_WHITE};

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    width: 272px;
    min-width: 272px;
  `}
`;

export const MenuHeader = styled.div`
  margin-bottom: 20px;
  padding-left: 16px;
`;

export const StyledMenuItem = styled(MenuItemWrapper)`
  background: ${Colors.OFF_WHITE};

  :hover {
    p {
      color: ${Colors.BLUE};
      font-weight: 600;
    }

    background: ${Colors.WHITE};
  }

  :active {
    p {
      color: ${Colors.BLUE};
      font-weight: 600;
    }
    background: ${Colors.WHITE};
  }
`;
