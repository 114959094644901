import React from 'react';
import * as UI from './styles';
import Typography from '../../Typography';
import menuItems from '../../../constants/menu';
import { Link } from 'react-router-dom';
import Button from '../../BaseButton';
import Colors from '../../../enums/Colors';
import { signOut } from '../../../services/user';
import Logo from '../../Logo';
import { ItemsContainer } from '..';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../store/modal';
import { useLocation } from 'react-router-dom';
import { getMenuRouteAction } from '../../../helpers/getMenuRouteAction';

const DesktopMenu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const openFeatureInDevModal = () => {
    dispatch(modalActions.open('featureInDev'));
  };

  const menu = menuItems.map((item, index) => {
    const action = getMenuRouteAction(item.route, location.pathname)

    return (
      <Link to={action} key={index}>
        <UI.StyledMenuItem onClick={!item.route ? openFeatureInDevModal : undefined}>
          <Typography type="p" color={Colors.BLACK}>
            {item.title}
          </Typography>
        </UI.StyledMenuItem>
      </Link>
    );
  })

  return (
    <UI.DesktopMenuBody>
      <UI.MenuHeader>
        <Logo logoType="medium" />
      </UI.MenuHeader>
      <ItemsContainer>
        {menu}
      </ItemsContainer>
      <Button caption="Log out" onClick={signOut} buttonType="transparent" />
    </UI.DesktopMenuBody>
  );
};
export default DesktopMenu;
