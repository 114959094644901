import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from '..';
import { sessionId } from '../../helpers/session';
import { SessionSymptom } from '../../helpers/sessionSymptom';
import { IOrgan } from '../../models/Organ';
import { IOrganSymptoms, ISessionSymptoms } from '../../models/Symptom';
import { fetchSessionSymptoms } from '../../services/session';

enum ACTIONS {
  ADD_SYMPTOM = 'ADD_SYMPTOM',
  DELETE_SYMPTOM = 'DELETE_SYMPTOM',
  SET_SYMPTOMS = 'SET_SYMPTOMS',
  RESET_SYMPTOMS = 'RESET_SYMPTOMS',
  LOAD_SYMPTOMS = 'LOAD_SYMPTOMS'
}

type IAction<Type, Payload> = {
  type: Type;
  payload: Payload;
};

type Action = 
  IAction<ACTIONS.ADD_SYMPTOM | ACTIONS.DELETE_SYMPTOM, SessionSymptom> | 
  IAction<ACTIONS.SET_SYMPTOMS, ISessionSymptoms[]> |
  IAction<ACTIONS.LOAD_SYMPTOMS, boolean> |
  IAction<ACTIONS.RESET_SYMPTOMS, undefined>;

interface ISymptomsState {
  sessionSymptoms?: ISessionSymptoms[];
  gender: string[];
  sent?: boolean;
}

const initialState: ISymptomsState = {
  gender: []
};

export const addSymptom = (symptomId: string, organ: IOrgan) => (dispatch: Function) => dispatch(
  { type: ACTIONS.ADD_SYMPTOM, payload: new SessionSymptom(symptomId, organ.id, organ.sex, organ.position) }
);

export const deleteSymptom = (symptomId: string, organ: IOrgan) => (dispatch: Function) => dispatch (
  { type: ACTIONS.DELETE_SYMPTOM, payload: new SessionSymptom(symptomId, organ.id, organ.sex, organ.position) }
);

export const getSessionSymptoms = () => async (dispatch: Function) => {
  fetchSessionSymptoms().then((response: IOrganSymptoms[]) => {
    const sessionSymptoms = response?.filter((organ) => {
      if (organ.symptoms?.length)
        return organ;
    }).map((organ) => ({
      organId: organ.id,
      symptoms: organ.symptoms?.map((symptom) => symptom.id),
      sessionId: sessionId(),
      sex: organ.sex,
      position: organ.position
    }));

    if (sessionSymptoms && sessionSymptoms.length) {
      dispatch({ type: ACTIONS.LOAD_SYMPTOMS, payload: true });
      dispatch({ type: ACTIONS.SET_SYMPTOMS, payload: sessionSymptoms});
    } else {  
      dispatch({ type: ACTIONS.LOAD_SYMPTOMS, payload: false });
      dispatch({ type: ACTIONS.SET_SYMPTOMS, payload: undefined});
    }
  });
};

export const resetSessionSymptoms = () => (dispatch: Function) => {
  dispatch({ type: ACTIONS.LOAD_SYMPTOMS, payload: false });
  dispatch({ type: ACTIONS.RESET_SYMPTOMS, payload: undefined });
};

const addOrgan = (state: ISymptomsState, symptom: SessionSymptom) => {
  const organSymptom = state.sessionSymptoms?.find((item) => item.organId === symptom.organId);

  if (organSymptom)
    return {sessionSymptoms: 
      state.sessionSymptoms?.map((item) => {
        if (item.organId === organSymptom.organId)
          item.symptoms.push(symptom.id)
        return item;
      })
    };
  
  const sessionSymptom: ISessionSymptoms = {
    organId: symptom.organId,
    symptoms: [symptom.id],
    sex: symptom.sex,
    sessionId: sessionId(),
    position: symptom.position
  };

  return {sessionSymptoms: [...(state.sessionSymptoms || []), sessionSymptom]};
};

const deleteOrgan = (state: ISymptomsState, symptom: SessionSymptom) => {
  const organSymptom = state.sessionSymptoms?.find((item) => item.organId === symptom.organId);

  if (organSymptom) {
    if (state.sent === false && organSymptom.symptoms.length === 1) 
      return {
        sessionSymptoms: state.sessionSymptoms?.filter((item) => item.organId !== symptom.organId)
      }

    if (organSymptom.symptoms.length) {
      return {sessionSymptoms:
        state.sessionSymptoms?.map((item) => {
          if (item.organId === organSymptom.organId)
            return {...item, symptoms: item.symptoms.filter(el => el !== symptom.id)};
          return item;
        })
      }
    }
  }

  return state;
};

const symptomsReducer = (
  state: ISymptomsState = initialState,
  action: Action
): ISymptomsState => {
  switch (action.type) {
    case ACTIONS.ADD_SYMPTOM:
      return {...state, ...addOrgan(state, action.payload)};
    case ACTIONS.DELETE_SYMPTOM:
      return {...state, ...deleteOrgan(state, action.payload)};
    case ACTIONS.LOAD_SYMPTOMS:
      return {...state, sent: action.payload};
    case ACTIONS.SET_SYMPTOMS:
      return {...state, sessionSymptoms: !!action.payload ? [...action.payload] : undefined};
    case ACTIONS.RESET_SYMPTOMS:
      return {...state, sessionSymptoms: action.payload};
    default:
      return state;
  }
};

export const defaultSelector = (state: IReduxState) => state.symptomsReducer;
export const symptomsSelector = (state: IReduxState) => state.symptomsReducer.sessionSymptoms;
export const symptomsSentSelector = (state: IReduxState) => state.symptomsReducer.sent;

export const allSymptomsSelector = createSelector(symptomsSelector, data => 
  data?.reduce<string[]>((acc, el) => [...acc, ...el.symptoms], []));
  
export const genderSwitchResetSelector = createSelector(symptomsSelector, data => {
  const selectedOrgansSex = data?.map((organ) => organ.sex);
  return selectedOrgansSex?.includes("m") || selectedOrgansSex?.includes("f");
});

export const noSymptomsSelector = createSelector(defaultSelector, data => {
  if (data.sent)
    return !data.sessionSymptoms?.filter((organ) => organ.symptoms.length > 0).length;
  return false;
});

export default symptomsReducer;
