import React from 'react';
import Typography from '../../components/Typography';
import * as UI from './styles';

interface IQuestionCaption {
  caption: string;
}

const QuestionCaption: React.FC<IQuestionCaption> = ({
  caption
}) => {
  return (
    <UI.CaptionWrapper>
      <Typography type='hint'>
        {caption}
      </Typography>
    </UI.CaptionWrapper>
  );
};

export default QuestionCaption;
