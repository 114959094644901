import styled, { css } from 'styled-components/macro';
import { ScreenWidthEnum } from '../../../../enums/screenWidth';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin: 16px 0 24px;
  width: 100%;
  align-self: center;

  ${(props) => [ScreenWidthEnum.phone, ScreenWidthEnum.tablet].some(el => el === props.theme.screen) && css`
    grid-template-columns: 1fr;
  `}
`;
