enum ColorsEnum {
  LIGHT_BLUE = "#BAEDE1",
  TRANSPARENT_BLUE = "#2D72B080",
  BLUE = "#2D72B0",
  LIGHT_TEAL = "#E2FFFA",
  TEAL = "#50EAC1",
  TRANSPARENT_TEAL = "#50EAC180",
  LIGHT_GRAY = "#DDDDDD",
  GRAY = "#55585E",
  GRAY_2 = "#C4C4C4",
  GRAY_3 = "#E2E5EC",
  GRAY_4 = "#B7BFCE",
  DARK_GRAY = "#B7B7B7",
  BLACK = "#000000",
  OFF_WHITE = "#EFF1F5",
  WHITE = "#FFFFFF",
  BEIGE = "#FFF4E2",
  PINK = "#FFCACA",
  DARK_PINK = "#FF7878",
  RED_ERROR = "#CC2929",
  GREEN_SUCCESS = "#006644",
  TRANSPARENT = "#FFFFFF00",
  DISABLE = "#D3D7DB",
  GREEN = "#00B283",
  TRANSPARENT_BLACK = "#00000030",
  CHECKBOX_ACTIVE = "#2E75B5"
};

export default ColorsEnum;
