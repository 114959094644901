import { getRequest } from "../../api"
import { sessionId } from "../../helpers/session";
import { IDiagnosis } from "../../models/Disease";

export const fetchBaseDiseases = (): Promise<IDiagnosis[]> => {
  return getRequest(`/disease/define/base/?sessionId=${sessionId()}`);
};

export const fetchPrimaryDiseases = (): Promise<IDiagnosis[]> => {
  return getRequest(`/disease/define/primary/?sessionId=${sessionId()}`);
};
