import React from 'react';
import * as UI from './styles';
import Typography from '../Typography';
import success from '../../assets/icons/success.svg';
import error from '../../assets/icons/error.svg';
import close from '../../assets/icons/closeBlack.svg';
import ColorsEnum from '../../enums/Colors';
import { AlertType } from '../../models/Notifications';

interface IAlert {
  selected?: boolean;
  caption: string;
  type?: AlertType;
  handleClose?: () => void;
}

const Alert: React.FC<IAlert> = ({
  caption,
  type = "success",
  handleClose,
  ...props
}) => (
  <UI.AlertWrapper type={type} {...props}>
    <img src={type === "success" ? success : error} alt={type} />
    <Typography type="p" color={type === "success" ? ColorsEnum.GREEN_SUCCESS : ColorsEnum.RED_ERROR}>{caption}</Typography>
    <img src={close} onClick={handleClose} alt="close" />
  </UI.AlertWrapper>
);

export default Alert;
