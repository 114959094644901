import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../../components/BaseButton';
import { ICheckboxItem } from '../../../../components/CheckboxList/ListItem';
import RoutesEnum from '../../../../enums/Routes';
import {
  medicalTestActions,
  medicalTestSelectors,
  MedicalTestStepEnum,
} from '../../../../store/medicalTest';
import * as RootUI from '../styles';
import * as UI from './styles';

const SelectTests: React.VFC = () => {
  const options = useSelector(medicalTestSelectors.tests);
  const selectedTests = useSelector(medicalTestSelectors.selectedTests);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (item?: ICheckboxItem) => {
    if (item) dispatch(medicalTestActions.checkTest(item));
  };

  const handleProceed = () => {
    if (selectedTests.length) dispatch(medicalTestActions.setStep(MedicalTestStepEnum.LOAD_DATA));
    else history.push(RoutesEnum.TREATMENT);
  };

  return (
    <RootUI.Wrapper>
      <UI.StyledMedicalTestHeader
        pageTitle="Medical Test"
        description="Select the analysis you are interested in"
        backRoute={RoutesEnum.CORRECTIONS}
      />
      <UI.Content>
        <UI.StyledCheckboxList type="white" options={options} onClick={handleClick} radio />
        <UI.Control>
          <Button buttonType="filled" caption="Proceed" width="100%" onClick={handleProceed} />
        </UI.Control>
      </UI.Content>
    </RootUI.Wrapper>
  );
};

export default SelectTests;
