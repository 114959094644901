import React from 'react';
import Button from '../../../../components/BaseButton';
import * as UI from './styles';
import * as RootUI from '../styles';
import ResultFrame from './ResultFrame';
import { RESULT } from './results';
import { v4 } from 'uuid';
import HeaderWithTitle from '../../../../components/HeaderWithTitle';
import RoutesEnum from '../../../../enums/Routes';
import {useHistory} from 'react-router';

const Results: React.VFC = () => {
  const history = useHistory()
  const testResults = RESULT.map((el) => (
    <ResultFrame key={v4()} date={el.date} testsList={el.testsList} />
  ));

  const handleProceed = () => {
    history.push(RoutesEnum.TREATMENT);
  };

  return (
    <RootUI.Wrapper>
      <HeaderWithTitle
        backRoute={RoutesEnum.CORRECTIONS}
        pageTitle="Decryption results"
        description="All your decrypted tests are stored here"
      />
      <RootUI.Content>
        <UI.ListWrapper>{testResults}</UI.ListWrapper>
        <RootUI.Control>
          <Button buttonType="filled" caption="Proceed" onClick={handleProceed} />
        </RootUI.Control>
      </RootUI.Content>
    </RootUI.Wrapper>
  );
};

export default Results;
