import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Routes from '../../../enums/Routes';
import Layout from '../../../layout/App';
import Corrections from '../../../pages/App/Corrections';
import MedicalTest from '../../../pages/App/MedicalTest';
import Patient from '../../../pages/Menu/Patient';
import SearchDoctor from '../../../pages/Menu/SearchDoctor';
import LoadingScreen from '../../../pages/Service/LoadingScreen';
import Symptoms from '../../../pages/App/Symptoms';
import Treatment from '../../../pages/App/Treatment';
import * as UI from './styles'

const AppSwitch: React.FC = () => {
  return (
    <Switch>
      <Layout>
        <UI.AppStyles />
        <Route path={Routes.PATIENT} component={Patient} />
        <Route path={Routes.SEARCH} component={SearchDoctor} />
        <Route path={Routes.SYMPTOMS} component={Symptoms} />
        <Route path={Routes.CORRECTIONS} component={Corrections} />
        <Route path={Routes.TEST} component={MedicalTest} />
        <Route path={Routes.TREATMENT} component={Treatment} />
      </Layout>
      <Route path={Routes.DEFAULT} component={LoadingScreen} />
    </Switch>
  );
};

export default AppSwitch;
