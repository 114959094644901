import React from 'react';
import * as UI from './styles';
import DropdownContent from '../../../../../components/DropdownContent';
import ButtonItem from '../../../../../components/ButtonItem';
import { v4 } from 'uuid';

export interface IDropdown {
  title: string;
  action: () => void;
}

interface IProps {
  title: string;
  dropdownButtons: IDropdown[];
}

const ResultDropdown: React.FC<IProps> = ({ title, dropdownButtons }) => {
  const buttons = dropdownButtons.map((el) => (
    <ButtonItem caption={el.title} onClick={el.action} key={v4()} />
  ));

  return (
    <>
      <DropdownContent caption={title}>
        <UI.ButtonListWrapper>{buttons}</UI.ButtonListWrapper>
      </DropdownContent>
    </>
  );
};

export default ResultDropdown;
