import styled, { css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';

interface IInputBase {
  error?: boolean;
}

interface IStyledInput extends IInputBase {
  isPassword?: boolean;
}

interface IPasswordToggle {
  dark?: boolean;
}

export const InputWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.BLACK};

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    font-size: 20px;
    line-height: 24px;
  `}
`;

export const StyledInput = styled.input<IStyledInput>`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.error ? Colors.RED_ERROR : Colors.LIGHT_GRAY};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 16px;
  color: ${Colors.BLACK};
  outline: none;

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    font-size: 20px;
    line-height: 24px;
    height: 48px;
  `}

  ${props => props.isPassword && css`
    padding-right: 36px;
  `}
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const PasswordToggle = styled.img<IPasswordToggle>`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  ${props => props.dark && css`
    filter: brightness(0.6);
  `}
`;
