import React, { useEffect, useRef, useState } from 'react';
import useComponentVisible from '../../hooks/useComponentVisible';
import Button from '../BaseButton';
import CloseButton from '../CloseButton';
import { ModalBody, ModalHeader, ModalWrapper } from '../Modal';
import Typography from '../Typography';
import * as UI from './styles';
import { ReactComponent as Rotate } from '../../assets/icons/rotate.svg';
import { ReactComponent as Flip } from '../../assets/icons/flip.svg';
import { Cropper } from 'react-cropper';

interface IProps {
  handleVisibility?: (visible: boolean) => void;
  isVisible: boolean;
  image: string;
  onApply: (url: string) => void;
  onClose: () => void;
}

const EditImageModal: React.VFC<IProps> = ({ handleVisibility, isVisible, image, onApply, onClose }) => {
  const { ref, isComponentVisible, setComponentVisible, handleChangeComponentVisibility } =
    useComponentVisible(isVisible);
  const [cropper, setCropper] = useState<Cropper>();

  useEffect(() => {
    setComponentVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    handleVisibility && handleVisibility(isComponentVisible);
  }, [isComponentVisible]);

  const handleClose = () => {
    setComponentVisible(false);
    onClose()
  };

  const handleRotate = (deg: number) => {
    cropper?.rotate(deg);
  };
  const handleFlip = (move: 'horizontal' | 'vertical') => {
    const data = cropper?.getImageData();
    if (!data) return;
    switch (move) {
      case 'horizontal':
        cropper?.scaleX(data.scaleX > 0 ? -1 : 1);
        break;
      case 'vertical':
        cropper?.scaleY(data.scaleY > 0 ? -1 : 1);
        break;
    }
  };

  const handleCrop = () => {
    if (!cropper) return;

    const url = cropper.getCroppedCanvas().toDataURL();
    onApply(url);
  };
  const handleReset = () => {
    cropper?.reset();
  };
  const initCropper = (instance: Cropper) => {
    setCropper(instance);
    instance.reset()
  }

  return (
    <ModalWrapper>
      <ModalBody ref={ref}>
        <ModalHeader>
          <Typography type="h2">Rotate and crop an image</Typography>
          <CloseButton onClick={handleClose} />
        </ModalHeader>
        <UI.Content>
          <Cropper
            style={{ height: 200, width: '100%' }}
            src={image}
            viewMode={1}
            background={false}
            responsive={false}
            autoCropArea={0}
            checkOrientation={false}
            onInitialized={initCropper}
            guides={true}
          />
          <UI.Actions>
            <Button buttonType="transparent" onClick={() => handleRotate(90)}>
              <Rotate />
            </Button>
            <Button buttonType="transparent" onClick={() => handleRotate(-90)}>
              <Rotate transform="scale(-1,1)" />
            </Button>
            <Button buttonType="transparent" onClick={() => handleFlip('vertical')}>
              <Flip />
            </Button>
            <Button buttonType="transparent" onClick={() => handleFlip('horizontal')}>
              <Flip transform="rotate(-90)" />
            </Button>
          </UI.Actions>
        </UI.Content>

        <UI.Buttons>
          <Button buttonType="transparent" caption="Reset" onClick={handleReset} />
          <Button buttonType="filled" caption="Apply" onClick={handleCrop} />
        </UI.Buttons>
      </ModalBody>
    </ModalWrapper>
  );
};

export default EditImageModal;
