import React, { useMemo } from 'react';
import Typography from '../../Typography';
import MenuItem from '../../MenuItem';
import Checkbox from '../../Checkbox';
import { CheckboxType } from '../../Checkbox/styles';

export type ItemType = "blue" | "gray" | "white" | CheckboxType;
const checkboxType = {
  blue: 'checkbox',
  gray: 'list',
  white: 'whitelist'
} as const

export interface ICheckboxItem {
  id: string;
  title?: string;
  image?: string;
  checked: boolean;
}

interface IListItem {
  item: ICheckboxItem;
  type: ItemType;
  onClick: () => void;
}

const ListItem: React.FC<IListItem> = ({
  item,
  type,
  onClick
}) => {
  const listItem = useMemo(() => {
    if (type === "blue" || type === "gray" || type === 'white')
      return (
        <MenuItem
          type={checkboxType[type]}
          selected={item.checked}
          caption={item.title}
          onClick={onClick}
          key={item.id} />
      );

    return (
      <Checkbox checked={item.checked} onClick={onClick} key={item.id} type={type}>
        {type === "image" ?
          <img src={item.image} /> :
          <Typography type="h2" lineHeight={14}>
            {item.title}
          </Typography>}
      </Checkbox>
    );
  }, [type, item, onClick]);

  return (
    <>
      {listItem}
    </>
  );
};

export default ListItem;
