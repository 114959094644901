import React, { useState, useMemo, useEffect } from 'react';
import * as UI from './styles';
import Avatar from '../../../components/Avatar';
import { View, Gender } from '../../../constants/avatar';
import ComplaintsModal from './ComplaintsModal';
import rotate from '../../../assets/icons/rotate.png';
import genderSwitch from '../../../assets/icons/genderSwitch.png';
import SwitchGenderModal from './SwitchGenderModal';
import { useHistory } from 'react-router';
import Routes from '../../../enums/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecializationBodyparts} from '../../../services/specialization';
import { fetchSessionSpecialization, saveSessionSymptoms, sendSessionSpecialization } from '../../../services/session';
import { fetchOrgan, fetchPositionSymptoms, fetchSpecializationSymptoms } from '../../../services/organs';
import { IOrganSymptoms } from '../../../models/Symptom';
import { allSymptomsSelector, genderSwitchResetSelector, getSessionSymptoms, resetSessionSymptoms, symptomsSelector } from '../../../store/symptoms';
import { BODY } from '../../../constants/organs';
import { gender, mainMenuPath, newSession, setGender } from '../../../helpers/session';
import ISpecialization from '../../../models/Specialization';
import { GYNECOLOGIST } from '../../../constants/specializations';
import SymptomsHeader from './SymptomsHeader';
import { screenSelectors } from '../../../store/screen';
import HeaderWithButton from '../../../components/HeaderWithButton';
import OrgansList from './OrgansList';
import SymptomsList from './SymptomsList';
import { ScreenWidthEnum } from '../../../enums/screenWidth';
import AddGeneralComplaints from './AddGeneralComplaints';

enum ModalNameEnum {
  GENERAL_COMPLAINTS = 'General complaints'
}

const Symptoms: React.FC = () => {
  const [preview, setPreview] = useState(false);
  const [complaintsModal, setComplaintsModal] = useState('');
  const [genderModal, setGenderModal] = useState(false);
  const [view, setView] = useState<View>('front');
  const [parts, setParts] = useState<string[]>([]);
  const [specialization, setSpecialization] = useState<ISpecialization>();
  const [organs, setOrgans] = useState<IOrganSymptoms[]>();
  const [modalOptions, setModalOptions] = useState<IOrganSymptoms[]>([]);
  const [bodySymptoms, setBodySymptoms] = useState<IOrganSymptoms[]>([]);
  const [organSymptoms, setOrganSymptoms] = useState<IOrganSymptoms[]>([]);
  const [avatarGender, setAvatarGender] = useState(gender());
  const [genderDisabled, setGenderDisabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionSymptoms = useSelector(symptomsSelector);
  const allSymptoms = useSelector(allSymptomsSelector);
  const genderSwitchReset = useSelector(genderSwitchResetSelector);
  const screenWidth = useSelector(screenSelectors.width);
  const oppositeGender = avatarGender === 'm' ? 'f' : 'm';
  const specializationView = specialization?.send === false
  const isGenecologist = specialization?.title === GYNECOLOGIST

  useEffect(() => {
    fetchSessionSpecialization().then((response) => {
      setSpecialization(response);
      fetchOrgan(BODY).then((response) => {
        setBodySymptoms([response]);
      });
    });
  }, []);

  useEffect(() => {
    if (specialization?.send !== false) {
      const selectedOrgans = sessionSymptoms?.filter((organ) => organ.symptoms.length).map((organ) => organ.position) || [];

      setGenderDisabled(false)
      if (organs?.length)
        setParts([...selectedOrgans, organs[0].position]);
      if (!preview)
        setParts([...selectedOrgans]);
    }
  }, [sessionSymptoms, specialization, preview, organs]);

  useEffect(() => {
    (async () => {
      if (!!specialization) {
        setPreview(!specialization.send);

        if (isGenecologist) {
          setGender('f');
          setAvatarGender(gender());
        }
        setGenderDisabled(isGenecologist)

        const response = await fetchSpecializationBodyparts(specialization.id)

        if (!specialization.send) {
          const positions = [...new Set(response.filter(el => el.position).map(el => el.position))]
          response.length && setParts(positions)
        }
        setOrganSymptoms(response)
      }
    })()
  }, [specialization, avatarGender]);

  useEffect(() => {
    if (complaintsModal === ModalNameEnum.GENERAL_COMPLAINTS) {
      setModalOptions(bodySymptoms)
    }
  }, [bodySymptoms])

  const handleAddButton = () => {
    setModalOptions(bodySymptoms);
    setComplaintsModal(ModalNameEnum.GENERAL_COMPLAINTS);
  };

  const handleProceedClick = () => {
    if (sessionSymptoms && sessionSymptoms.length)
      saveSessionSymptoms(sessionSymptoms).then(() => {
        dispatch(getSessionSymptoms());
        history.push(Routes.CORRECTIONS);
      });
  };

  const handleComplaintsVisibility = (visible: boolean) =>
    !visible && setComplaintsModal('');
  
  const handleGenderVisibility = (visible: boolean) => 
    setGenderModal(visible);

  const handleGenderSwitch = () => {
    dispatch(resetSessionSymptoms());
    newSession(oppositeGender);
    if (specialization) sendSessionSpecialization(specialization.id);
    if (preview) setPreview(false);
    setAvatarGender(gender());
  };
    
  const handleGenderButton = () => {
    if (genderDisabled) return
    if (genderSwitchReset)
      setGenderModal(true);
    else {
      if (!specialization) {
        setPreview(false)
        setOrganSymptoms([])
        setOrgans([])
      }
      dispatch(resetSessionSymptoms())
      setGender(oppositeGender);
      setAvatarGender(gender());
    }
  };
  
  const handleModal = (organSymptoms: IOrganSymptoms) => {
    setModalOptions([organSymptoms]);
    setComplaintsModal(organSymptoms.title);
  };

  const handleRotation = () =>
    setView(view === 'front' ? 'back' : 'front');

  const handleBodyPartClick = (position: string, gender: Gender) => {
    if (specialization?.send === false) return;

    if (specialization) {
      if (!organSymptoms.length) return
      setOrgans(organSymptoms?.filter((item) => item.position === position && item.sex !== oppositeGender));
      setPreview(true);
    } else {
      fetchPositionSymptoms(position, gender).then((response) => {
        setOrganSymptoms(response);
        setOrgans(response);
        setPreview(true);
      });
    }
  };

  const hidePreview = () =>
    setPreview(false);

  const handleBackClick = preview && specialization?.send !== false ? hidePreview : undefined;

  const layout = useMemo(() => {
    const specializationTitle = specializationView ? specialization?.title : (preview && parts?.length && parts[parts.length - 1]) || specialization?.title;
    const bodySymtoms = sessionSymptoms?.find(el => el.organId === BODY);
    return (
      <>
        <UI.Container>
          <UI.Row preview={preview} isSpecializationView={specializationView}>
            <SymptomsHeader
              preview={preview}
              title={specializationTitle}
              isSpecializationView={!!specialization?.title}
            />
            {
              (!(preview && screenWidth <= ScreenWidthEnum.tablet) || specializationView) &&
              <AddGeneralComplaints 
                handleAddButton={handleAddButton} 
                specializationView={specializationView} 
                bodySymtomsLength={bodySymtoms?.symptoms?.length} 
              />
            }
            {
              preview && 
              <UI.SymptomsListContainer isSpecializationView={specializationView}>
                {specializationView ? 
                  (<SymptomsList
                    isSpecialization
                    specializationId={specialization?.id}
                    organSymptoms={organSymptoms}
                  />) :
                  (<OrgansList 
                    organs={organs}
                    handleSymptomsModal={handleModal}
                  />)
                }
              </UI.SymptomsListContainer>
            }
          </UI.Row>

          <UI.BottomButtons>
            <UI.ImgButtonsWrapper>
              <UI.GenderSwitch disabled={genderDisabled} src={genderSwitch} onClick={handleGenderButton} />
              <img src={rotate} onClick={handleRotation} />
            </UI.ImgButtonsWrapper>
            <UI.ProceedButton
              buttonType='filled'
              caption='Proceed'
              disabled={!sessionSymptoms?.length}
              onClick={handleProceedClick} 
              counter={allSymptoms?.length}
            />
          </UI.BottomButtons>
          <Avatar
            gender={avatarGender}
            view={view}
            preview={preview}
            parts={parts}
            onClick={handleBodyPartClick}
          />
        </UI.Container>
      </>
    );
  }, [specialization, preview, organs, view, organSymptoms, sessionSymptoms, parts, bodySymptoms, screenWidth, avatarGender, specializationView]);

  return (
    <>
      <HeaderWithButton
        backRoute={mainMenuPath()}
        onButtonClick={handleBackClick}
      />
      {layout}
      <ComplaintsModal
        title={complaintsModal}
        visible={!!complaintsModal}
        handleVisibility={handleComplaintsVisibility}
        options={modalOptions}
        handleProceed={handleProceedClick}
        specializationId={specialization?.id}
      />
      {genderModal && 
        <SwitchGenderModal
          visible={genderModal}
          handleVisibility={handleGenderVisibility}
          handleGenderSwitch={handleGenderSwitch}
        />
      }
    </>
  );
};

export default Symptoms;
