import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import Colors from '../../enums/Colors';
import { ScreenWidthEnum } from '../../enums/screenWidth';
import Typography from '../Typography';

interface INavItem {
  isCurrentPath: boolean;
  disabled?: boolean;
}

export const NavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  background-color: ${Colors.WHITE};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
  z-index: 50;
  width: 100%;

  ${(props) => [ScreenWidthEnum.phone, ScreenWidthEnum.desktop].some(el => el === props.theme.screen) && css`
    min-height: 88px;
    height: 88px;
  `}

  ${(props) => props.theme.screen === ScreenWidthEnum.landscapePhone && css`
    min-height: 56px;
    height: 56px;
  `}

  ${props => props.theme.screen >= ScreenWidthEnum.laptop && css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1024px;
  `}

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    max-width: 1240px;
  `}
`;

export const NavItem = styled(Link)<INavItem>`
  display: flex;
  flex-flow: column;
  text-decoration: none;
  align-items: center;

  ${props => props.theme.screen > ScreenWidthEnum.phone && css`
    flex-flow: row;
  `}

  p {
    font-weight: ${props => props.isCurrentPath ? '700' : '500'};
    margin-top: 8px;

    ${props => props.theme.screen <= ScreenWidthEnum.landscapePhone && css`
      font-size: 11px;
      line-height: 12px;
    `}

    ${props => props.theme.screen > ScreenWidthEnum.phone && css`
      margin-left: 8px;
      margin-top: 0;
    `}
  }
`;

export const NavItemsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px 0;
  margin: auto;
`;

export const NavIconWrapper = styled.div<INavItem>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${props => props.disabled ? Colors.LIGHT_GRAY : Colors.TRANSPARENT};
  border: 2px solid ${props => props.isCurrentPath || !props.disabled ? Colors.BLUE : Colors.LIGHT_GRAY};

  ${(props) => props.isCurrentPath && css`
    background-color: ${Colors.BLUE};
  `}

  svg {
    fill: ${props => props.disabled || props.isCurrentPath ? Colors.WHITE : Colors.BLUE};
    width: 24px;
    height: 24px;

    ${(props) => props.theme.screen === ScreenWidthEnum.desktop && css`
      width: 28px;
      height: 28px;
    `}
  }

  ${props => props.theme.screen === ScreenWidthEnum.desktop && css`
    width: 48px;
    height: 48px;
  `}
`;

export const Title = styled(Typography)`
  text-align: center;
`