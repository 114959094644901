import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { IReduxState } from '..';
import { IUploadFile } from '../../models/UploadFiles';
import { ICheckboxItem } from './../../components/CheckboxList/ListItem';

interface ITest extends ICheckboxItem {
  completed: boolean;
  files: IUploadFile[];
}

export enum UploadMode {
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  ALL = "ALL"
}
interface IMedicalTest {
  tests: ITest[];
  currentStep: MedicalTestStepEnum;
  mode: UploadMode | null
}

export enum MedicalTestStepEnum {
  SELECT_TESTS,
  LOAD_DATA,
  CONFIRM_FILES,
  RESULT_PROCESSING,
  WAITING_RESULTS,
  RESULTS,
}

const initialState: IMedicalTest = {
  currentStep: MedicalTestStepEnum.SELECT_TESTS,
  tests: [
    {
      checked: false,
      id: '1',
      title: 'Blood test',
      completed: false,
      files: [],
    },
    {
      checked: false,
      id: '2',
      title: 'Urine test',
      completed: false,
      files: [],
    },
    {
      checked: false,
      id: '3',
      title: 'Cardiogram',
      completed: false,
      files: [],
    },
  ],
  mode: null
};

export const medicalTestActions = {
  checkTest: createAction<ICheckboxItem>('MEDICAL_TEST/CHECK_TEST'),
  setStep: createAction<MedicalTestStepEnum>('MEDICAL_TEST/SET_STEP'),
  resetTests: createAction('MEDICAL_TEST/RESET_TESTS'),
  addFile: createAction<Omit<IUploadFile, 'id'> & { testId: string }>('MEDICAL_TEST/ADD_IMAGE'),
  removeFile: createAction<{ testId: string; id: string }>('MEDICAL_TEST/REMOVE_IMAGE'),
  completeTest: createAction<ITest>('MEDICAL_TEST/COMPLETE_TEST'),
  setMode: createAction<UploadMode | null>('MEDICAL_TEST/SET_MODE')
};

const medicalTestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(medicalTestActions.checkTest, (state, action) => {
      state.tests = state.tests.map((el) => ({
        ...el,
        checked: el.id === action.payload.id
      }));
    })
    .addCase(medicalTestActions.setStep, (state, action) => {
      state.currentStep = action.payload;
    })
    .addCase(medicalTestActions.resetTests, () => {
      return initialState;
    })
    .addCase(medicalTestActions.addFile, (state, action) => {
      const { testId, ...image } = action.payload;
      state.tests.find((el) => el.id === testId)?.files.push({ ...image, id: v4() });
    })
    .addCase(medicalTestActions.removeFile, (state, action) => {
      const { testId, id } = action.payload;
      const selectedTest = state.tests.find((el) => el.id === testId);
      if (selectedTest) {
        const fileId = selectedTest?.files.findIndex((el) => el.id === id);
        if (fileId > -1) selectedTest.files.splice(fileId, 1);
      }
    })
    .addCase(medicalTestActions.completeTest, (state, action) => {
      const test = state.tests.find(el => el.id === action.payload.id)
      if (test) test.completed = true
    })
    .addCase(medicalTestActions.setMode, (state, action) => {
      state.mode = action.payload
    })
});

const defaultSelector = (state: IReduxState) => state.medicalTestReducer;

export const medicalTestSelectors = {
  tests: createSelector(defaultSelector, (state) => state.tests),
  currentStep: createSelector(defaultSelector, (state) => state.currentStep),
  selectedTests: createSelector(defaultSelector, (state) =>
    state.tests.filter((el) => el.checked && !el.completed),
  ),
  get currentTest() {
    return createSelector(this.selectedTests, (state) => (state.length ? state[0] : undefined));
  },
  mode: createSelector(defaultSelector, state => state.mode)
};

export default medicalTestReducer;
