import React from 'react';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../store/modal';
import Button from '../BaseButton';
import CloseButton from '../CloseButton';
import { ModalBody, ModalHeader, ModalWrapper } from '../Modal';
import Typography from '../Typography';

const FeatureInDevelopmentModal: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(modalActions.close('featureInDev'));
  };

  return (
    <ModalWrapper>
      <ModalBody>
        <ModalHeader>
          <Typography type="h2">Feature in development</Typography>
          <CloseButton onClick={handleClose} />
        </ModalHeader>
        <div>
          <Typography type="p">
            Feature in development...
          </Typography>
        </div>
        <Button buttonType="filled" caption="Close" onClick={handleClose} />
      </ModalBody>
    </ModalWrapper>
  );
};

export default FeatureInDevelopmentModal;
