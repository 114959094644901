import styled from 'styled-components';
import ColorsEnum from '../../enums/Colors';
import Typography from '../Typography';

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  > svg {
    margin-left: 6px;
    margin-right: 12px;
    min-width: 22px;
  }
`;

export const FileDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const FileName = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${ColorsEnum.BLACK};
`;

export const Wrapper = styled.div`
  > * + * {
    margin-top: 8px;
  }
`