import React from 'react';
import { ModalWrapper, ModalBody, ModalHeader } from '../../../../components/Modal';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/BaseButton';
import { ModalContentWrapper } from './styles';
import terms from '../../../../constants/terms';

interface ITermsModal {
  handleVisibility: () => void;
}

const TermsModal: React.FC<ITermsModal> = ({
  handleVisibility
}) => (
  <ModalWrapper>
    <ModalBody>
      <ModalHeader>
        <Typography type="h2">Terms and Conditions</Typography>
      </ModalHeader>
      <ModalContentWrapper>
        <Typography type="p">
          {terms}
        </Typography>
        <Button
          onClick={handleVisibility}
          buttonType="filled"
          caption="I agree"
          width="110px"
        />
      </ModalContentWrapper>
    </ModalBody>
  </ModalWrapper>
);

export default TermsModal;
