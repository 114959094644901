import React, { useMemo } from 'react';
import * as UI from './styles';
import Colors from '../../enums/Colors';
import shadow from '../../assets/icons/body/shadow.svg';
import bodyParts, { backImage, Gender, View } from '../../constants/avatar';
import { getBodypartColor } from '../../helpers/color';
import BodyPart from './BodyPart';

interface IAvatar {
  parts?: string[];
  onClick: (position: string, gender: Gender) => void;
  gender?: Gender;
  view?: View;
  preview?: boolean;
}

const Avatar: React.FC<IAvatar> = ({
  onClick,
  parts,
  gender = "m",
  view = "front",
  preview
}) => {
  const body = useMemo(() => {
    const strokeColors = Colors.DARK_GRAY;
    const clickHandler = (position: string, gender: Gender) =>
      onClick(position, gender);

    return bodyParts.filter((item) => item.view === view && item.gender === gender)
      .map((item) => (
        <BodyPart
          key={item.id}
          item={item}
          selected={parts?.includes(item.title)}
          onClick={() => clickHandler(item.title, item.gender)}
          fill={getBodypartColor(parts?.includes(item.title), Colors.TRANSPARENT)}
          stroke = {getBodypartColor(parts?.includes(item.title), strokeColors)} />
        )
      );
  }, [gender, view, preview, parts, onClick]);

  return (
    <UI.AvatarWrapper gender={gender} view={view} preview={preview}>
      <img src={backImage[gender][view]} />
      <UI.BodyWrapper gender={gender} view={view} preview={preview}>
        {body}
      </UI.BodyWrapper>
      <img src={shadow} />
    </UI.AvatarWrapper>
  );
};

export default Avatar;
