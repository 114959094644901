export enum ScreenWidthEnum {
  phone,
  landscapePhone,
  tablet,
  laptop,
  desktop,
}

export enum ScreenBreakPointEnum {
  phone = 600,
  tablet = 1280,
  laptop = 1600,
}
