import styled from 'styled-components';

export const Wrapper = styled.div`
  > p {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
export const Tests = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;
