import styled, { css } from 'styled-components';
import CheckboxList from '../../../../components/CheckboxList';
import HeaderWithTitle from '../../../../components/HeaderWithTitle';
import { ScreenWidthEnum } from '../../../../enums/screenWidth';
import * as RootUI from '../styles';

export const Control = styled(RootUI.Control)`
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.landscapePhone &&
    css`
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 100px;
      button {
        width: 50%;
      }
    `}
`;
export const Content = styled(RootUI.Content)`
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.landscapePhone &&
    css`
      gisplay: flex;
      max-width: 100%;
      flex-direction: column;
      align-items: flex-end;
    `}
`;
export const StyledCheckboxList = styled(CheckboxList)`
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.landscapePhone &&
    css`
      width: 50%;
      float: right;
      margin-top: -56px;
    `}
`;

export const StyledMedicalTestHeader = styled(HeaderWithTitle)`
  ${(props) =>
    props.theme.screen === ScreenWidthEnum.landscapePhone &&
    css`
      > p {
        text-align: left;
        width: 40%;
      }
    `}
`;
