import React from 'react';
import Colors from '../../../../enums/Colors';
import * as UI from './styles';
import ImageLink from '../../../../components/ImageLink';
import byName from '../../../../assets/icons/byName.svg';
import bySymptome from '../../../../assets/icons/bySymptome.svg';
import Routes from '../../../../enums/Routes';

const FindDoctor: React.FC = () => (
  <UI.Wrapper>
    <ImageLink
      caption='By symptome'
      image={bySymptome}
      color={Colors.LIGHT_TEAL}
      route={Routes.SYMPTOMS}
    />
    <ImageLink
      caption='By name'
      image={byName}
      color={Colors.BEIGE}
      route={Routes.SEARCH}
    />
  </UI.Wrapper>
);

export default FindDoctor;
