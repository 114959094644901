import React from 'react';
import { useHistory } from 'react-router';
import RoutesEnum from '../../enums/Routes';
import * as UI from './styles';

interface IBackButton {
  onClick?: () => void;
  backRoute?: RoutesEnum;
  caption?: string;
  disabled?: boolean;
}

const BackButton: React.VFC<IBackButton> = ({
  onClick,
  backRoute,
  caption = 'Back',
  disabled
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (onClick) return onClick()
    if (backRoute) return history.push(backRoute)
    if (history.action !== "POP") return history.goBack()
  }

  return (
    <UI.BackButton
      buttonType="back"
      caption={caption}
      onClick={handleClick}
      disabled={disabled}
      width='104px'
    />
  );
};

export default BackButton