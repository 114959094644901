import React from 'react';
import * as UI from './styles';
import Typography from '../Typography';
import Colors from '../../enums/Colors';
import checkmarkBlack from '../../assets/icons/checkmark.svg';
import checkmarkBlue from '../../assets/icons/checkmarkBlue.svg';
import ButtonCounter from '../ButtonCounter';

export type MenuItemType = 'menu' | 'list' | 'checkbox' | 'whitelist';

interface IMenuItem {
  selected?: boolean;
  count?: number;
  caption?: string;
  type?: MenuItemType;
  onClick?: () => void;
  CustomMark?: React.FC;
  noBoxShadow?: boolean;
}

const MenuItem: React.FC<IMenuItem> = ({
  selected,
  caption,
  type = 'menu',
  onClick,
  count,
  CustomMark,
  children,
  noBoxShadow,
  ...props
}) => (
  <UI.MenuItemWrapper
    selected={selected}
    type={type}
    onClick={onClick} 
    boxShadow={!noBoxShadow}
    {...props}
  >
    {children || (
      <Typography type="p" color={type === 'checkbox' && selected ? Colors.BLUE : Colors.BLACK}>
        {caption}
      </Typography>
    )}
    {CustomMark && <CustomMark />}
    {selected &&
      (count ? (
        <UI.Counter>
          <ButtonCounter>{count}</ButtonCounter>
        </UI.Counter>
      ) : (
        <img src={type === 'checkbox' ? checkmarkBlue : checkmarkBlack} />
      ))}
  </UI.MenuItemWrapper>
);

export default MenuItem;
