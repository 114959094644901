import styled from 'styled-components/macro';
import { VIEWPORT_HEIGHT } from '../../../../constants/viewport';
export const DropdownsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 20px;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & > :first-child {
    margin-bottom: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  row-gap: 24px;
  align-self: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: calc((110 / ${VIEWPORT_HEIGHT}) * 100vh);
`;

export const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 9px;
`;

export const ListWrapper = styled.div`
  overflow-y: auto;
  > * + * {
    margin-top: 8px;
  }
`;
