import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScreenWidthEnum } from '../../../enums/screenWidth';
import {
  medicalTestActions,
  medicalTestSelectors,
  MedicalTestStepEnum,
  UploadMode,
} from '../../../store/medicalTest';
import { screenSelectors } from '../../../store/screen';
import ConfirmFiles from './ConfirmFiles';
import LoadData from './LoadData';
import ResultProcessing from './ResultProcessing';
import Results from './Results';
import SelectTests from './SelectTests';
import WaitingResults from './WaitResults';

const MedicalTest: React.FC = () => {
  const currentStep = useSelector(medicalTestSelectors.currentStep);
  const screen = useSelector(screenSelectors.width);
  const dispatch = useDispatch();

  const [component, setComponent] = useState<JSX.Element>();

  useEffect(() => {
    if (screen >= ScreenWidthEnum.laptop) dispatch(medicalTestActions.setMode(UploadMode.ALL));
  }, [screen]);

  useEffect(() => {
    if (currentStep === MedicalTestStepEnum.SELECT_TESTS) setComponent(<SelectTests />);
    else if (currentStep === MedicalTestStepEnum.LOAD_DATA) setComponent(<LoadData />);
    else if (currentStep === MedicalTestStepEnum.CONFIRM_FILES) setComponent(<ConfirmFiles />);
    else if (currentStep === MedicalTestStepEnum.RESULT_PROCESSING)
      setComponent(<ResultProcessing />);
    else if (currentStep === MedicalTestStepEnum.WAITING_RESULTS) setComponent(<WaitingResults />);
    else if (currentStep === MedicalTestStepEnum.RESULTS) setComponent(<Results />);
  }, [currentStep]);

  return <>{component}</>;
};

export default MedicalTest;
