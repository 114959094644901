import React from 'react';
import { useSelector } from 'react-redux';
import ColorsEnum from '../../../../enums/Colors';
import { ScreenWidthEnum } from '../../../../enums/screenWidth';
import { screenSelectors } from '../../../../store/screen';
import * as UI from './styles';

interface ISymptomsHeader {
  preview?: boolean;
  title?: string;
  isSpecializationView?: boolean;
}

const SymptomsHeader: React.VFC<ISymptomsHeader> = ({ preview, title, isSpecializationView }) => {
  const screenWidth = useSelector(screenSelectors.width);
  const isLongTitle = !!title && title.length > 15;
  const description = isSpecializationView
    ? 'Choose your symptoms'
    : "Choose a bodypart that you're having a problem with";

  const header = title ? (
    <>
      {!isSpecializationView && <UI.Header type="h1">Symptoms /&nbsp;</UI.Header>}
      <UI.Header type="h1" color={!isSpecializationView ? ColorsEnum.GREEN : ColorsEnum.BLACK}>
        {title}
      </UI.Header>
    </>
  ) : (
    <UI.Header type="h1">Symptoms</UI.Header>
  );

  if (screenWidth === ScreenWidthEnum.landscapePhone)
    return (
      <UI.SymptomsHeaderWrapperLandScape useSmallFont={isLongTitle || isSpecializationView}>
        {!isSpecializationView && <UI.Header type="h1">Symptoms</UI.Header>}
        {title && (
          <UI.Header type="h1" color={!isSpecializationView ? ColorsEnum.GREEN : ColorsEnum.BLACK}>
            {title}
          </UI.Header>
        )}
        <UI.Description type="p" isSpecializationView={isSpecializationView}>
          {description}
        </UI.Description>
      </UI.SymptomsHeaderWrapperLandScape>
    );

  return (
    <UI.SymptomsHeaderWrapper useSmallFont={isLongTitle}>
      {header}
      {(!preview || screenWidth >= ScreenWidthEnum.tablet) && (
        <UI.Description type="p">{description}</UI.Description>
      )}
    </UI.SymptomsHeaderWrapper>
  );
};

export default SymptomsHeader;
