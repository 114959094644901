import React from 'react';
import * as UI from './styles';
import { ReactComponent as LoadArea } from '../../assets/icons/loadArea.svg';
import Typography from '../Typography';

interface IProps {
  onDrop: (files: FileList) => void;
}

const DragAndDrop: React.FC<IProps> = ({ onDrop, ...props }) => {
  const handleDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDrop(e.dataTransfer.files);
  };

  const onDragEvent: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <UI.DragZone
      id="drop_zone"
      onDragOver={onDragEvent}
      onDragEnter={onDragEvent}
      onDrop={handleDrop}
      {...props}>
      <LoadArea />
      <Typography type="button">Drag file here</Typography>
    </UI.DragZone>
  );
};

export default DragAndDrop;
